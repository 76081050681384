@import "./src/scss/globals";
.video-slide {
    height: 100%;

    .video-type {
        display: flex;
        padding: 20px;
        height: 100%;

        iframe {
            flex-shrink: 0;
            padding-right: 20px;

            &:only-child {
                width: 100%;
                height: 100%;
            }
        }

        .transcript {
            height: 100%;
            width: 100%;
            overflow-y: auto;
            background-color: $very-light-gray;
            padding: 10px;
        }
    }

    @media screen and (max-width: 900px) {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 0 10px 10px;

        iframe {
            padding: 0px !important;
            max-width: 100%;
            max-height: 32vh;
            margin-bottom: 10px;
        }

        .video-type {
            display: flex;
            flex-direction: column;
        }

        .transcript {
            padding: 10px;
        }
    }
}