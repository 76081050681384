@import "./src/scss/globals";
.customr-error-page-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    & > header {
        display: flex;
        align-items: center;
        z-index: 9999;
        border: 1px solid rgba(133, 133, 133, 0.125);
        position: fixed;
        width: 100%;
        justify-content: space-between;
        background-color: $white;
        height: 54px;
        padding: 0 40px;
        overflow-x: hidden;
        @media (max-width: $default-breakpoint) {
            padding: 0 20px;
        }
        .logo-img {
            height: 30px;
        }
    }
    @media (max-width: $default-breakpoint) {
        & > header {
            .logo-img {
                height: 20px;
            }
        }
    }
    & > main {
        flex: 1;
        padding-top: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        & > .content-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 90%;
            max-width: 1266px;
            background-color: $light-blue;
            border-radius: 15px;
            padding: 60px 90px;
            height: calc(100vh - 300px);
            & > i {
                width: 100%;
                font-size: 180px;
                text-align: center;
                color: $brand-blue;
            }
            & > h1 {
                font-family: sans-serif, Arial, Verdana, 'Trebuchet MS', 'Apple Color Emoji', 'Segoe UI Emoji',
                    'Segoe UI Symbol';
                font-weight: bold;
                font-size: 3rem;
                margin: 1.2rem 0;
                color: $brand-blue;
            }
            & > span {
                font-family: sans-serif, Arial, Verdana, 'Trebuchet MS', 'Apple Color Emoji', 'Segoe UI Emoji',
                    'Segoe UI Symbol';
                color: #7e7e7e;
            }
            .custom-error-message {
                margin-top: 1.5rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: #7e7e7e;
                p {
                    margin: 0;
                }
                @include respond(phone) {
                    align-items: flex-start;
                }
            }
            @media screen and (max-width: 900px) {
                padding: 60px 45px;
            }
            @media screen and (max-width: 750px) {
                padding: 40px 10px;
                width: 95%;
            }
        }
        button {
            display: flex;
            align-items: center;
            background-color: $brand-cyan;
            font-size: 26px;
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            color: $white;
            padding: 14px 90px;
            border: 0;
            border-radius: 45px;
            margin-top: 40px;
            @include respond(phone) {
                width: 95%;
                padding: 14px 0;
                justify-content: center;
            }
        }
    }
}
