@import "./src/scss/globals";
.navigation-calendar-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .navigation-slide-index-text {
        margin-left: 0.6rem;
        font-size: 14px;
        margin-top: 0.2rem;
        color: #ffffff;
        text-decoration: none solid rgb(255, 255, 255);
        text-align: center;
    }
    .calendar-icon {
        cursor: pointer;
    }
}
.navigation-calendar-slide-index-wrapper {
    $self: &;
    position: absolute;
    min-height: 60px;
    background-color: $brand-blue;
    width: 100%;
    bottom: 100%;
    z-index: 99;
    top: auto;

    @media screen and (max-width: 900px) {
        max-height: calc(100vh - 400px);
    }

    .calendar-content-wrapper {
        @media screen and (max-width: 900px) {
            padding: 0 20px;
            max-height: calc(100vh - 400px);
        }
        .navigation-calendar {
            padding: 20px 30px;
            @media screen and (max-width: 900px) {
                padding: 20px 20px;
            }
            .calendar-legend {
                justify-content: center;
                column-gap: 30px;
            }
            .calendar-group {
                .item-list {
                    column-gap: 6px;
                    grid-row-gap: 6px;
                    margin-top: 0;
                }
            }
        }
    }
    &__toggle {
        display: flex;
        justify-content: center;
        font-size: 12px;
        position: absolute;
        height: 17px;
        width: 40px;
        border-radius: 15px;
        top: -13px;
        left: 50%;
        transform: translateX(-50%);
        background-color: inherit;
        cursor: pointer;

        svg {
            transform: translateY(3px);
        }
    }
}
