@import "./src/scss/globals";
.message-item-container {
    .row {
        &.sender {
            flex-direction: row-reverse;
        }
    }

    .sender-photo {
        padding-left: 5px;

        &.sender {
            opacity: 0;
        }
    }

    .message-container {
        padding: 10px;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        margin-bottom: 20px;
        max-width: 380px;
        fill: $light-gray-100;

        &.sender {
            background-color: $brand-cyan;
        }

        &.receiver {
            background-color: $light-gray-100;
        }

        .sender-name {
            font-family: 'Open Sans', sans-serif;
            font-size: 0.688rem;
            color: #25255e;
            text-decoration: none solid rgb(37, 37, 94);
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: 2px;
        }

        .sender-message {
            height: fit-content;
            font-family: 'Open Sans', sans-serif;
            font-size: 0.813rem;
            color: #000000;
            text-decoration: none solid rgb(0, 0, 0);
            word-break: break-word;
        }

        .bottom-panel {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 5px;

            .read-more {
                color: $linkClr;
                cursor: pointer;
                font-size: 0.813rem;
            }

            .timestamp {
                font-family: 'Open Sans', sans-serif;
                color: #000000;
                text-decoration: none solid rgb(0, 0, 0);
                font-size: 0.8em;
            }
        }
    }

    .overlay-trigger {
        position: absolute;
        top: -50%;
        left: 38px;
        background-color: white;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        padding: 16px;
        width: 80%;
        z-index: 1;

        &:after {
            transform: translateY(-50%) rotate(135deg);
            content: '';
            display: inline-block;
            left: -6px;
            position: absolute;
            top: 50%;
            border-style: solid;
            border-width: 0 0 12px 12px;
            height: 0;
            width: 0;
            border-color: transparent;
            border-bottom-color: white;
            box-shadow: 1px 1px 1px 0px lightgray;
        }
        @media (min-width: 1366px) and (max-width: 1480px) {
            left: 27px;
        }
        @media (min-width: 1700px) {
            width: 75%;
        }

        .overlay-content {
            display: flex;
            align-items: center;

            .profile-image {
                margin-right: 16px;

                img,
                .profile-avatar {
                    width: 45px !important;
                    height: 45px !important;
                    border-radius: 50%;
                    border: 1.5px solid #2fcca1;
                }
            }

            .profile-info {
                width: calc(100% - 38px);
                .name {
                    font-weight: bold;
                    font-size: 14px;
                }

                .email {
                    white-space: nowrap;
                    color: #666;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    margin-bottom: 8px;
                }

                .view-profile-btn {
                    background-color: #0077b6;
                    color: white;
                    border: none;
                    border-radius: 4px;
                    padding: 6px 12px;
                    cursor: pointer;
                }
            }
        }
        hr {
            border: 1px solid #dadada;
        }
        .profile-navigation {
            display: flex;
            justify-content: start;
            align-items: center;
            gap: 7px;
            cursor: pointer;

            .fa-circle-user:before,
            .fa-user-circle:before {
                font-size: medium;
            }
        }
    }

    .profile-photo {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        object-fit: cover;
    }

    .profile-avatar {
        width: 1.5rem !important;
        height: 1.5rem !important;
        font-size: 0.5rem;
    }

    .sender-photo-hidden {
        &.receiver {
            opacity: 0;
        }
    }
}
