@import "./src/scss/globals";
.sidebar-toggle {
    .menu-foldline {
        width: 25px;
        height: 25px;
    }
    .menu-unfoldline {
        width: 25px;
        height: 25px;
        transform: scaleX(-1);
    }
    &:hover {
        cursor: pointer;
    }
}
