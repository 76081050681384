@import "./src/scss/globals";
.status-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: calc(100vh - 55px);
    padding-top: 10rem;
    padding-bottom: 200px;
    .status-container {
        width: 50%;
        margin: 0 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @media screen and (max-width: 1625px) {
            max-width: calc(100% - 50px);
        }
    }

    .status-card {
        display: flex;
        padding: 20px 20px 20px 20px;
        margin: 20px 0;
        border-radius: 20px;
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.02);
        border-width: 2px;
        border-style: dashed;
        max-width: 100%;
        width: 100%;
        flex-direction: row;
        position: relative;

        .status-content {
            width: 100%;
            display: block;
            min-height: 36px;
            h4 {
                margin: 5px 0 12px;
                line-height: 22px;
                font-size: 16px;
                padding-right: 40px;
                a {
                    color: $linkClr;
                    text-decoration: underline;
                }
            }
            .status-data {
                padding-right: 40px;
                display: block;
            }
            .remove-content-space {
                p {
                    margin: 0.3rem 0px 0px 0px !important;
                }
            }
            h4 + .status-data {
                clear: both;
                padding-right: 0;
            }
        }

        .status-type-icon {
            height: 32px;
            width: 32px;
            display: block;
            float: left;
            margin-right: 20px;
            padding-top: 5px;
            &--info {
                color: $indigo-dye;
            }
            &--alert {
                color: $warning;
            }
            &--critical {
                color: $error;
            }
        }

        &.info {
            border-color: $indigo-dye;
            background-color: $white;
        }

        &.alert {
            border-color: $warning;
            background-color: $warning-light-10;
        }

        &.critical {
            border-color: $error;
            background-color: $error-light-10;
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.02);
        }
    }
}
