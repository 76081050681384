@import "./src/scss/globals";
.menu-popup {
    &__item {
        display: flex;
        align-items: center;
        min-height: 30px;
        padding: 10px 10px;
        cursor: pointer;
        font-size: 14px;
        transition: background-color 0.2s ease-out;
        text-decoration: none;
        color: $black;

        & > i {
            min-width: 11px;
            font-size: 10px;
            margin-right: 10px;
        }

        &:hover {
            background-color: darken($white, 10);
            text-decoration: none;
        }

        &:first-child {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;

            main > & {
                border-radius: 0;
            }
        }

        &:last-child {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;

            main > & {
                border-radius: 0;
            }
        }
    }
}
