@import "./src/scss/globals";
.user-notification-count-wrapper {
    .card {
        background-color: rgba(47, 204, 161, 0.03);
        border: 1px solid rgba(47, 204, 161, 0.5);
    }
    .accordion-item {
        border: 0;
        .accordion-header,
        .card-header {
            padding: 0;
            border: 0;
            background-color: unset;
            width: 100%;
            .accordion-button {
                padding: 0;
                background-color: unset;
                &:focus {
                    box-shadow: unset;
                }
                &::after {
                    display: none;
                }
            }
            .accordion-button[aria-expanded='true'] {
                border-bottom: 1px solid rgba(47, 204, 161, 0.5);
            }
        }
        .accordion-collapse {
            .accordion-body {
                padding: 0;
                border: 0;
                .card-body {
                    border: 0;
                }
            }
        }
    }
}
