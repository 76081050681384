@import "./src/scss/globals";
#action-confirmation-popup {
    width: 600px;
    max-width: 90%;
    .modal-content {
        border-radius: 1rem !important;
    }
}

.blue-header-popup {
    .modal-content {
        border-radius: 0.9rem !important;
        border-color: #25255e;
        .modal-header {
            background-color: #25255e;
            color: #fff;
            align-items: center;
            justify-content: flex-start;
            border-top-left-radius: 0.7rem;
            border-top-right-radius: 0.7rem;
            padding: 1.5rem 1.5rem;
            @media screen and (max-width: 375px) {
                padding: 1rem 1rem;
            }
            .btn-close {
                filter: invert(1);
                opacity: 1;
            }
        }
    }
}
