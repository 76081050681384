@import "./src/scss/globals";
.slide-calendar-item {
    width: 40px;
    height: 45px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    background-color: $mid-gray;
    border: solid 2px $white;
    &:not(.locked-card) {
        cursor: pointer;
    }
    h3 {
        margin-top: 2px;
        font-size: 1.2rem;
        margin-bottom: 0;
        font-weight: bold;
        color: $black;
        line-height: normal;
    }
    .status {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 15px;
        border-radius: 0 0 5px 5px;
        i {
            color: $black;
            font-size: 12px;
        }
        &.green {
            background-color: $success;
        }
        &.red {
            background-color: $incorrect-orange;
        }
    }
    &.locked-card {
        background-color: $light-gray;
    }
}
