@import "./src/scss/globals";
.question-slide {
    padding: 0;

    & > header {
        & > h1 {
            font-weight: bold;
            padding: 20px 20px 0px;
            &.single {
                margin: 0;
                margin-bottom: 20px;
                -webkit-line-clamp: 2;
            }
        }
    }

    .single-question {
        padding-top: 1px;

        .questions {
            min-height: calc(100% - 70px);
            padding: 0 20px;

            .question {
                margin-top: 20px;

                & > span {
                    display: block;
                    margin-bottom: 8px;
                    font-size: 18px;
                    font-weight: bold;
                }

                .option {
                    display: flex;
                    align-items: center;
                    margin: 5px 0;

                    &.selected {
                        background-color: $very-light-gray;
                    }

                    .left-container {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 36px;
                        height: 36px;
                        input[type='radio'],
                        input[type='checkbox'] {
                            font-size: 5px;
                            transform: none;
                        }
                        &.correct {
                            background-color: $brand-cyan;
                        }
                        &.incorrect {
                            background-color: $brand-red;
                        }
                        .fa-check,
                        .fa-times {
                            font-size: 12px;
                        }
                    }

                    label {
                        margin-left: 10px;
                        margin-bottom: 0;
                        flex: 1;
                    }
                }
                &.image-question {
                    @extend %center-content;
                    padding: 0 30px;
                    flex-wrap: wrap;
                    > span {
                        width: 100%;
                    }

                    .option {
                        display: flex;
                        flex-direction: column;
                        width: 20%;
                        min-height: 100px;
                        margin: 8px;
                        transition: border-color 0.2s ease-out;
                        position: relative;
                        padding: 10px;
                        border-radius: 6px;

                        @media screen and (max-width: 900px) {
                            width: 40%;
                        }

                        &::after {
                            content: '';
                            transition: opacity 0.2s ease-out;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 1;
                            height: 100%;
                            width: 100%;
                            background-color: rgba($brand-blue, 0.5);
                            background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' %3E%3Cpath fill='#{rgba($white, .8)}' d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z' class=''%3E%3C/path%3E%3C/svg%3E");
                            background-size: 36px;
                            background-repeat: no-repeat;
                            background-position: center;
                            pointer-events: none;
                            opacity: 0;
                            border-radius: 10px;
                        }

                        &.selected {
                            box-shadow: inset 0 0 0 3px $brand-blue;

                            &::after {
                                opacity: 1;
                            }
                        }

                        img {
                            height: 75px;
                            object-fit: contain;
                            margin-top: 0;
                            width: auto;

                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}

.question-slide {
    .detailed .single-question.single-question-card-component {
        header {
            background-color: transparent;
            color: $black;
        }
    }
}

@media screen and (max-width: 900px) {
    @import './SingleQuestionMobile.scss';
}

.preview-wrapper.preview-wrapper--mobile {
    @import './SingleQuestionMobile.scss';
}
