@import "./src/scss/globals";
.apply-discount-wrapper {
    @media (min-width: 800px) and (max-width: 1060px) {
        width: 55%;
        margin: auto;
    }

    @media (min-width: 1025px) and (max-width: 1160px) {
        width: 100%;
        margin: auto;
    }

    @media (min-width: 600px) and (max-width: 800px) {
        width: 65%;
        margin: auto;
    }

    .apply-coupon-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 500px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 5px;
        }

        @media (min-width: 1025px) and (max-width: 1200px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 5px;
        }

        .code-title-wrapper {
            i {
                transform: rotate(320deg);
            }
        }

        .coupon {
            flex: 0 0 auto;
            margin-bottom: auto;
            display: flex;
            align-items: center;
            width: 60%;

            @media (min-width: 1025px) and (max-width: 1200px) {
                width: 100%;
            }

            &--inside-icon {
                position: relative;

                & > input {
                    padding-right: 2.5rem;
                }

                & > .btn {
                    position: absolute;
                    top: 0.3rem;
                    right: 0.3rem;
                    margin: 0;
                    border-radius: 8px;
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                    display: flex;
                    font-size: 1rem;
                    color: $white;
                    padding: 3px;

                    @include respond(phone) {
                        width: 35%;
                        top: unset;
                    }
                }
            }

            @media screen and (max-width: 500px) {
                width: 100%;
            }

            .coupon-question {
                margin-right: 16px;
            }
        }

        .applied-coupon-wrapper {
            background-color: $success;
            padding: 6px 12px;
            font-weight: bold;
            text-transform: uppercase;

            @include respond(phone) {
                font-size: 12px;
            }

            .applied-coupon-name {
                margin-right: 12px;
            }

            .applied-coupon-remove-icon {
                background-color: unset;
                border: unset;
            }
            .applied-coupon-remove-icon:hover {
                cursor: pointer;
            }
        }

        .apply-code-button {
            background-color: #2fcca1;
            padding: 6px;
            text-transform: uppercase;
            border-radius: 5px;
            font-size: 1.04rem;
            margin-top: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            font-weight: inherit;

            @include respond(phone) {
                width: inherit;
            }

            &:hover {
                background-color: #d2f5eb;
                color: #25255e;
            }
        }

        .button-visible {
            visibility: visible;
            opacity: 1;
        }

        .button-hidden {
            visibility: hidden;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
        }
    }
}
