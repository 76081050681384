@mixin respond($breakpoint) {
    @if $breakpoint == smallest-phone {
        @media only screen and (max-width: 20em) {
            @content;
        } // 320px
    }
    @if $breakpoint == small-phone {
        @media only screen and (max-width: 21.875em) {
            @content;
        } // 350px
    }
    @if $breakpoint == smaller-phone {
        @media only screen and (max-width: 25em) {
            @content;
        } // 400px
    }
    @if $breakpoint == phone-small {
        @media only screen and (max-width: 28.125em) {
            @content;
        } // 450px
    }
    @if $breakpoint == phone-smaller {
        @media only screen and (max-width: 31.25em) {
            @content;
        } // 500px
    }
    @if $breakpoint == phone {
        @media only screen and (max-width: 37.5em) {
            @content;
        } // 600px
    }
    @if $breakpoint == large-phone {
        @media only screen and (max-width: 43.75em) {
            @content;
        } // 700px
    }
    @if $breakpoint == small-tab-port {
        @media only screen and (max-width: 50em) {
            @content;
        } // 800px
    }
    @if $breakpoint == tab-port {
        @media only screen and (max-width: 56.25em) {
            @content;
        } // 900px
    }
    @if $breakpoint == tab-port-large {
        @media only screen and (max-width: 62.5em) {
            @content;
        } // 1000px
    }
    @if $breakpoint == tab-land-small {
        @media only screen and (max-width: 68.75em) {
            @content;
        } // 1100px
    }
    @if $breakpoint == tab-land {
        @media only screen and (max-width: 75em) {
            @content;
        } // 1200px
    }
    @if $breakpoint == laptop {
        @media only screen and (max-width: 81.25em) {
            @content;
        } // 1300px
    }
    @if $breakpoint == laptop-mid {
        @media only screen and (max-width: 84.375em) {
            @content;
        } // 1350px
    }
    @if $breakpoint == large-laptop {
        @media only screen and (max-width: 87.5em) {
            @content;
        } // 1400px
    }
    @if $breakpoint == small-desktop {
        @media only screen and (max-width: 100em) {
            @content;
        } // 1600px
    }
    @if $breakpoint == big-desktop {
        @media only screen and (min-width: 112.5em) {
            @content;
        } // 1800px
    }
}

@import "./CommonMixins/searchFilters.scss";
@import "./CommonMixins/checkoutMixins.scss";