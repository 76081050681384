@import "./src/scss/globals";
.page-card-quiz-popup {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $light-opaque-bg;

    @media screen and (max-width: 900px) {
        width: 100%;
        height: 100%;
    }

    .slide-container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;
        width: 100%;
        height: 100%;

        &__wrapper {
            display: flex;
            flex-direction: column;
            width: 800px;

            &>header {
                &>h3 {
                    font-size: 16px;
                    font-weight: bold;
                }

                &>h5 {
                    font-size: 16px;
                    color: $light-gray;
                }
            }

            &>main {
                height: 400px;
                overflow-y: auto;
                box-shadow: 0 0 10px rgba($brand-blue, 0.3);
            }

            &>footer {
                height: 75px;
            }

            @media screen and (max-width: 900px) {
                align-items: flex-start;
                width: 100vw;
                height: 100%;

                &>header {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    background-color: rgba($blue, 0.1);
                    position: relative;
                    height: 48px;

                    &>h3,
                    &>h5 {
                        display: block;
                        font-size: 14px;
                        color: $light-gray;
                        font-weight: normal;
                        margin: 0;
                        max-width: calc(100% - 220px);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                &>main {
                    width: 100vw;
                    height: auto;
                    flex: 1;
                    box-shadow: none;
                }

                &>footer {
                    height: 53px;
                    width: 100%;
                }
            }
        }

        &__unset-theme-background {
            header {
                background-color: $white !important;
                color: unset !important;
                height: auto;

                @media screen and (max-width: 900px) {
                    height: 55px;
                }
            }

            .lesson-slide-header {
                .standard {
                    .course-details {
                        padding: 20px 0 0 20px !important;
                    }

                    .lesson-name {
                        padding: 0 20px !important;
                    }

                    .lesson-timer {
                        visibility: hidden;
                    }
                }

                .state,
                .national {
                    .course-details {
                        padding: 20px 0 0 20px !important;
                    }

                    .lesson-timer {
                        visibility: hidden;
                    }
                }
            }
        }

        &__progress {
            height: 15px;
            width: 100%;
            background-color: lighten($brand-cyan, 35%);

            &>div {
                height: 100%;
                background-color: $brand-cyan;
            }

            @media screen and (max-width: 900px) {
                height: 5px;
            }
        }

        &__navigation {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $brand-blue;
            height: 60px;
            color: $white;
            font-size: 14px;
            position: relative;

            .lesson-button {
                position: absolute;
                display: flex;
                align-items: center;
                padding: 0 15px;
                height: 100%;
                user-select: none;
                cursor: pointer;
                background-color: transparent;
                transition: background-color 0.2s ease-out;
                font-size: 14px;

                &:hover {
                    background: lighten($brand-blue, 10%);
                }

                i {
                    margin: 0 15px;
                    font-size: 11px;
                    transform: translateY(1px);
                }

                &--next {
                    top: 0;
                    padding-right: 0;
                    right: 0;
                }

                &--previous {
                    padding-left: 0;
                    left: 0;
                }

                &--disabled {
                    opacity: 0.5;

                    &:hover {
                        background-color: transparent;
                        cursor: default;
                    }
                }

                &.take-final-exam-btn {
                    right: 10px;
                }
            }

            .check-answer-container {
                @extend %center-content;
                margin: 0px;
                height: 50px;
                position: absolute;
                bottom: 100%;
                z-index: 10;
                background-image: linear-gradient(transparent, rgba(255, 255, 255, 0.3));

                button {
                    text-align: center;
                    padding: 5px 35px;
                    background-color: #2fcca1;
                    color: #000;
                    border: 2px solid #2fcca1;
                    text-decoration: none;
                    border-radius: 50px;
                    font-weight: bold;
                }
            }

            @media screen and (max-width: 900px) {
                height: 48px;
                width: 100vw;
            }
        }
    }
}

.page-card-quiz {
    overflow-y: auto;

    .quiz {
        &__info {
            padding: 20px 20px;
            background-color: $cyan;

            &>h4 {
                margin-top: 0px;
                margin-bottom: 16px;
            }
        }

        &__unset-default-background {
            background-color: transparent !important;
        }

        &__alert {
            padding: 0.55rem;
            border-left: 10px solid;
            margin-bottom: 1rem;
            background-color: $white;

            &--success {
                border-color: $brand-cyan;
            }

            h6 {
                font-weight: bold;
                margin-bottom: 0.1rem;
            }
        }

        &__data {
            margin-top: 10px;

            &>p {
                margin-bottom: 3px;
            }
        }

        &__footer {
            &>button {
                margin: 16px 15px 16px 0;
            }
        }
    }
}