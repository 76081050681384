@import "./src/scss/globals";
.cookie-wrapper {
    &>header {
        display: flex;
        align-items: center;
        z-index: 9999;
        border: 1px solid rgba(133, 133, 133, 0.125);
        position: fixed;
        width: 100%;
        justify-content: space-between;
        background-color: $white;
        height: 54px;
        padding: 0 25px 0 40px;
        overflow-x: hidden;

        @media (max-width: $default-breakpoint) {
            padding: 0 20px;
        }

        .logo-img {
            height: 30px;
        }
    }

    .cookie-alert {
        position: fixed;
        top: 6rem;
        left: 50%;
        opacity: 0;
        transform: translateX(-50%) translateY(-0.5rem);
        padding: 2rem;
        background-color: $white;
        border-radius: 1.5rem;
        z-index: 99997;
        box-shadow: 0 0 10px rgba($black, 0.2);
        transition: all 0.5s ease-in-out;
        pointer-events: none;
        display: flex;
        flex-direction: column;
        width: 50%;

        @media screen and (min-width: 1440px) {
            width: 60%;
        }

        @media (min-width: 425px) and (max-width: 1440px) {
            width: 85%;
        }

        @include respond(phone) {
            width: 95%;
        }

        &.cookie-alert--visible {
            opacity: 1;
            transform: translateX(-50%) translateY(0);
            pointer-events: auto;
        }

        &>h1 {
            font-size: 28px;
            font-weight: 400;
            padding-bottom: 1rem;
            margin-bottom: 0.8rem;
            border-bottom: 1px solid rgba($black, 0.2);
        }


        .display-message {
            margin-bottom: 2rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid rgba($black, 0.2);
        }

        .display-browser-list {
            display: flex;
            flex-direction: column;

            overflow: auto;
            max-height: calc(100vh - 360px);

            .browser-name {
                margin: 0.3rem 0;
                cursor: pointer;
            }
        }

        .list-cookie-steps {
            padding: 0 1.5rem;

            li {
                .content-container {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                }
            }

            .setting-image {
                width: auto;
                height: calc(100vh - 650px);
                margin-top: 1rem;
                object-fit: contain;
                margin-bottom: 0.5rem;

                @media screen and (max-height: 900px) {
                    height: calc(100vh - 480px);
                }

                @include respond(phone) {
                    padding: 0;
                    height: calc(100vh - 480px);
                }
            }

            .btn--link {
                color: $brand-blue;
                cursor: pointer;
            }
        }
    }
}