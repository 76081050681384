@import "./src/scss/globals";
.extension-model {
    &.resale-options {
        padding-right: 10px;
        @media screen and (max-width: 700px) {
            .modal-dialog {
                &.modal-lg {
                    max-width: 96%;
                }
            }
        }

        @media screen and (max-width: 575px) {
            .modal-dialog {
                &.modal-lg {
                    max-width: 96%;
                }

                .modal-title {
                    img {
                        height: 18px;
                        margin: 0 auto 0 0;
                    }
                }
                .modal-container {
                    .modal-offer-container {
                        padding: 5px;
                        width: 100%;
                        .modal-offer-row {
                            padding: 15px 0 15px 0;
                        }
                    }
                }
                .form-check-label {
                    font-size: 16px;
                }
                .item-detail {
                    margin-left: auto;
                }
            }
        }
    }

    .warning-message {
        display: flex;
        align-items: center;
        font-weight: bolder;
    }

    @media (max-width: 501px) {
        .__react_component_tooltip {
            max-width: calc(100vw - 50px);
        }
    }

    @media (min-width: 500px) and (max-width: 980px) {
        .__react_component_tooltip {
            max-width: calc(100vw - 40vw);
        }
    }
    @media (min-width: 980px) and (max-width: 2600px) {
        .__react_component_tooltip {
            max-width: calc(100vw - 50vw);
        }
    }
}
