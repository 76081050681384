@import "./src/scss/globals";
.asyncpreview-fileupload {
    label {
        display: flex;
        align-items: center;

        & > .bd {
            font-size: 13px;
            padding: 3px 7px;
            cursor: pointer;
            margin-left: 20px;
        }
    }
}

.Upload-Input {
    .Img-Wrap {
        width: 13rem;
        height: 13rem;

        display: block;
        background-color: #f5fafd;
        border: 1px solid #ccc;
        border-radius: 4px;
        position: relative;
        .Img-Path {
            background-size: contain;
            background-position: center;
            max-width: 96%;
            max-height: 96%;
            margin: auto;
            height: 96%;
            width: auto;
            min-width: 96%;
            background-repeat: no-repeat;
            left: 0;
            right: 0;
            display: block;
            position: absolute;
            bottom: 0;
            top: 0;
        }
    }

    &.error {
        input {
            color: $red;
        }
        input::file-selector-button {
            color: $red;
            padding: 0.5em;
            border: 1px solid $red;
        }
    }
}
