.question-slide {
    .boolean-answers {
        height: auto;
    }

    > header {
        & > h1 {
            font-size: 18px;
        }

        & > h3 {
            font-size: 16px;
        }
    }

    .boolean-answer {
        height: auto;

        .questions {
            .question {
                width: calc(100% - 20px);

                .content {
                    flex-direction: column;
                    align-items: initial;
                }
            }
        }
    }
}
