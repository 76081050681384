@import "./src/scss/globals";
.hotspotIcon {
    box-shadow: 0 0 10px #fff;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    background-color: $brand-blue;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    color: $white;

    &--active {
        border: 4px solid $brand-cyan;
        background-color: $brand-cyan;
    }

    &__internal {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $brand-blue;
        box-shadow: 0 0 0 4px $white;

        &--active {
            background-color: $brand-cyan;
        }
    }
}
