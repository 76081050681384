@import "./src/scss/globals";
.document-page {
    header {
        min-height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        h1 {
            text-align: center;
            padding: 20px 25px 20px 20px;
            margin-bottom: 0;
        }
    }

    .content {
        padding: 20px;
    }

    .document-page-pdf-container {
        width: 100%;
        height: calc(100vh - 54px);
    }
}
