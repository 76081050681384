@import "./src/scss/globals";
#user-messages {
    max-width: 1080px;
    margin: 30px auto;

    .user-notification-count-header-wrapper {
        flex-wrap: nowrap;
        display: flex;
        justify-content: space-between;
        .col {
            flex: unset;
            width: auto;
        }
    }
    @media (max-width: 1140px) {
        margin: 30px 20px;
    }

    .text-tiny {
        font-size: 1.8em;
    }
    .text-small {
        font-size: 0.85em;
    }
    .text-big {
        font-size: 1.4em;
    }

    .accordion:not(:last-child) {
        margin-bottom: 0.75rem;
    }

    .card-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;

        .card-title {
            width: 50%;
            flex: 1;
            margin: 0;
            cursor: pointer;
        }

        .card-controls {
            width: 50%;
            text-align: right;
            .btn:not(:last-child) {
                margin-right: 0.5rem;
            }
        }

        .card-title,
        .card-controls {
            padding: 0.75rem 1rem;
        }
    }
}
