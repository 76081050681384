@import "./src/scss/globals";
.heading-page {
    header {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        height: 30%;
        min-height: 150px;
        padding: 20px 25px 20px 20px;
    }

    & > div:not(.cardInfo__icon) {
        img {
            max-width: 100%;
        }
        padding: 20px;
        & > p:last-child {
            margin: 0;
        }
    }

    @media screen and (max-width: 900px) {
        header {
            height: 25%;
        }
    }
}
