@import "./src/scss/globals";
.upsell-cart-button {
    position: fixed;
    right: 2rem;
    bottom: 2rem;
    @media screen and (max-width: 1200px) {
        right: 1rem;
        bottom: 1rem;
    }
    .cart-button,
    .cart-button:active,
    .cart-button:active:focus {
        width: 4rem;
        height: 4rem;
        background-color: $brand-cyan !important;
        color: $black !important;
        border-radius: 50px;
        text-align: center;
        box-shadow: 2px 2px 3px #999 !important;
        border: none !important;
        i {
            font-size: 1.5rem;
        }
    }

    .count {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        font-size: 11px;
        border-radius: 50%;
        background: $brand-blue;
        width: 1.5rem;
        height: 1.5rem;
        line-height: 1.5rem;
        display: block;
        text-align: center;
        color: $white;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
    }
}
