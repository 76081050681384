%center-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

%center-content-horizontal-left {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
}

%center-content-vertical {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

%center-content-vertical-top {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
}

%center-content-vertical-top-left {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: center;
}

%flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

%number-input-no-arrows {
    -webkit-appearance: none;
    -moz-appearance: textfield;
}
