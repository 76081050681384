@import "./src/scss/globals";
.confirmation__header {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $stratos;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 30px;
    text-align: center;
    @media screen and (max-width: 900px) {
        margin-bottom: 20px;
        padding: 25px;
    }
    @media screen and (max-width: 500px) {
        height: 50%;
    }
    > img {
        width: 100px;
    }
    > h1 {
        color: $white;
        font-size: 30px;
        line-height: 40px;
        @include respond(phone) {
            font-size: 28px;
            line-height: 34px;
            margin-bottom: 20px;
        }
    }
    > p {
        color: $white;
        font-size: 16px;
        line-height: 25px;
    }
    > h3 {
        color: $white;
        font-size: 20px;
        line-height: 25px;
        @include respond(phone) {
            font-size: 16px;
            line-height: 22px;
            font-weight: normal;
            margin-bottom: 12px;
        }
    }
    .access-course-button {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        a {
            border: none;
            background: transparent;
            color: $white;
            text-decoration: underline;
            font-size: 1.3rem;
            cursor: pointer;
        }
        .link-disabled,
        .btn-primary.disabled {
            color: gray;
            cursor: not-allowed;
            background-color: $white;
            border: 0;
            &:hover {
                cursor: not-allowed;
                outline: 0;
            }
        }
    }

    > h2 {
        color: $white;
        font-size: 25px;
        line-height: 30px;
        margin-top: 20px;
    }
}
