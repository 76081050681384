@import "./src/scss/globals";
.course-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-radius: 100px;
    background-color: $indigo-dye;
    border: none;
    color: $white;
    font-size: 20px;
    padding: 0 24px;
    width: 125px;
    &:hover {
        background-color: $azure-white;
    }
    @media screen and (max-width: 1800px) {
        font-size: 18px;
    }
    @media screen and (max-width: 1200px) {
        font-size: 16px;
        min-width: 100px;
        height: 40px;
    }
}

.exam-passed-dropdown {
    button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px !important;
        border-radius: 100px;
        background-color: $mid-gray;
        border: none;
        color: $black;
        font-size: 20px;
        padding: 0 24px;
        &:hover {
            background-color: #25a380;
            color: $white;
        }
        @media screen and (max-width: 1800px) {
            font-size: 18px;
        }
        @media screen and (max-width: 1200px) {
            font-size: 16px;
            min-width: 100px;
            height: 40px !important;
        }
    }
}
.expired-course-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-radius: 100px;
    background-color: $mid-gray;
    border: none;
    color: $mid-dark-gray;
    font-size: 20px;
    padding: 0 24px;
    width: 125px;
    &:hover {
        text-decoration: none;
        color: $mid-dark-gray;
    }
    @media screen and (max-width: 992px) {
        margin-top: 10px;
        max-width: 140px;
    }
    @media screen and (max-width: 1800px) {
        font-size: 18px;
    }
    @media screen and (max-width: 1200px) {
        font-size: 16px;
        min-width: 100px;
        height: 40px;
    }
}

.extension-course-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-radius: 100px;
    background-color: $correct-green;
    border: none;
    color: $black;
    font-size: 20px;
    padding: 0 24px;
    min-width: 125px;
    &:hover {
        background-color: $brand-cyan-light;
        &:link,
        &:visited {
            color: $brand-blue;
        }
    }
    @media screen and (max-width: 1800px) {
        font-size: 18px;
    }
    @media screen and (max-width: 1200px) {
        font-size: 16px;
        min-width: 100px;
        height: 40px;
    }
}
.certificate-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    min-width: 150px;
    border-radius: 100px;
    border: none;
    color: $white;
    font-size: 20px;
    padding: 0 30px;
    &:hover {
        background-color: $azure-white;
    }
    @media screen and (max-width: 1800px) {
        font-size: 18px;
    }
    @media screen and (max-width: 1200px) {
        font-size: 16px;
        min-width: 100px;
        height: 40px;
    }
}
.resale-options {
    .modal-content {
        height: 80%;
        max-height: unset;
    }
    .modal-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        header {
            color: $brand-blue;
            font-size: 35px;
            display: block;
            text-align: center;
        }
        .modal-instruction {
            text-align: center;
            width: 60%;
            font-size: 18px;
            margin-top: 15px;
            margin-bottom: 25px;
            @media (max-width: 1360px) {
                width: 90%;
                text-align: center;
            }
        }
        .modal-offer-container {
            width: 60%;
            background-color: #f3f9fc;
            height: fit-content;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            padding: 20px;
            .modal-offer-row {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-left: 0;
                margin-right: 0;
                border-radius: 10px;
                padding: 15px 15px 15px 15px;
                .modal-offer-details {
                    margin-right: 0;
                    flex: 1 0 auto;
                    .course-title {
                        color: $brand-blue;
                        font-size: 18px;
                        font-weight: bold;
                    }
                    .course-days-remaining {
                        font-style: italic;
                    }
                }
                .model-offer-options {
                    margin-top: 24px;
                    flex: 1 1 auto;
                    .item {
                        justify-content: space-between;
                        margin-top: 4px;
                        font-size: 18px;
                        padding: 0px 8px;
                        align-items: center;
                        display: flex;

                        .item-detail {
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                        }

                        .item-price {
                            font-weight: 700;
                            color: $brand-blue;
                            margin-left: 8px;
                            margin-right: 8px;
                        }

                        input[type='checkbox'] {
                            width: 28px;
                            height: 28px;
                        }

                        &.checked {
                            background-color: white;

                            label {
                                margin-right: auto;
                                padding: 8px;
                            }

                            input[type='checkbox'] {
                                background-color: $success;
                            }

                            &::before {
                                content: '';
                                border: 8px solid $success;
                                align-self: stretch;
                            }
                        }
                    }
                }

                .no-option-message {
                    font-size: 14px;
                    color: $red;
                    margin-top: 12px;
                }
            }

            @media (max-width: 1360px) {
                width: 90%;
            }
        }
    }
    .header {
        display: flex;
        align-items: center;
        justify-content: center;

        .modal-title {
            margin: auto;
        }

        .close {
            margin: 0;
            font-size: 2rem;
        }
    }
    img {
        height: 30px;
        margin: 10px;
    }
    .option-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 1266px;
        background-color: #f0f8ff;
        border-radius: 15px;
        padding: 60px 90px;
    }
    .option-list {
        list-style: none;
    }
    .footer {
        justify-content: flex-end;

        .subtotal {
            margin-right: 42px;
        }

        .btn {
            background-color: $success;
            font-weight: bold;
            margin-right: 24px;
        }

        .btn:disabled {
            background-color: #25255e;
            pointer-events: none;
        }
    }
}

.exam-passed-btn {
    margin-left: auto;
}
