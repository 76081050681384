@import "./src/scss/globals";
.slide-header {
    display: flex;
    flex-direction: column;
    width: 100%;
    & > h1,
    h3 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    & > h1 {
        font-weight: bold;
        padding: 20px 20px 0px;
        font-size: 24px;
        &.single {
            margin: 0;
            margin-bottom: 20px;
            -webkit-line-clamp: 2;
        }

        @media screen and (max-width: 500px) {
            font-size: 18px;
        }
        @media (min-width: 501px) and (max-width: 900px) {
            font-size: 21px;
        }
    }
    & > h3 {
        padding: 0 20px;
        margin-bottom: 20px;
        font-size: 20px;
        &.single {
            margin-top: 20px;
            margin-bottom: 20px;
            -webkit-line-clamp: 2;
        }

        @media screen and (max-width: 500px) {
            font-size: 16px;
        }
        @media (min-width: 501px) and (max-width: 900px) {
            font-size: 17px;
        }
    }
}
