@import "./src/scss/globals";
.heading-slide {
    min-height: 100%;
    height: 100%;
    overflow-y: auto;
    .heading_card {
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        & > h1 {
            -webkit-line-clamp: 2;
            &.single {
                -webkit-line-clamp: 4;
            }
        }
        & > h3 {
            -webkit-line-clamp: 3;
            padding-top: 8px;
            margin: 0;

            &.single {
                -webkit-line-clamp: 5;
            }
        }
    }
    & > div:not(.cardInfo__icon) {
        padding: 1.25rem;
        & > p:last-child {
            margin: 0;
        }
    }

    @media screen and (max-width: 900px) {
        .heading_card {
            height: 25%;
        }
    }
}
