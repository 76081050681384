@import "./src/scss/globals";
.maintenance-prompt-model {
    .modal-dialog {
        width: 500px;
        @media screen and (max-width: 500px) {
            max-width: 96% !important;
        }
    }
}
.modal-backdrop.show {
    opacity: 1;
}
