@import "./src/scss/globals";
.secret-questions {
    .modal-content {
        border-radius: 0.9rem !important;
        border-color: #25255e;
        .modal-header {
            background-color: #25255e;
            color: #fff;
            align-items: center;
            justify-content: flex-start;
            border-top-left-radius: 0.7rem;
            border-top-right-radius: 0.7rem;
            padding: 1.5rem 1.5rem;
            @media screen and (max-width: 375px) {
                padding: 1rem 1rem;
            }
            .btn-close {
                filter: invert(1);
                opacity: 1;
            }
        }
    }
}

.secret-question-modal {
    .modal-dialog {
        justify-content: center;

        .modal-content {
            width: 50%;

            .modal-body {
                overflow-x: hidden !important;

                .secret-questions-select {
                    color: $black;
                    padding-right: 1rem;
                    text-wrap: wrap;
                }
            }

            .modal-footer {
                .button-green {
                    background-color: $success;
                }
            }

            @media screen and (max-width: 768px) {
                width: 100% !important;
            }

            @media (min-width: 769px) {
                width: calc(100vw - 160px) !important;
                max-width: 780px;
            }
        }
    }
}

.collect-secret-questions-answers.modal {
    .modal-dialog {
        .modal-content {
            border-radius: 24px;

            .switcher {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 16px auto 0px;
            }

            .input-label {
                text-align: right;
                margin: auto;
                padding: 0;

                @media screen and (max-width: 768px) {
                    text-align: left;
                    padding: 0 15px;
                }
            }

            .modal-footer {
                justify-content: center;
            }
        }
    }
}

.verify-secret-questions-answer.modal {
    .modal-dialog {
        .modal-content {
            border-radius: 24px;

            .random-question-row {
                justify-content: space-between;
                margin: auto 0px 8px 0px;

                .col {
                    padding: 0px;
                }

                .try-another-secret-question-btn-wrapper-col {
                    display: flex;
                    justify-content: flex-end;
                }

                .try-another-secret-question-btn {
                    text-align: right;
                }
            }

            .link-btn {
                background: none;
                border: none;
                color: $linkClr;
                text-decoration: underline;
                cursor: pointer;
                font: inherit;
                padding: 0;
                margin-bottom: 0px;
            }

            .link-btn:hover {
                text-decoration: none;
            }

            .forger-secret-question-btn {
                margin-top: 16px;
            }
        }
    }
}

.verify-secret-questions-answer {
    .wrong-attempts {
        display: flex;
        align-items: center;
        margin-top: 0.5rem;
    }
}

.doc-pending-approval {
    small {
        font-size: 1rem;
        padding-top: 6px;
    }
}