@import "./src/scss/globals";
.package-card {
    position: relative;
    width: 100%;
    max-width: 800px;
    min-width: 800px;
    height: auto;
    min-height: 200px;
    border-radius: 20px;
    background-color: $white;
    padding: 40px 30px 20px 30px;
    margin: 12.5px 0 12.5px 0;

    @media screen and (max-width: 600px) {
        min-width: 300px;
    }

    @media (max-width: 500px) {
        padding: 20px 16px 20px 16px;
    }

    @media screen and (max-width: 900px) and (min-width: 600px) {
        min-width: 100%;
    }

    @media screen and (max-width: 1200px) and (min-width: 900px) {
        min-width: 530px;
        max-width: 530px;
    }

    @media (min-width: 900px) and (max-width: 980px) {
        min-width: 380px;
        max-width: 380px;
    }

    @media (min-width: 1200px) and (max-width: 1300px) {
        min-width: 530px;
        max-width: 530px;
    }

    @media (min-width: 1300px) and (max-width: 1500px) {
        min-width: 600px;
        max-width: 600px;
    }

    @media screen and (max-width: 575px) {
        min-width: initial;
    }

    .package-contents {
        display: flex;
        flex-wrap: wrap;

        .image {
            width: 130px;
            height: 130px;
            max-height: 130px;
            border-radius: 15px;
            object-fit: cover;
        }

        .square-image {
            object-fit: contain !important;

            @include respond(phone) {
                object-fit: contain !important;
            }
        }

        .description {
            display: flex;
            flex-direction: column;
            padding-left: 30px;
            width: 100%;
            flex: 1 0 0%;

            h3 {
                margin-bottom: 10px;
                font-weight: bold;
                color: $stratos;
                font-size: 1.5rem;
            }

            div {
                border-bottom: solid 1px $mid-gray;
                width: 100%;
                padding-bottom: 15px;
            }

            @media (max-width: 500px) {
                padding-left: 0;
                padding-top: 16px;
            }
        }

        @media screen and (max-width: 1200px) and (min-width: 900px),
        screen and (max-width: 600px) {
            .description {
                >h3 {
                    font-size: 22px;
                }

                >div {
                    font-size: 14px;
                }
            }

            .course-button {
                bottom: 20px;
            }
        }
    }

    .home-share-button {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    @media screen and (max-width: 500px) {
        .square-img {
            width: 100%;
            margin: 0;

            .image {
                height: 175px;
                max-height: 175px;
                width: 175px;
            }
        }
    }
}