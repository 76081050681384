@import "./src/scss/globals";
.mnemonic-slide {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    .slide-wrapper {
        flex: 1;
        overflow: auto;
    }
    .content {
        padding: 10px 20px;
    }
    .rows {
        display: flex;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        span {
            padding: 10px 20px;
            margin: 10px;
            font-size: 24px;
            font-weight: bold;
            border: solid 2px $brand-blue;
            border-radius: 50px;
            &:hover {
                cursor: pointer;
                background-color: $brand-blue;
                color: white;
                transition: all 0.25s;
            }
        }
    }
    .row {
        display: flex;
        justify-content: center;
        width: 475px;
        & > img {
            height: 95px;
            width: 95px;
            margin: 10px;
            object-fit: contain;
            padding: 3px;
            border: 3px solid transparent;
            transition: border-color 0.2s ease-out;
            &.active {
                border: 3px solid $brand-blue;
            }
        }
        @media screen and (max-width: 900px) {
            width: 250px;
            & > img {
                width: 50px;
                height: 50px;
                margin: 5px;
            }
        }
    }
    .popup {
        position: absolute;
        background: darken($very-light-gray, 10%);
        border-top: 10px solid $brand-cyan;
        padding: 15px;
        top: 45px;
        right: 15px;
        width: 320px;
        height: calc(100% - 60px);
        box-shadow: 0 0 5px rgba($brand-blue, 0.3);
        z-index: 12;
        & > h1 {
            font-size: 18px;
            font-weight: bold;
        }
        & > span {
            position: absolute;
            right: 5px;
            top: 5px;
            height: 22px;
            width: 22px;
            text-align: center;
            line-height: 22px;
            background-color: $brand-cyan;
            border-radius: 11px;
            font-size: 12px;
            cursor: pointer;
        }
        & > div {
            height: 92%;
            overflow-y: auto;
        }
        @media screen and (max-width: 900px) {
            width: calc(100% - 30px);
            z-index: 20;
            max-height: 420px;
            height: auto;

            position: fixed;
            top: auto;
            bottom: 60px;
            left: 15px;
            right: 15px;
            margin: auto;

            & > div {
                height: auto;
                max-height: 270px;
            }
        }
    }
}
