@mixin checkout-main-button {
    display: flex;
    align-items: center;
    background-color: $indigo-dye;
    font-size: 26px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: $white;
    padding: 14px 90px;
    border: 0;
    border-radius: 45px;

    @include respond(phone) {
        width: 100%;
        padding: 14px 0;
        justify-content: center;
    }

    @include respond(smallest-phone) {
        width: 100%;
        padding: 10px 0;
        justify-content: center;
    }

    &.dark {
        background-color: $madison;
    }

    &.light-blue {
        background-color: $cornflower-blue;
    }

    &.light {
        background-color: $gallery;
        color: $stratos;
    }

    &.sm {
        font-size: 18px;
        padding: 14px 30px;

        @include respond(phone) {
            padding: 10px 30px;
        }
    }
}