@import "./src/scss/globals";
.page-lesson-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0px;
    flex-grow: 1;

    .card-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        max-width: 800px;
        width: 100%;
        flex-grow: 1;

        @media screen and (max-width: 500px) {
            max-width: 100%;
        }

        @import '../../../../../Courses/CKEditorContent.scss';
    }

    .banner-container {
        padding: 0px 24px;
    }

    @media screen and (max-width: 900px) {
        .lesson-page-header + div {
            padding-top: 48px;
        }

        .banner-container {
            padding: 0px;
        }
    }

    // Reduced Spacing for all types of Pages for Page View
    .lesson-card {
        min-height: auto;

        .text-page {
            &.theme-navy,
            &.theme-green {
                header {
                    & + .content {
                        padding-top: 20px;
                    }
                }
            }

            header {
                & + .content {
                    padding-top: 0;
                }

                h2 {
                    margin-bottom: 0;
                }
            }

            .content {
                p:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .heading-page {
            &.theme-navy,
            &.theme-green {
                header {
                    & + .content {
                        padding-top: 20px;
                    }
                }
            }

            header {
                min-height: auto;

                & + .content {
                    padding-top: 0;
                }

                h2 {
                    margin-bottom: 0;
                }
            }

            ol:last-child {
                margin-bottom: 0;
            }
        }

        .audio-page {
            .transcript {
                margin: 0;

                ol:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .document-page {
            header {
                min-height: auto;
            }

            .content {
                p:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
