@import "./src/scss/globals";
.popup-alert {
    display: flex;
    min-height: 30px;
    width: 400px;
    margin-top: 30px;
    border-radius: 10px;
    transform: translateY(-100px);
    transition: all 500ms linear;
    background-color: $white;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    pointer-events: auto;
    opacity: 0;
    flex-grow: 0;
    max-width: 90%;
    &:hover {
        cursor: pointer;
    }
    &.active {
        opacity: 1;
        transform: translateY(0);
    }
    .colour-bar {
        height: 100%;
        width: 6px;
        border-radius: 5px 0 0 5px;
        flex-shrink: 0;
        &.success {
            background-color: $success;
        }
        &.error {
            background-color: $error;
        }
        &.warning {
            background-color: $warning;
        }
        &.info {
            background-color: $info;
        }
    }
    .alert-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 0 10px 10px 0;
        .title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            border-bottom: solid 1px $mid-gray;
            padding: 3px 5px 3px 10px;
            font-size: 18px;
            font-weight: bold;
            span::first-letter {
                text-transform: uppercase;
            }
            i {
                color: $black;
                font-size: 14px;
                padding: 5px 7px;
                &:hover {
                    transition: transform 30ms linear;
                    transform: scale(1.3);
                }
            }
        }
        .message {
            padding: 5px 10px;
            font-size: 14px;
            overflow: scroll;
            span::first-letter {
                text-transform: uppercase;
            }
        }
        .resize {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 20px;
            border-top: solid 1px $mid-gray;
            i {
                font-size: 12px;
            }
            &:hover {
                transition: background-color 50ms linear;
                background-color: $very-light-gray;
            }
        }
    }
}

.announcement-alert {
    display: flex;
    min-height: 30px;
    width: 600px;
    margin-top: 30px;
    border-radius: 10px;
    transform: translateY(-100px);
    transition: all 500ms linear;
    background-color: $error-light-10;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    pointer-events: auto;
    opacity: 0;
    flex-grow: 0;
    max-width: 90%;
    border: 1px solid $error;
    padding: 18px;
    &:hover {
        cursor: pointer;
    }
    &.active {
        opacity: 1;
        transform: translateY(0);
    }

    .announcement-alert-outer-wrapper {
        width: 100%;

        a {
            color: $linkClr;
        }

        .announcement-alert-inner-wrapper {
            .announcement-alert-title-outer-wrapper {
                display: flex;
                justify-content: space-between;

                .announcement-alert-title-inner-wrapper {
                    display: flex;
                    align-items: center;

                    .icon {
                        & i {
                            font-size: 24px;
                            color: $error;
                        }
                    }

                    .announcement-alert-title {
                        margin-left: 12px;
                        font-weight: bold;
                    }
                }
            }
            .announcement-alert-message {
                margin-top: 12px;
            }
        }
    }
}
