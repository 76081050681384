@import "./src/scss/globals";
.bio-sig-auth {
    iframe.hidden {
        display: none;
    }

    iframe.visible {
        display: block;
    }

    iframe {
        border: none;
        width: 900px;
        height: 600px;
        @media screen and (max-width: 900px) {
            padding: 10px;
            max-width: 100%;
            height: calc(100vh - $mobile-header-height);
        }
    }
}
