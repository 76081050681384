@import "./src/scss/globals";
.course-proctoring {
    position: fixed;
    z-index: 1000;
    top: $header-height;
    left: 0;
    height: auto;
    width: 100vw;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    right: 0;

    .centered-block.loading {
        align-items: center;
    }

    @media screen and (max-width: 900px) {
        top: $mobile-header-height;
        height: calc(100vh - $mobile-header-height);
    }
}

.action-confirmation-popup-flagged-since-long-time {
    .modal-footer {
        justify-content: center;
    }

    #common_issues_para {
        margin: 12px 0px;
    }
}
