@import "./src/scss/globals";
.lesson-slide-header {
    width: 100%;
    display: flex;
    align-items: flex-start;

    >header {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 50px;

        .sidebar-toggle {
            display: none;
        }

        .course-details {
            display: flex;

            .course-title {
                font-size: 16px;
                font-weight: bold;
                margin: 0;
                padding: 0;
                padding-right: 72px;
            }
        }

        .lesson-name {
            display: flex;
            align-items: center;
            height: 30px;
            font-size: 16px;
            color: $light-gray;
            margin: 0;
            padding-right: 120px;
            margin-top: 4px;

            .lesson-type {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 30px;
                padding: 0 20px;
                font-size: 16px;
                margin-right: 10px;
                color: $white;

                &.state {
                    background-color: $state-brown;
                }

                &.national {
                    background-color: $cornflower-blue;
                }

                i {
                    margin-right: 10px;
                }
            }

            >i {
                margin: 0 5px;
                color: $black;
            }

            .slide-header-chapter {
                flex-shrink: 0;
                max-width: 60%;
                margin-bottom: 4px;
            }

            .slide-header-lesson {
                flex-grow: 1;
                margin-bottom: 4px;
            }
        }

        .lesson-timer {
            position: absolute;
            left: auto;
            bottom: 0px;
            right: 8px;
        }
    }

    .overflow-elipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    @media screen and (max-width: 1200px) {
        header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            height: 50px;

            .course-details {
                width: 100%;
            }

            .sidebar-toggle {
                display: block;
            }

            .lesson-name {
                box-sizing: border-box;
                width: 100%;
            }
        }
    }

    @media screen and (max-width: 900px) {
        header {
            background-color: rgba($blue, 0.1);
            margin-bottom: 0;

            &.state {
                background-color: $state-brown;
            }

            &.national {
                background-color: $cornflower-blue;
            }

            .course-details {
                display: none;

                .course-title {
                    text-align: center;
                    flex-grow: 1;
                }
            }

            .lesson-name {
                padding-left: 50px;
                justify-content: start;
                margin: 0;
                font-size: 14px;

                .lesson-type {
                    margin-right: 5px;
                    padding: 0 5px 0;

                    &>i {
                        margin: 0;
                        font-size: 22px;
                        color: black;
                    }

                    &>span {
                        display: none;
                    }
                }

                .slide-header-chapter {
                    margin: 0;
                    color: black;
                }

                .slide-header-lesson {
                    flex-grow: 0;
                    margin: 0;
                    color: black;
                }
            }

            .lesson-timer {
                bottom: auto;
            }

            .lesson-timer.admin-preview {
                right: 55px;
                font-size: 13px;
            }
        }
    }
}

.lesson-container {
    .slide-layout {
        .sidebar-toggle {
            background-color: white;
            left: 0;
            padding: 8px;
            border-radius: 0 8px 8px 0;
            top: 4px;
        }
    }
}