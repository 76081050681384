@import "./src/scss/globals";
.crop-photo-update {
    &-modal {
        margin-top: 0.3rem;
        .modal-dialog {
            width: 30rem !important;

            @include respond(phone) {
                width: unset !important;
                padding: 16px 10px !important;
            }
        }

        .modal-content {
            border-radius: 0.8rem !important;
        }

        .modal-header {
            background-color: #25255e;
            color: #fff;
            align-items: center;
            justify-content: center;
            border-top-left-radius: 0.5rem;
            border-top-right-radius: 0.5rem;
            padding: 1.5rem 1.5rem;
            @media screen and (max-width: 375px) {
                padding: 1rem 1rem;
            }
        }

        .modal-body {
            padding: 0;
            .crop-container-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0.5rem;
                flex-direction: column;

                .crop-container {
                    position: relative;
                    width: 100% !important;
                    background: #333;
                    height: calc(100vh - 500px);
                }

                .image-zoom-wrapper {
                    display: flex;
                    width: 100%;
                    margin-top: 1rem;
                    .image-zoom-container {
                        width: 100%;
                        .form-control {
                            appearance: auto;
                            cursor: default;
                            color: rgb(157, 150, 142);
                            padding: initial;
                            border: initial;
                            margin: 2px;
                            &:focus {
                                outline: none !important;
                                box-shadow: unset !important;
                            }
                        }
                    }
                    .form-group {
                        width: 100%;
                    }
                }
            }
        }

        .modal-footer {
            align-items: center;
            justify-content: flex-end;

            .confirm-profile {
                width: 9rem;
                color: $white;
                margin-left: 8px;

                &:hover {
                    color: $black;
                }
            }
        }
    }
}
