@import "./src/scss/globals";
.quiz-attempt {
    .review-status-container {
        display: flex;
        .status {
            width: 6rem !important;
            display: flex;
            align-items: center;
            span {
                margin-right: 5px;
            }
        }
    }
    .colour-bar {
        &.fail {
            background-color: $warning !important;
        }
        &.nullified {
            background-color: $light-gray !important;
        }
        &.in-review {
            background-color: $stratos !important;
        }
        &.in-progress {
            background-color: $info !important;
        }
    }
    .percentage-score {
        width: 55px;
    }
    .quiz-attempt-buttons-wrapper {
        display: flex;
        gap: 12px;
    }
    .button-width {
        width: 9rem;
        .exam-certificate-btn--disabled {
            border: 1px solid $mid-gray;
            background-color: $mid-gray;
            color: $mid-dark-gray !important;
            cursor: not-allowed;
            &:hover,
            &:active,
            &:focus {
                background-color: $mid-gray;
                outline: 0;
                color: unset;
                box-shadow: none;
            }
        }
    }
    .status {
        width: 86px !important;
    }
}
