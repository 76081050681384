@import "./src/scss/globals";
.image-page {
    position: relative;
    overflow-y: auto;
    .image-page-padding {
        padding: 20px;
    }
    .file-image {
        float: left;
        margin: 7px 20px 0px 0px;
        max-width: 50%;
        &--LEFT {
            float: left;
            margin: 0px 20px 0px 0px;
        }
        &--RIGHT {
            float: right;
            margin: 0px 0px 0px 20px;
            object-position: right top;
        }
        &--TOP {
            max-width: 100%;
            float: none;
            height: 250px;
            width: 100%;
            margin: 0px;
        }
        &--cover {
            object-fit: cover;
        }
        &--contain {
            object-fit: contain;
        }
    }
    .TOP {
        margin: 0 20px;
    }
    @media screen and (max-width: 500px) {
        display: flex;
        flex-direction: column;
        .file-image {
            float: none;
            max-width: 100%;
            margin: 0 0 20px 0;
            width: 100%;
        }
    }
    .background-image {
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .content {
        &--bg {
            max-width: 66.6%;
            padding: 20px;
            position: relative;
            padding-left: 0;
            @media screen and (max-width: 800px) {
                max-width: 100%;
                padding: 20px;
            }
        }
        &--top {
            @media screen and (max-width: 500px) {
                padding-top: 0;
            }
        }
        .display-content {
            &__TOP {
                padding: 20px;
                padding-top: 0;
            }

            &__BG {
                padding: 20px;
            }
            &__RIGHT,
            &__LEFT {
                padding: 20px 0;
                padding-top: 0;
            }
        }
        header {
            padding: 20px 20px 20px 20px;
        }
        div {
            img {
                max-width: 100%;
            }
        }
    }

    .content--TOP,
    .content--BG {
        header {
            padding: 20px 20px 20px 20px;
        }
    }

    .content--RIGHT,
    .content--LEFT {
        header {
            padding: 0px 20px 20px 0;
        }
    }
}

.theme-navy,
.theme-green {
    .image-page-padding {
        padding: 20px 0 !important;
        @media screen and (max-width: 800px) {
            padding: 20px !important;
        }
    }
    .content--RIGHT,
    .content--LEFT {
        header {
            padding: 20px 20px 20px 20px;
        }
    }
    .TOP {
        margin: 0;
        @media screen and (max-width: 800px) {
            margin: 0 20px;
        }
    }

    .content {
        &--top {
            padding-top: 20px;
            @media screen and (max-width: 800px) {
                padding: 20px;
            }
        }
        .display-content {
            &__RIGHT,
            &__LEFT {
                padding: 20px;
                padding-right: 0;
            }
            &__LEFT {
                padding-left: 0 !important;
                @media screen and (max-width: 500px) {
                    padding-left: 20px !important;
                }
            }
            &__TOP,
            &__BG {
                padding-top: 20px;
            }
        }
    }
}

.theme-default {
    .content--RIGHT {
        header {
            padding: 0px 20px 20px 0px !important;
        }
    }
    .TOP {
        margin-top: 0px;
    }
    .content--bg {
        padding-left: 0;
        padding-top: 0;
    }
    .display-content {
        &__BG {
            padding-top: 0px !important;
        }
    }
}
