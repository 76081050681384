@import "./src/scss/globals";
body {
    .examStartModal {
        @media (min-width: 1366px) {
            .modal-dialog {
                max-width: 600px;
            }
        }
    }
    .password {
        position: relative;
        .password__show {
            position: absolute;
            top: auto;
            left: auto;
            right: 10px;
            bottom: 10px;
        }
    }
}

.btn-footer {
    justify-content: flex-end;
    display: flex;
}
