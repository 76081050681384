@import "./src/scss/globals";
.hotspot-list-slide {
    $self: &;
    display: flex;
    flex-direction: column;
    .section {
        padding: 20px;
        & > p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .hotspotContent {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
    }
    .list {
        display: flex;
        .item {
            position: relative;
            flex: 1;
            .file-image {
                height: 80px;
                width: 80px;
                min-height: 80px;
                min-width: 80px;
                object-fit: contain;
                margin: auto;
            }
            h6 {
                text-align: center;
                margin: 5px 4px 10px 0px;
            }
        }
    }
    .activeItemContent {
        background-color: lighten($mid-gray, 8%);
        padding: 8px 8px 8px 0;
        flex: 1 1 auto;
        & > span {
            display: block;
            width: 100%;
            height: 100%;
            background-color: $white;
            padding: 15px 20px;
            overflow-y: auto;
            &.greyed {
                display: flex;
                justify-content: center;
                align-items: center;
                color: $light-gray;
                font-style: italic;
            }
        }
    }
    .horizontal {
        display: flex;
        flex-direction: column;
        height: 100%;
        .hotspotContent:nth-child(2) {
            height: 100%;
        }
        .activeItemContent {
            overflow: auto;
            > h3 {
                margin-left: 10px;
            }
        }
        .list {
            transform: rotateX(180deg);
            padding: 35px 0 15px;
            display: flex;
            overflow-x: auto;
            flex: 0 0 auto;
            .item {
                margin: auto;
                transform: rotateX(180deg);
                flex: 0 0 25%;
                padding: 0 10px;
                &[data-manysiblings='true'] {
                    margin-right: 14px;
                    margin-left: unset;
                    &:first-child {
                        margin-left: 14px;
                    }
                }
                .hotspotIcon {
                    box-shadow: 0 0 10px #fff;
                    bottom: -25px;
                    left: 50%;
                    transform: translateX(-50%);
                }
                .active {
                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -35px;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 0px;
                        height: 0px;
                        border-left: 8px solid transparent;
                        border-right: 8px solid transparent;
                        border-bottom: 8px solid lighten($mid-gray, 7%);
                    }
                }
            }
        }
    }
    .vertical {
        overflow-y: hidden;
        display: flex;
        flex-direction: column;
        .hotspotContent {
            flex-direction: row;
            overflow: auto;
        }
        .activeItemContent {
            display: flex;
            flex-direction: column;
        }
        .list {
            $this: &;
            background-color: lighten($mid-gray, 7%);
            padding: 0 36px 0 15px;
            flex-direction: column;
            justify-content: unset;
            overflow-y: auto;
            overflow-x: hidden;
            direction: rtl;
            scroll-margin: 20px;
            min-width: 155px;
            max-width: 155px;
            .item {
                margin: auto;
                padding: 10px 0;
                &[data-manysiblings='true'] {
                    margin-bottom: 14px;
                    margin-top: unset;
                    &:first-child {
                        margin-top: 14px;
                    }
                }
                .file-image {
                    margin-bottom: 0;
                }
                .hotspotIcon {
                    box-shadow: 0 0 10px #fff;
                    right: -30px;
                    top: 50%;
                    transform: translateY(-50%);
                }
                .active {
                    &::after {
                        content: '';
                        position: absolute;
                        right: -53px;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 0px;
                        height: 0px;
                        border-top: 8px solid transparent;
                        border-bottom: 8px solid transparent;
                        border-right: 8px solid $white;
                    }
                }
            }
        }

        &.vertical-with-content {
            overflow-y: scroll;

            .hotspotContent {
                min-height: 300px;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .hotspot-list-slide {
        .horizontal {
            .list {
                .item {
                    flex: 0 0 33.33%;
                }
            }
        }
    }
}
