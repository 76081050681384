@import "./src/scss/globals";
.source-selected-dropdown {
    background-color: $mid-gray !important;
}
.country-select-wrapper {
    .selected-flag {
        position: relative;
        width: 38px;
        height: 100%;
        padding: 0 0 0 8px;
        border-radius: 3px 0 0 3px;
    }
    button {
        display: block;
        width: 100%;
        height: calc(1.5em + 1rem + 2px);
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #121212;
        border-radius: 0.25rem;
        padding: 0.5rem 0;
        border-right: unset;
        border-top-right-radius: unset;
        border-bottom-right-radius: unset;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    .dropdown-toggle {
        color: #495057 !important;
        background-color: $white !important;
        border-color: #121212 !important;
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        &:focus {
            box-shadow: 0 0 0 0.2rem rgba(37, 37, 94, 0.25) !important;
        }
        &::after {
            margin: 5px;
            border-top: 0.4em solid !important;
            border-right: 0.4em solid transparent !important;
            border-left: 0.4em solid transparent !important;
        }
    }

    .dropdown-menu {
        height: calc(100vh - 700px);
        overflow: auto;
        width: 23rem;
        padding: 0.5rem 0.8rem !important;
        min-height: calc(100vh - 572px);
        @media screen and (max-width: 1570px) {
            height: calc(100vh - 620px);
        }

        .input-group {
            .input-group-append {
                cursor: pointer;
            }
        }
        .country-label {
            display: flex;
            width: 100%;
            padding: 0.25rem 0.1rem;
            clear: both;
            font-weight: 400;
            color: #212529;
            text-align: inherit;
            white-space: nowrap;
            background-color: transparent;
            align-items: center;
            border: 0;
            & > label {
                margin-left: 8px;
            }
            img {
                width: 35px;
                height: 25px;
                margin-right: 10px;
            }
        }
        .dropdown-item {
            padding: 0.25rem 0rem;
            &.active,
            &:active {
                background-color: $mid-gray !important;
                color: $black !important;
            }
        }
        .no-country-found {
            display: flex;
            height: 90%;
            align-items: center;
            justify-content: center;
        }
    }
}
.number-input-text {
    input[type='tel'] {
        border-top-left-radius: unset;
        border-bottom-left-radius: unset;
    }
}
