@import "./src/scss/globals";
.radio-question {
    & > p {
        font-weight: bold;
        .correct {
            color: $black;
        }
        .incorrect {
            color: $black;
        }
    }
    & > .options {
        margin-left: 10px;
        & > .option {
            display: flex;
            align-items: center;
            padding: 5px;

            & .feedback-text {
                flex: 0 0 auto;
                font-weight: 700;
                color: #000;
                padding: 0 10px;
                text-align: right;
                flex-grow: 1;
            }

            & > label {
                margin-left: 20px;
                margin-bottom: 0;
                flex-grow: 1;
            }

            & .option-content-wrapper {
                display: flex;
                justify-content: space-between;
                margin-right: 8px;
                width: 100%;
                flex-wrap: wrap;
                row-gap: 4px;
            }

            &.correct-feedback {
                color: $black;
                background-color: $correct-green;
                margin-bottom: 4px;
            }
            &.incorrect-feedback {
                color: $black;
                background-color: $incorrect-orange;
                margin-bottom: 4px;
            }
            &.reveal-correct-feedback {
                background-color: $light-gray-100;
                color: $black;
                margin-bottom: 4px;
            }
        }
        .radio-correct {
            color: $black;
            background-color: $correct-green;
        }
        .radio-incorrect {
            color: $black;
            background-color: $incorrect-orange;
        }
        .empty-radio {
            margin-right: 15px;
        }
    }
}
