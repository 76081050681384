@import "./src/scss/globals";
.fill-gap-question {
    .question {
        display: block;
        font-weight: bold;

        & > select {
            padding: 0 25px 0 2px;
            line-height: 1;
            height: 30px;
            width: auto;
            min-width: 100px;
            margin: 0 5px;
        }
        .select-answer {
            margin: 0 3px;
            font-style: italic;
            text-decoration: underline;
        }
    }

    & > .options {
        margin-left: 10px;
        font-weight: normal;
        width: 100%;

        & > .option {
            display: flex;
            align-items: center;
            padding: 5px;

            & .feedback-text {
                flex: 0 0 auto;
                font-weight: 700;
                color: #000;
                padding: 0 10px;
                text-align: right;
                flex-grow: 1;
            }

            & .option-content {
                margin-left: 20px;
                margin-bottom: 0;
            }

            & .option-content-wrapper {
                display: flex;
                justify-content: space-between;
                margin-right: 8px;
                width: 100%;
                flex-wrap: wrap;
                row-gap: 4px;
            }

            &.correct-feedback {
                color: $black;
                background-color: $correct-green;
                margin-bottom: 4px;
            }
            &.incorrect-feedback {
                color: $black;
                background-color: $incorrect-orange;
                margin-bottom: 4px;
            }
            &.reveal-correct-feedback {
                background-color: $light-gray-100;
                color: $black;
                margin-bottom: 4px;
            }
        }
    }
}

.correct {
    color: $black;
}

.incorrect {
    color: $black;
}
