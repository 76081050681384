@import "./src/scss/globals";
.quiz-previous-attempts {
    display: flex;
    flex-direction: column;
    background-color: $cyan;
    > h5 {
        font-weight: bold;
        margin-left: 20px;
    }
}
