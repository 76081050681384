@import "./src/scss/globals";
.upsell-card {
    width: 100%;
    max-width: 800px;
    min-width: 600px;
    border-radius: 20px;
    background-color: $white;
    margin: 12.5px 0 12.5px 0;
    display: flex;
    flex-wrap: wrap;
    padding: 40px 30px 20px 30px;

    @media screen and (max-width: 500px) {
        flex-direction: column;
    }

    @media screen and (max-width: 600px) {
        min-width: 300px;
        padding: 20px 16px 20px 16px;
    }

    @media screen and (max-width: 900px) and (min-width: 600px) {
        min-width: 460px;
    }

    @media screen and (max-width: 1200px) and (min-width: 900px) {
        min-width: 380px;
        max-width: 380px;
    }

    @media (min-width: 900px) and (max-width: 980px) {
        min-width: 380px;
        max-width: 380px;
    }

    @media (min-width: 1200px) and (max-width: 1300px) {
        min-width: 530px;
        max-width: 530px;
    }

    @media (min-width: 1300px) and (max-width: 1500px) {
        min-width: 600px;
        max-width: 600px;
    }

    @include respond(phone) {
        margin: 0;
    }

    .image {
        margin-right: 30px;
        flex: 0 0 auto;
        width: 130px;
        height: 130px;
        border-radius: 15px;
        object-fit: cover;
    }

    .confirmation-square-image {
        @include respond(phone) {
            display: flex;
            justify-content: center;
        }

        .image {
            @include respond(phone) {
                width: 150px;
                height: 150px;
                margin-right: 0;
                margin-bottom: 0;
            }
        }

        @media screen and (max-width: 992px) {
            width: 100%;
            margin: auto;

            .image {
                display: block;
                margin: auto;
            }
        }
    }

    .square-image {
        object-fit: contain !important;

        @include respond(phone) {
            object-fit: contain !important;
        }
    }

    @media screen and (max-width: 500px) {
        .square-img {
            width: 100%;
            margin: 0;
            display: flex;
            justify-content: center;

            .image {
                height: 175px;
                max-height: 175px;
                width: 175px;
                margin-right: 0;
            }
        }
    }

    .content {
        flex: 0 0 auto;
        width: 60%;

        h2 {
            margin-bottom: 10px;
            font-weight: bold;
            color: $stratos;
            font-size: 1.5rem;

            @include respond(phone) {
                text-align: center;
            }
        }

        div {
            font-family: 'Open Sans', sans-serif;
            white-space: pre-line;

            @include respond(phone) {
                text-align: center;
            }
        }

        .confirmation_actions {
            @media screen and (max-width: 992px) {
                margin: auto;
                margin-top: 1rem;
            }
        }

        @media screen and (max-width: 500px) {
            width: auto;
            padding-top: 16px;
            justify-content: center;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .content-upsells {
        width: 80%;

        @include respond(phone) {
            width: 100%;
        }

        .confirmation-header {
            @include respond(phone) {
                h2 {
                    text-align: center;
                }

                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
            }
        }

        @media screen and (max-width: 992px) {
            width: 100%;
            margin-top: 15px;
        }

        @media screen and (max-width: 992px) {
            text-align: center;
        }
    }

    .timer-icon {
        width: 30px;
        height: 30px;
    }

    .upsells-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        width: 100%;

        @include respond(phone) {
            justify-content: center;
        }

        .read-more-button {
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            min-width: 150px;
            background-color: $mid-gray;
            border-radius: 100px;
            margin-left: 14px;
            font-size: 20px;
            padding: 0 30px;
            width: fit-content;
            margin-top: 10px;
            cursor: pointer;

            &:link,
            &:visited {
                color: $black;
            }

            &:hover {
                background-color: $very-light-gray;

                &:link,
                &:visited {
                    color: $brand-blue;
                }
            }

            @media screen and (max-width: 1200px) {
                font-size: 16px;
                height: 40px;
                margin: 10px 5px 0;
                padding: 0 15px;
                min-width: initial;
            }
        }

        .button--disabled {
            border: 1px solid $mid-gray;
            background-color: $mid-gray !important;
            color: $mid-dark-gray !important;
            cursor: not-allowed !important;

            &:hover,
            &:active,
            &:focus {
                background-color: $mid-gray;
                outline: 0;
                color: unset;
                box-shadow: none;
            }
        }

        .buy-now-button {
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            min-width: 150px;
            background-color: $brand-cyan;
            border-radius: 100px;
            margin-left: 14px;
            font-size: 20px;
            padding: 0 30px;
            width: fit-content;
            margin-top: 10px;
            font-weight: normal;
            color: $white;
            cursor: pointer;

            &:hover {
                background-color: $brand-cyan-light;
                color: $brand-blue;

                &:link,
                &:visited {
                    color: $brand-blue;
                }
            }

            @media screen and (max-width: 1200px) {
                font-size: 16px;
                height: 40px;
                margin: 10px 5px 0;
                padding: 0 15px;
                min-width: initial;
            }
        }
    }
}
