@import "./src/scss/globals";
.exam-button {
    max-width: 180px !important;
    background-color: $brand-cyan;

    &:hover {
        background: lighten($brand-cyan, 10);
    }

    &-disabled {
        border: 1px solid $mid-gray !important;
        background-color: $mid-gray !important;
        color: $mid-dark-gray !important;
        cursor: not-allowed !important;

        &:hover,
        &:active,
        &:focus {
            background-color: $mid-gray;
            outline: 0;
            color: unset;
            box-shadow: none;
        }
    }
}