@import "./src/scss/globals";
.profile-wrapper {
    @media screen and (max-width: 500px) {
        padding: unset !important;
    }
    .user-profile-address {
        padding: 2rem;
        @media screen and (max-width: 500px) {
            padding: 2rem 0.5rem;
        }
        .header-wrapper {
            @media screen and (max-width: 500px) {
                h3 {
                    font-size: 1.5rem;
                }
            }
        }
        .form__content {
            padding: 2rem 0;
            .autofill-address-check-box {
                .check-label {
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;
                }
            }
            .address-wrapper {
                @media screen and (max-width: 500px) {
                    flex-direction: column;
                    .residential-block {
                        margin-top: 3rem;
                    }
                    .billing-block {
                        margin-top: 1rem;
                    }
                }
            }
            @media screen and (max-width: 500px) {
                padding: 1.5rem 0;
            }
        }
    }
}
