@import "./src/scss/globals";
.toggle-input {
    $self: &;
    cursor: pointer;
    position: relative;

    & > input {
        opacity: 0;
        width: 22px;
        height: 22px;
        position: absolute;
        z-index: 3;
        top: -1px;
        left: -1px;
    }

    &--radio {
        height: 22px;
        width: 22px;
        background-color: $white;
        border-radius: 11px;
        border: 2px solid rgba($light-gray, 0.5);
        transition: border-color 0.2s ease-out, box-shadow 0.2s ease-out, background-color 0s linear 0.2s;
        position: relative;
        box-shadow: inset 0 -22px 0 -22px $brand-blue;

        &::before {
            content: "";
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(1.2);
            opacity: 0;
            background-color: $white;
            width: 22px;
            height: 22px;
            border-radius: 11px;
            transition: transform 0.15s ease-out 0.1s, opacity 0.15s ease-out 0.1s;
        }

        &:hover {
            border-color: $brand-blue;
        }

        &--checked {
            border-color: $brand-blue;
            box-shadow: inset 0 -44px 0 -22px $brand-blue;
            background-color: $brand-blue;

            &::before {
                transform: translate(-50%, -50%) scale(0.3);
                opacity: 1;
            }
        }
    }

    &--checkbox {
        height: 22px;
        width: 22px;
        background-color: $white;
        border-radius: 6px;
        border: 2px solid rgba($light-gray, 0.5);
        transition: border-color 0.2s ease-out, background-color 0.1s ease-out;
        position: relative;
        box-shadow: inset 0 0 0 0 $brand-blue;

        &:hover {
            border-color: $brand-blue;
        }

        & > svg {
            transform: translateY(-5px);
            & > path {
                fill: none;
                stroke: white;
                stroke-width: 5;
                stroke-linecap: round;
                stroke-dasharray: 48;
                stroke-dashoffset: 100%;
                transition: stroke-dashoffset 0.1s ease-out 0.1s;
            }
        }

        &--checked {
            border-color: $brand-blue;
            background-color: $brand-blue;

            & > svg {
                & > path {
                    stroke-dashoffset: 0%;
                }
            }
        }
    }
}
