@import "./src/scss/globals";
.calculation-wrapper {
    display: flex;
    flex-direction: column;
    gap: 3px;

    .col-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .bold-text {
            font-weight: bold;
        }

        .discount-code {
            color: $red;
        }
    }

    hr {
        margin: 0.5rem 1rem;
        border-bottom: 1px solid $mid-gray !important;
        border: unset;
    }
}

.single-page-calculation-wrapper {
    margin: 0rem 0.5rem;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-wrap: wrap;
    background: #fff;
    padding: 1.8rem;
    border-radius: 15px;
    box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px;

    .apply-discount-wrapper {
        @media (min-width: 600px) and (max-width: 1024px) {
            margin: unset !important;
        }
        .apply-coupon-wrapper {
            margin-bottom: 0.8rem;

            .code-title-wrapper {
                display: none;
            }

            .coupon {
                width: 100%;
            }
        }
    }
}
