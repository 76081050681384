@import "./src/scss/globals";
.checkout-survey-modal {
    .modal-dialog {
        @media screen and (max-width: 1024px) {
            width: 100% !important;
        }
        @media (min-width: 1024px) and (max-width: 1365px) {
            width: 50% !important;
            max-width: unset;
        }
        @media (min-width: 1366px) and (max-width: 2560px) {
            max-width: 40% !important;
        }

        @include respond(phone) {
            padding: 16px 10px !important;
        }
    }
    .modal-body {
        @include respond(phone) {
            min-height: initial;
            max-height: initial !important;
        }
        .form_error {
            textarea {
                border: 1px solid #ff4343 !important;
                color: #ff4343;
            }
        }

        .survey-header {
            font-weight: bold;
        }

        .survey-body,
        .survey-header {
            font-size: 1.17rem;
        }
    }
    .modal-content {
        border-radius: 0.9rem !important;
        border-color: #25255e;
        .modal-header {
            background-color: #25255e;
            color: #fff;
            align-items: center;
            justify-content: center;
            border-top-left-radius: 0.7rem;
            border-top-right-radius: 0.7rem;
            padding: 1.5rem 1.5rem;
            @media screen and (max-width: 375px) {
                padding: 1rem 1rem;
            }
            .btn-close {
                filter: invert(1);
                opacity: 1;
            }
        }
        .modal-footer {
            button {
                color: $white;
                &:hover {
                    color: $black;
                }
            }
        }
    }
}
