@import "./src/scss/globals";
:root {
    --transition-slide-background-image-position: to top;
}

.transition-slide {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 20px 0 20px;
    $i: &;
    & > div > .file-image {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        padding: 0 !important;
        svg {
            font-size: 4rem;
        }
    }
    & > .transition-card-with-background-image::after {
        background-image: linear-gradient(
            var(--transition-slide-background-image-position),
            rgba(255, 255, 255, 0.4) 45%,
            rgba(255, 255, 255, 1.1) 100%
        );
    }
    & > div::after {
        content: '';
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }
    .icon,
    .content {
        position: relative;
        z-index: 3;
    }
    .icon {
        width: 120px;
        height: 120px;
        object-fit: contain;
    }
    .content {
        overflow-y: auto;
        & > p:last-child {
            margin-bottom: 0;
        }
    }
    &.top {
        align-items: flex-start;
    }
    &.bottom {
        align-items: flex-end;
    }
    &.left {
        align-items: flex-start;
    }
    &.right {
        align-items: flex-start;
        svg {
            font-size: 4rem;
        }
    }
    & > .transition-card-with-background-image {
        height: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &::after {
            background-image: linear-gradient(
                var(--transition-slide-background-image-position),
                rgba(255, 255, 255, 0.4) 45%,
                rgba(255, 255, 255, 1.1) 100%
            );
        }
    }
    & > .transition-card-without-background-image {
        height: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    & > div::after {
        content: '';
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }
    .icon,
    .content {
        position: relative;
        z-index: 3;
    }
    .icon {
        width: 120px;
        height: 120px;
        object-fit: contain;
        margin-bottom: 10px;
    }
    .content {
        overflow-y: auto;
        padding-bottom: 8px;
        & > p:last-child {
            margin-bottom: 0;
        }
    }
    .top {
        flex-direction: column;
        #{$i}__icon {
            margin-bottom: 10px;
        }
    }
    .bottom {
        flex-direction: column-reverse;
        #{$i}__icon {
            margin-top: 10px;
        }
    }
    .left {
        #{$i}__icon {
            margin-right: 10px;
        }
    }
    .right {
        flex-direction: row-reverse;
        #{$i}__icon {
            margin-left: 10px;
        }
        #{$i}__content {
            flex: 1;
        }
    }
}
