@import "./src/scss/globals";
/* Tackle responsive design using a card layout on mobile */

.table {
    &.table--no-head {
        border-radius: 0.3rem;
        border: 1px solid lighten($black, 70%);
        & > .main > .row {
            border-width: 1px 0 0 0;

            &:first-child {
                border: 0;
            }
        }
    }

    & > .header,
    & > .main {
        .col {
            flex: 1;
            font-size: 1rem;
            line-height: 1.5;
            padding: 0.65rem;

            &.col--controls {
                display: flex;
                flex: 0;
                align-items: center;

                .btn {
                    i {
                        font-size: 14px;
                    }
                    &:not(:last-child) {
                        margin-right: 0.75rem;
                    }
                }
            }

            &.col--center {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .bp,
            .bd,
            .btn {
                @extend %flex-center;
                height: 26px;
                border-radius: 6px;
                padding: 0 4.5px;
                font-size: 0.75rem;
            }

            a {
                text-decoration: none;
        
                &:hover {
                    color: $brand-blue;
                    text-decoration: underline;
                }
            }

            input[type='text'],
            input[type='email'],
            input[type='number'],
            input[type='password'],
            select {
                font-size: 0.875rem;
                height: auto;
                padding: 1.5px 6px;
            }

            & > div {
                display: flex;

                & > div[class*='-container'] {
                    flex: 1;

                    & > div[class*='-control'] {
                        min-height: 26px;
                        height: 26px;
                        border-color: $black;

                        & > div {
                            height: 26px;
                            line-height: 22px;

                            div[class*='-singleValue'],
                            div[class*='-ValueContainer'] {
                                height: 22px;
                            }

                            div[class*='-Input'] {
                                height: 22px;

                                input {
                                    height: 22px;
                                    line-height: 22px;
                                    transform: translateY(-4px);
                                }
                            }
                        }

                        svg {
                            height: 16px;
                            width: 16px;
                            color: $black;
                        }
                    }
                }
            }
        }
    }

    & > .header {
        display: flex;
        border-bottom: 3px solid lighten($black, 70%);
        background-color: lighten($black, 85%);
        font-weight: bold;
        border-radius: 0.3rem 0.3rem 0 0;
        padding: 4px 0;

        .col {
            display: flex;
            align-items: center;
            font-size: 0.875rem;
            font-weight: bold;
            padding: 1rem 0.65rem;
            color: $black;
            user-select: none;

            .col--sort {
                display: flex;
                flex-direction: column;
                margin-left: auto;
                align-items: center;
                background-color: transparent;
                padding: 0;
            }

            .col--filter {
                cursor: pointer;
                background-color: transparent;
            }
        }
    }

    & > .main {
        position: relative;

        .row {
            display: flex;
            margin: 0;
            border-width: 0 1px 1px 1px;
            border-style: solid;
            border-color: lighten($black, 70%);
            background-color: $white;

            &.row--sep {
                border-top-width: 2px;
            }

            &.row--highlight {
                position: relative;
                box-shadow:
                    inset 0 20px 20px -20px rgba($warning, 0.8),
                    inset 0 -20px 20px -20px rgba($warning, 0.3);

                &::after {
                    content: '';
                    position: absolute;
                    top: -1px;
                    left: -1px;
                    width: 100%;
                    height: 100%;
                    border: 1px solid rgba($warning, 0.75);
                    box-sizing: content-box;
                    pointer-events: none;
                }
            }

            &:last-child {
                border-radius: 0 0 0.3rem 0.3rem;
            }

            .col {
                display: grid;
                align-items: center;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 0.875rem;

                &.col--controls,
                &.col--flex,
                &.limitTitle {
                    display: flex;
                }
            }
        }

        .table-loading {
            position: absolute;
            z-index: 1;
            color: $brand-blue;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($white, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;

            &.hide {
                display: none;
            }
        }
    }

    &__filter-popup {
        padding: 15px;
        border-radius: 10px;
    }

    &__filter-form {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        & > label {
            width: 100%;

            &:not(:last-child) {
                margin-bottom: 1rem;
            }

            & > div,
            & > input,
            & > select {
                margin-top: 0.5rem;
            }
        }
    }

    &-filters {
        display: flex;
        width: calc(100% - 120px);
    }

    &-filter {
        display: flex;
        font-size: 13px;
        align-items: center;
        height: 24px;
        padding: 0 10px;
        border: 1px solid $light-gray;
        color: lighten($black, 20%);
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 10px;
        }
    }
}
