@import "./src/scss/globals";
.cards-quiz {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    z-index: 10;
    overflow-y: auto;

    .form-check-label {
        display: block;
    }

    .option-content-wrapper {
        display: flex;
        justify-content: space-between;
        margin-right: 8px;
        width: 100%;
        flex-wrap: wrap;
        row-gap: 4px;
    }
    .question-wrap {
        margin-bottom: 40px;
        .quiz-feedback {
            margin-top: 20px;
            padding: 10px 20px;
            &.correct {
                color: $black;
                display: flex;
                background-color: $light-gray-100;
                border-left: 8px solid $correct-green;
            }
            &.incorrect {
                color: $black;
                display: flex;
                background-color: $light-gray-100;
                border-left: 8px solid $incorrect-orange;
            }
        }
    }
    &--option {
        padding: 4px;
        padding-left: 32px;

        & .feedback-text {
            flex: 0 0 auto;
            font-weight: 700;
            color: #000;
            padding: 0 10px;
            text-align: right;
            flex-grow: 1;
        }

        &.correct-feedback {
            color: $black;
            background-color: $correct-green;
            margin-bottom: 4px;
        }
        &.incorrect-feedback {
            color: $black;
            background-color: $incorrect-orange;
            margin-bottom: 4px;
        }
        &.reveal-correct-feedback {
            background-color: $light-gray-100;
            color: $black;
            margin-bottom: 4px;
        }
    }
    & > div {
        padding: 20px;
    }
    & > header {
        display: flex;
        height: 60px;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        background-color: rgba($mid-gray, 0.3);
    }
    &__timer {
        & > span:last-child {
            font-weight: bold;
        }
        & > .bd {
            margin-right: 10px;
            cursor: pointer;
        }
        & > .bd:hover {
            border-color: $brand-blue;
        }
    }
    @media screen and (max-width: 900px) {
        max-height: calc(100vh - 120px);
        & > div,
        & > header {
            padding: 10px;
        }
        &__tracker {
            & > span {
                display: none;
            }
        }
        &__timer {
            & > span:first-of-type {
                display: none;
            }
        }
    }
}
span.center {
    text-align: center;
}
.quiz-spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    z-index: 10;
    overflow-y: auto;

    & > div {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
