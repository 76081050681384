@import "./src/scss/globals";
.question-slide {
    position: relative;
    overflow-y: auto;
    height: 400px;
    display: flex;
    flex-direction: column;

    .check-answer-container {
        @extend %center-content;
        padding: 15px 0;
        height: auto;
        position: sticky;
        bottom: 0px;
        margin-top: auto;
        background-image: linear-gradient(transparent, rgba(255, 255, 255, 0.3));

        button {
            text-align: center;
            padding: 5px 35px;
            background-color: #2fcca1;
            color: #000;
            border: 2px solid #2fcca1;
            text-decoration: none;
            border-radius: 50px;
            font-weight: bold;
        }
    }
}

@media screen and (max-width: 900px) {
    @import './QuestionMobile.slide.scss';
}

.preview-wrapper.preview-wrapper--mobile {
    @import './QuestionMobile.slide.scss';
}
