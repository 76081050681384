@import "./src/scss/globals";
.checkout-summary {
    width: 100%;
    margin-bottom: 55px;

    .heading {
        font: normal normal 600 30px/37px Poppins, sans-serif;
        color: $brand-blue;
        text-align: center;

        @include respond(phone) {
            font-size: 22px;
            line-height: 30px;
        }

        @include respond(smallest-phone) {
            font-size: 20px;
        }
    }

    .products {
        display: flex;
        flex-direction: column;
        width: 800px;
        max-width: 100%;
        margin: 0 auto;
        justify-content: space-evenly;
        margin-top: 2rem;

        @include respond(phone) {
            width: 100%;
            justify-content: center;
        }

        .product {
            width: 100%;
            font-family: 'Poppins', sans-serif;
            display: flex;
            flex-wrap: wrap;

            &:not(:last-child) {
                margin-bottom: 25px;
            }

            @include respond(phone) {
                flex-wrap: nowrap;
            }

            .chatImgWrap {
                flex: 0 0 auto;
                width: 23%;
                padding-right: 30px;
                height: 100%;

                @include respond(phone) {
                    padding-right: 0 !important;
                    padding-bottom: 15px !important;
                    margin-right: 3% !important;
                    width: 27% !important;
                }

                @include respond(tab-port) {
                    padding-right: 0;
                    padding-bottom: 8px;
                    margin-right: 10px;
                    width: 21%;
                }
            }

            .imgWrap {
                display: flex;
                justify-content: center;
                align-items: center;
                flex: 0 0 auto;
                width: 23%;
                padding-right: 30px;

                @include respond(phone) {
                    padding-right: 0 !important;
                    padding-bottom: 15px !important;
                    margin-right: 3% !important;
                    width: 27% !important;
                }

                @include respond(tab-port) {
                    padding-right: 0;
                    padding-bottom: 8px;
                    margin-right: 10px;
                    width: 21%;
                }

                img {
                    width: 150px;
                    display: block;
                    font-size: 12px;
                    border-radius: 8px;
                    height: 150px;
                    object-fit: contain;

                    @include respond(tab-port) {
                        width: 125px;
                        height: 125px;
                        background: transparent !important;
                    }

                    @include respond(large-phone) {
                        width: 115px;
                        border-radius: 15px;
                    }

                    @include respond(phone) {
                        width: 75px;
                        object-fit: contain;
                        height: 75px !important;
                        background: transparent !important;
                    }
                }

                .non-square-image {
                    object-fit: cover !important;

                    @include respond(phone) {
                        object-fit: cover !important;
                    }
                }
            }

            .course-content-width {
                width: 100% !important;
            }

            .contentWrap {
                flex: 0 0 auto;
                width: 77%;
                display: flex;
                justify-content: space-between;

                @include respond(phone) {
                    width: 70%;
                }

                .detailsWrap {
                    display: flex;
                    justify-content: space-between;
                    gap: 20px;
                }

                h3 {
                    text-align: left;
                    font-family: inherit;
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: 600;
                    color: $brand-blue;
                    margin: 0;

                    @include respond(phone) {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }

            .details {
                padding: 15px 0 0;
                overflow: hidden;
                flex-grow: 1;
                display: flex;
                justify-content: space-between;
                flex-direction: column;

                .details-row {
                    display: flex;
                    justify-content: space-between;

                    .price {
                        color: $ebony-clay;
                        font-weight: 600;

                        .old {
                            font-weight: normal;
                            text-decoration: line-through;
                            color: $red;
                            margin-right: 6px;
                        }
                    }

                    .tax-label,
                    .tax-value {
                        font-size: 14px;
                        line-height: 16px;
                        font-weight: normal;
                        color: #1d1d1d;

                        @include respond(phone) {
                            font-size: 14px;
                            line-height: 22px;
                            max-width: 167px;
                        }
                    }

                    .terms-conditions-button {
                        font-size: 12px;
                        text-transform: uppercase;
                        border: none;
                        background: #2f74ac;
                        color: $white;
                        padding: 2px 8px;
                        font-weight: 200;
                        font-family: sans-serif, Arial, Verdana, 'Trebuchet MS', 'Apple Color Emoji', 'Segoe UI Emoji',
                            'Segoe UI Symbol';
                        -webkit-font-smoothing: antialiased;
                    }
                }
            }

            .order-package-controls {
                font-size: 20px;

                & > .delete {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $mid-gray;
                    width: 36px;
                    height: 36px;
                    border-radius: 5px;
                    color: $black;
                    cursor: pointer;
                    padding: unset;
                    font: unset;
                }
            }
        }

        .pkg-terms-condition {
            display: flex;
            align-items: center;
            margin-top: 30px;

            input[type='checkbox'] {
                width: 13px;
                height: 13px;
                margin-right: 3px;
                border-radius: 0;
            }
        }

        .total-top-margin {
            margin-top: 15px !important;
        }

        .total {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            border-top: 2px solid $azure-white;
            padding-top: 30px;

            @include respond(phone) {
                align-items: flex-start;
                padding-top: 20px;
            }

            .label {
                color: #1d1d1d;
                font-size: 18px;
                font-weight: bold;
                line-height: 37px;

                .coupon-wrapper {
                    @include respond(phone) {
                        display: block;
                    }

                    .coupon-link {
                        color: $blue;
                        font-weight: normal;
                        text-decoration: underline;
                        line-height: 37px;
                        user-select: none;

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    .coupon-input {
                        display: inline-flex;

                        & > input {
                            font-size: 13px;
                            padding: 5px 8px;
                            width: 160px;
                        }

                        & > button {
                            font-size: 13px;
                            padding: 0px 18px;
                            margin-left: 10px;
                            height: 31.5px;
                        }
                    }
                }
            }

            .total-summary {
                flex: 0 0 auto;
                display: flex;
                flex-direction: column;
                gap: 12px;
                flex-basis: 250px;
                font-size: 18px;
                margin-left: auto;

                @include respond(smallest-phone) {
                    font-size: 16px;
                }

                @include respond(phone) {
                    width: 100%;
                }

                .total-summary-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .red-font {
                        color: $red;
                    }
                }

                .total-item {
                    font-size: 22px;

                    @include respond(smallest-phone) {
                        font-size: 16px;
                    }
                }
            }

            .applied-code-wrapper {
                @include respond(phone) {
                    width: 100%;
                }
            }

            .apply-coupon-wrapper {
                @include respond(phone) {
                    margin-bottom: 20px;
                    padding-bottom: 20px;
                    border-bottom: 2px solid $azure-white;
                }

                .coupon {
                    flex: 0 0 auto;
                    margin-bottom: auto;
                    display: flex;
                    align-items: center;

                    @include respond(phone) {
                        width: 100%;
                    }

                    .coupon-question {
                        margin-right: 16px;
                    }

                    .applied-coupon-wrapper {
                        background-color: $success;
                        padding: 6px 12px;
                        font-weight: bold;
                        text-transform: uppercase;

                        @include respond(phone) {
                            font-size: 12px;
                        }

                        .applied-coupon-name {
                            margin-right: 12px;
                        }

                        .applied-coupon-remove-icon {
                            background-color: unset;
                            border: unset;
                            border-radius: unset;
                            font: unset;
                            padding: unset;
                            display: unset;
                            color: unset;
                            outline: 0;
                        }
                        .applied-coupon-remove-icon:hover {
                            cursor: pointer;
                        }
                    }
                }

                .apply-code-button {
                    background-color: #2fcca1;
                    padding: 6px;
                    text-transform: uppercase;
                    border-radius: 5px;
                    font-size: 1.04rem;
                    margin-top: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: auto;
                    font-weight: inherit;

                    @include respond(phone) {
                        width: inherit;
                    }

                    &:hover {
                        background-color: #d2f5eb;
                        color: #25255e;
                    }
                }

                .button-visible {
                    visibility: visible;
                    opacity: 1;
                }

                .button-hidden {
                    visibility: hidden;
                    opacity: 0;
                    transition: opacity 0.3s ease-in-out;
                }
            }
        }

        .tax-total {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 10px;
        }
    }
}
