@import "./src/scss/globals";
.question-slide {
    .fillgap-container {
        .fillGapQuestion {
            font-weight: 400;

            &:not(:last-child) {
                margin-bottom: 10px;
            }
            line-height: 20px;

            & span {
                display: inline !important;
                line-height: 20px;
            }

            & > select {
                position: relative;
                height: 20px;
                display: inline !important;
                border: none;
                background-color: #eee;
                border-radius: 0;
                max-width: 250px;

                @media screen and (max-width: 900px) {
                    max-width: 150px;
                }

                &::after {
                    content: '';
                    position: absolute;
                    bottom: -3px;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 1px;
                    background-color: $black;
                }
            }
        }

        .fillgap-questions {
            padding: 15px 20px;
        }

        .fillgap-content {
            padding: 5px 20px;
        }

        & > header {
            & > h3 {
                -webkit-line-clamp: 2;
            }

            & + section {
                padding-top: 0;
            }
        }
    }
}
