@import "./src/scss/globals";
.audio-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    audio {
        display: none;
    }
    header {
        min-height: 150px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px 25px 20px 20px;

        @media screen and (max-width: 600px) {
            flex-direction: column;
            justify-content: center;
        }
    }
    .player {
        order: 1;
        display: flex;
        color: $brand-blue;
        margin-right: 20px;
        i {
            font-size: 22px;
            cursor: pointer;
        }

        &.white-controls {
            color: white;
        }

        .play-pause {
            font-size: 28px;
        }
    }

    .heading {
        order: 2;

        @media screen and (max-width: 600px) {
            order: 0;
            margin-bottom: 10px;
        }
    }

    .body {
        margin-left: 15px;
        display: flex;
        flex-direction: column;
    }
    .track {
        width: 210px;
        height: 8px;
        background-color: rgba($white, 0.5);
        position: relative;
        margin-bottom: 10px;
        cursor: pointer;
        & > div {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            background-color: $brand-blue;
            transition: width 0.1s ease-out;
            pointer-events: none;
        }

        .white-controls {
            background-color: white;
        }
        #tooltip-audio-indicator {
            top: -35px;
            background-color: rgb(0 0 0 / 60%);
            padding: 3px;
            border-radius: 4px;
            position: absolute;
            border-bottom-left-radius: unset;
            color: #fff;
            pointer-events: none;
            &::after {
                content: '';
                position: absolute;
                top: 72%;
                right: 78%;
                margin-top: 8px;
                border-width: 5px;
                border-style: solid;
                border-color: transparent rgb(0 0 0 / 60%) transparent transparent;
                transform: rotate(-90deg);
            }
        }
    }
    .details {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .time {
        font-size: 14px;
        transform: translateY(1px);
    }
    .audio-controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;

        .audio-forword-arrow {
            width: 24px;
            height: 24px;
            border: 2px solid;
            border-radius: 50%;
            position: relative;
            text-align: center;
            transform: rotate(-95deg);
            display: flex;
            justify-content: center;
            cursor: pointer;
            .audio-content {
                display: flex;
                justify-content: center;
                align-items: center;
                transform: rotate(95deg);
                margin: auto;
                font-size: 11px;
            }
        }
        .audio-forword-arrow:before {
            content: '';
            display: block;
            width: 3px;
            height: 50px;
            background: #fff;
            position: absolute;
            bottom: -45px;
            top: 0;
            right: -3px;
            margin: auto;
        }
        .audio-forword-arrow:after {
            content: '';
            width: 0;
            height: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 4px solid #000;
            position: absolute;
            bottom: 4px;
            right: -3px;
            transform: rotate(180deg);
        }

        .audio-backword-arrow {
            width: 24px;
            height: 24px;
            border: 2px solid;
            border-radius: 50%;
            position: relative;
            text-align: center;
            transform: rotate(-95deg);
            display: flex;
            justify-content: center;
            cursor: pointer;
            .audio-content {
                display: flex;
                justify-content: center;
                align-items: center;
                transform: rotate(95deg);
                margin: auto;
                font-size: 11px;
            }
        }
        .audio-backword-arrow:before {
            content: '';
            display: block;
            width: 3px;
            height: 50px;
            background: #fff;
            position: absolute;
            bottom: -45px;
            top: 0;
            right: -3px;
            margin: auto;
        }
        .audio-backword-arrow:after {
            content: '';
            width: 0;
            height: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 4px solid #000;
            position: absolute;
            bottom: 10px;
            right: -5px;
        }
    }

    .volume {
        display: flex;
        align-items: center;
        cursor: pointer;
        & > div {
            width: 80px;
            height: 4px;
            background-color: rgba($white, 0.5);
            position: relative;
            margin-left: 4px;
            & > div {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                background-color: $brand-blue;
                transition: width 0.1s ease-out;
                pointer-events: none;
            }
        }

        .white-controls {
            background-color: white;
        }
    }
    .heading {
        text-align: left;
        margin-left: auto;

        @media screen and (max-width: 600px) {
            text-align: center;
            margin: 0 0 20px 0;
        }

        h1 {
            font-weight: bold;
            font-size: 24px;
            @media screen and (max-width: 500px) {
                font-size: 18px;
            }
            @media (min-width: 501px) and (max-width: 900px) {
                font-size: 21px;
            }
        }
        h2 {
            font-size: 20px;
            @media screen and (max-width: 500px) {
                font-size: 16px;
            }
            @media (min-width: 501px) and (max-width: 900px) {
                font-size: 17px;
            }
        }
    }
    .transcript {
        margin: 20px;
        padding: 15px;
        background-color: #f9f9f9;
        flex: 1;
        overflow-y: auto;
        max-height: 500px;
    }
    .content {
        padding: 20px;
    }
}
