@import "./src/scss/globals";
.payment-content-wrapper {
    background: $white;
    border-radius: 15px;
    padding: 20px 30px 20px;
    margin: 0px 15px 0px;
    box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;

    @media screen and (max-width: 1024px) {
        margin: 0;
    }

    @media screen and (max-width: 320px) {
        padding: 0 10px;
    }

    .content-wrapper {
        flex-direction: column;

        .zero-purchase-wrapper {
            font-weight: bold;
            padding: 0 15px;
            font-size: 1.1rem;
            color: #25255e;
            margin-bottom: 1.5rem;
        }

        .payment-option-wrapper {
            gap: 10px;
            display: flex;
            flex-direction: column;
            margin-bottom: 1.4rem;

            @media screen and (max-width: 320px) {
                padding: 0;
            }

            .toggle-option {
                display: flex;
                flex-direction: row;
                align-items: center;

                .klarna-card-label {
                    width: 80%;

                    @media screen and (max-width: 500px) {
                        width: 90%;
                    }

                    @media (min-width: 600px) and (max-width: 1024px) {
                        width: 60%;
                    }

                    @media (min-width: 1025px) and (max-width: 1160px) {
                        width: 100%;
                    }
                }

                .cards-logos {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 10px;

                    img {
                        height: 30px;
                        border: 1px solid $mid-gray;
                        padding: 4px;
                        border-radius: 5px;
                        margin-right: 5px;
                    }

                    span {
                        margin: 0;
                        font-size: 0.7rem;
                        font-style: italic;
                    }
                }
            }
        }

        .payment-calculation-wrapper {
            margin-bottom: 1.2rem;
            font-size: 1.1rem;
        }

        .agreement-wrapper {
            width: 30rem;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 2rem;

            @media screen and (max-width: 500px) {
                width: 25rem;
                margin-left: unset;
            }

            @include respond(phone) {
                max-width: 100%;
                text-align: left;
            }

            .lblTerms_Condition {
                display: inline-block;
                width: 100%;
                line-height: 1.88;
                font-size: 0.9rem;
                position: relative;
                padding-left: 25px;

                input[type='checkbox'] {
                    position: absolute;
                    left: 0;
                    top: 4px;
                }

                @media (min-width: 400px) and (max-width: 860px) {
                    line-height: 2.5;
                }

                button {
                    width: auto;
                    display: inline-block;
                    border: none;
                    background: transparent;
                    text-decoration: underline;
                    font-size: 0.9rem;
                    color: #121212;
                    padding: 0;
                    padding-left: 4px;
                    font-weight: 200;
                    font-family: sans-serif, Arial, Verdana, 'Trebuchet MS', 'Apple Color Emoji', 'Segoe UI Emoji',
                        'Segoe UI Symbol';
                    -webkit-font-smoothing: antialiased;

                    @media screen and (max-width: 500px) {
                        width: unset;
                    }
                }

                .terms-condition-wrapper {
                    width: 100%;
                    margin-left: 0;

                    @media screen and (max-width: 500px) {
                        width: 25rem;
                    }
                }

                .required-terms {
                    color: $red;
                }
            }

            .lblTerms_Condition_pkg {
                display: flex;
                width: 100%;
                align-items: flex-start;
                justify-content: flex-start;
                line-height: 1.88;
                font-size: 0.9rem;

                button {
                    border: none;
                    background: transparent;
                    text-decoration: underline;
                    font-size: 0.9rem;
                    color: #121212;
                    padding: 0;
                    display: flex;
                    align-items: flex-start;
                    text-align: start;
                    font-weight: 200;
                    font-family: sans-serif, Arial, Verdana, 'Trebuchet MS', 'Apple Color Emoji', 'Segoe UI Emoji',
                        'Segoe UI Symbol';
                    -webkit-font-smoothing: antialiased;

                    @media screen and (max-width: 500px) {
                        width: unset;
                    }
                }

                .checkbox-wrapper {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;

                    input[type='checkbox'] {
                        margin-top: 5px;
                    }
                }

                .terms-condition-wrapper {
                    span {
                        width: 100%;
                        margin-left: 5px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        margin-top: 2px;

                        @include respond(smallest-phone) {
                            -webkit-line-clamp: 3;
                        }

                        @include respond(phone) {
                            width: auto;
                            -webkit-line-clamp: initial;
                        }
                    }
                }
            }
        }

        .payment-button-wrapper {
            margin-bottom: 1.2rem;

            button {
                width: 100%;
                text-align: center;
                align-items: center;
                justify-content: center;
            }

            .stripe-button,
            .complete-purchase-button {
                text-decoration: none !important;
                display: flex;

                button {
                    width: 100%;
                    justify-content: center;

                    @include respond(phone) {
                        max-width: 260px;
                        margin: auto;
                    }

                    @include respond(tab-port) {
                        max-width: 360px;
                        margin: auto;
                    }

                    @media (min-width: 800px) and (max-width: 1060px) {
                        max-width: 500px;
                        margin: auto;
                    }
                }
            }

            .paypal-button {
                width: 100%;
                display: block;
                text-align: center;
                font-size: 14px;
                height: 55px;
                margin: auto;

                @include respond(phone) {
                    max-width: 260px;
                }

                @include respond(tab-port) {
                    max-width: 360px;
                }

                @media (min-width: 800px) and (max-width: 1060px) {
                    max-width: 500px;
                }
            }

            .disabled {
                pointer-events: none;
            }
        }

        .payment-info-wrapper {
            align-items: center;
            justify-content: center;
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid $mid-gray;

            .content-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                flex-wrap: wrap;
                gap: 0.5rem;

                p {
                    margin: 0 0.5rem 0 0;
                }
            }

            .stripe-text-wrapper {
                padding: 0.5rem 1rem;
                border: 1px solid $black;
                border-radius: 0.2rem;
                margin: 1.5rem 0;
            }
        }

        .apply-discount-wrapper {
            @media (min-width: 800px) and (max-width: 1060px) {
                width: 55%;
                margin: auto;
            }

            @media (min-width: 1025px) and (max-width: 1160px) {
                width: 100%;
                margin: auto;
            }

            @media (min-width: 600px) and (max-width: 800px) {
                width: 65%;
                margin: auto;
            }

            .apply-coupon-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;

                @media screen and (max-width: 500px) {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 5px;
                }

                @media (min-width: 1025px) and (max-width: 1200px) {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 5px;
                }

                .code-title-wrapper {
                    i {
                        transform: rotate(320deg);
                    }
                }

                .coupon {
                    flex: 0 0 auto;
                    margin-bottom: auto;
                    display: flex;
                    align-items: center;
                    width: 60%;

                    @media (min-width: 1025px) and (max-width: 1200px) {
                        width: 100%;
                    }

                    &--inside-icon {
                        position: relative;

                        & > input {
                            padding-right: 2.5rem;
                        }

                        & > .btn {
                            position: absolute;
                            top: 0.3rem;
                            right: 0.3rem;
                            margin: 0;
                            border-radius: 8px;
                            align-items: center;
                            text-align: center;
                            justify-content: center;
                            display: flex;
                            font-size: 1rem;
                            color: $white;
                            padding: 3px;

                            @include respond(phone) {
                                width: 35%;
                                top: unset;
                            }
                        }
                    }

                    @media screen and (max-width: 500px) {
                        width: 100%;
                    }

                    .coupon-question {
                        margin-right: 16px;
                    }
                }

                .applied-coupon-wrapper {
                    background-color: $success;
                    padding: 6px 12px;
                    font-weight: bold;
                    text-transform: uppercase;

                    @include respond(phone) {
                        font-size: 12px;
                    }

                    .applied-coupon-name {
                        margin-right: 12px;
                    }

                    .applied-coupon-remove-icon {
                        background-color: unset;
                        border: unset;
                        border-radius: unset;
                        font: unset;
                        padding: unset;
                        display: unset;
                        color: unset;
                        outline: 0;
                    }
                    .applied-coupon-remove-icon:hover {
                        cursor: pointer;
                    }
                }

                .apply-code-button {
                    background-color: #2fcca1;
                    padding: 6px;
                    text-transform: uppercase;
                    border-radius: 5px;
                    font-size: 1.04rem;
                    margin-top: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: auto;
                    font-weight: inherit;

                    @include respond(phone) {
                        width: inherit;
                    }

                    &:hover {
                        background-color: #d2f5eb;
                        color: #25255e;
                    }
                }

                .button-visible {
                    visibility: visible;
                    opacity: 1;
                }

                .button-hidden {
                    visibility: hidden;
                    opacity: 0;
                    transition: opacity 0.3s ease-in-out;
                }
            }
        }
    }
}
