@import "./src/scss/globals";
#user-bar-chat-menu {
    border: unset;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    margin-top: 0.5rem !important;
    max-width: 30rem !important;

    position: fixed;
    inset: 0px 0px auto auto;
    transform: translate(-23.5%, 10%);

    @media screen and (max-width: 900px) {
        transform: translate(-31%, 8%);
    }

    @media screen and (max-width: 650px) {
        transform: translate(-25%, 8%);
    }

    @media screen and (max-width: 600px) {
        transform: translate(-22%, 8%);
        max-width: 29rem !important;
    }
    @media screen and (max-width: 550px) {
        transform: translate(-19%, 8%);
        max-width: 27rem !important;
    }

    @media screen and (max-width: 500px) {
        transform: translate(-16%, 8%);
        max-width: 26rem !important;
    }

    @media screen and (max-width: 450px) {
        transform: translate(-6%, 8%);
        max-width: 26rem !important;
    }

    @media screen and (max-width: 400px) {
        transform: translate(-6%, 8%);
        max-width: 24rem !important;
    }

    @media screen and (max-width: 375px) {
        transform: translate(-8%, 8%);
        max-width: 23rem !important;
    }

    @media screen and (max-width: 320px) {
        transform: translate(-2%, 8%);
        max-width: 22rem !important;
    }

    .chat-close-button {
        position: absolute;
        right: 0.5rem;
        padding: 0.25rem;
        background: transparent;
        border: none;
        color: #6c757d;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;

        &:hover {
            background-color: rgba(108, 117, 125, 0.1);
            color: #495057;
        }

        &:focus {
            box-shadow: none;
            outline: none;
        }

        &:active {
            background-color: rgba(108, 117, 125, 0.2);
        }
    }

    .popover-arrow {
        right: 1.2rem !important;
        position: absolute;
        display: block;
        width: 1rem;
        height: 0.5rem;
        margin: 0 0.3rem;

        @media screen and (max-width: 650px) {
            right: 10% !important;
        }

        @media screen and (max-width: 600px) {
            right: 14% !important;
        }

        @media screen and (max-width: 550px) {
            right: 20% !important;
        }

        @media screen and (max-width: 500px) {
            right: 25% !important;
        }

        @media screen and (max-width: 450px) {
            right: 35% !important;
        }

        @media screen and (max-width: 375px) {
            right: 37% !important;
        }

        @media screen and (max-width: 320px) {
            right: 45% !important;
        }
    }

    .popover-body {
        padding: 0.5rem 1.3rem !important;

        .chat-pane-container {
            display: flex;
            flex-direction: column;
            position: relative;
            z-index: 1;
            background-color: $white;
            height: calc(100vh - 15rem);

            @media screen and (min-width: 767px) and (max-width: 1024px) {
                max-height: 50rem !important;
            }

            .chat-message-container {
                flex-grow: 7;
                overflow: auto;
                margin-top: 1rem;
                width: 30rem;

                .spinner {
                    display: flex;
                    justify-content: center;
                }

                .infinite-scroll-student {
                    overflow: unset !important;
                }

                .blank-message-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;

                    p {
                        text-align: center;
                    }
                }

                .message-list-wrapper {
                    .grouped-key {
                        text-align: center;
                        margin-top: 1rem;
                        font-family: 'Open Sans', sans-serif;
                        font-size: 0.725rem;
                    }

                    .message-item-container {
                        margin: 0;
                        justify-content: center;

                        .message-container {
                            max-width: 30rem !important;

                            @media screen and (max-width: 450px) {
                                max-width: 80% !important;
                            }
                        }

                        .receiver {
                            .profile-photo {
                                @media screen and (max-width: 450px) {
                                    margin-left: -3vw !important;
                                }
                            }
                        }
                    }
                }
            }

            .message-field {
                flex-grow: unset;
                padding: 0.8rem;
                border-top: 1px solid $mid-gray;

                &-wrapper {
                    justify-content: flex-end;
                    align-items: flex-end;

                    .input-wrapper {
                        max-width: 87%;
                        flex: 0 0 87%;
                        padding-right: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        @include respond(phone) {
                            max-width: 85%;
                            flex: 0 0 85%;
                        }

                        @include respond(smaller-phone) {
                            max-width: 84%;
                            flex: 0 0 84%;
                        }

                        .instructor-message {
                            border-color: $mid-gray;
                        }
                    }

                    .button-wrapper {
                        display: flex;
                        align-items: center;
                        padding: 0;

                        .message-submit-button {
                            width: 2.8rem;
                            border: unset;
                            background-color: $white;
                            border-radius: 0.3rem;
                            padding: 5px;
                            color: $mid-dark-gray;
                            font-size: 1.4rem;
                            margin-left: 0.5rem;

                            &:focus,
                            &:active {
                                background-color: #2fcca1;
                                box-shadow: unset !important;
                                outline: 0;
                            }

                            &:hover {
                                background-color: #d2f5eb;
                                color: #25255e;
                            }
                        }
                    }
                }
            }
        }
    }
}
