@import "./src/scss/globals";
$primary: $brand-blue;
$secondary: $brand-cyan;
$info: $mid-gray;

@import 'node_modules/bootstrap/scss/bootstrap';

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background-color: $very-light-gray;
}

::-webkit-scrollbar-corner,
::-webkit-resizer {
    background: $very-light-gray;
}

::-webkit-scrollbar-thumb {
    background-color: $light-gray;
}

html {
    font-size: 16px;
}

button {
    color: $black;
}

.bd {
    text-align: center;
    display: inline-block;
    padding: 5px 15px;
    background-color: #dadada;
    color: #000;
    border: solid 2px #dadada;
    text-decoration: none;
    border-radius: 50px;
    font-weight: bold;
}

.bd:hover {
    background-color: #fff;
}

.bp {
    text-align: center;
    display: inline-block;
    padding: 5px 15px;
    background-color: #2fcca1;
    color: #000;
    border: 2px solid #2fcca1;
    text-decoration: none;
    border-radius: 50px;
    font-weight: bold;
}

.bp:hover {
    background-color: #fff;
}

.btn-cancel {
    position: absolute;
    transform: translateX(-65px);
    background-color: $brand-red;
    border: $brand-red 2px solid;

    &:hover {
        background-color: #fff;
    }
}

.bf {
    display: block;
    width: 100%;
}

body {
    --ck-border-radius: 0.25rem;
    --ck-color-toolbar-border: #{$black};
    --ck-color-base-border: #{$black};
    --ck-focus-ring: 1px solid #{$brand-blue};

    font-family: 'Open Sans', sans-serif;
    overflow-x: hidden;

    a {
        outline-color: $brand-blue;
        color: $black;
        transition: color 0.25s ease-out;
        text-decoration: none;

        &:hover {
            color: $brand-blue;
        }
    }

    input[type='text'],
    input[type='email'],
    input[type='number'],
    input[type='password'],
    input[type='tel'],
    textarea {
        padding: 0.5rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: 0.25rem;
        border: 1px solid $black;
        width: 100%;

        &[disabled] {
            position: relative;
            opacity: 0.5;
        }
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-repeat: no-repeat, repeat;
        background-position:
            right 0.7em top 50%,
            0 0;
        background-size:
            0.65em auto,
            100%;
        padding: 0.5rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: 0.25rem;
        border: 1px solid $black;
        background-color: $white;
        width: 100%;

        &:not([multiple]) {
            background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
        }
    }

    & > div[class^='css-'] {
        z-index: 999;
    }

    .btn {
        text-align: center;
        display: inline-block;
        padding: 5px 15px;
        background-color: #dadada;
        color: #000;
        border: solid 2px #dadada;
        text-decoration: none;
        border-radius: 50px;
        font-weight: bold;

        &:hover {
            background-color: #fff;
            color: #000;
            border: solid 2px #dadada;
        }

        &--small {
            font-size: 0.75rem;
            padding: 0.25rem 0.45rem;
        }

        &.btn--primary {
            background-color: $brand-cyan;
            font-weight: bold;
            color: $white;

            &:hover {
                background-color: lighten($brand-cyan, 10%);
                color: $white;
            }
        }

        &.btn--danger {
            background-color: $brand-red;
            font-weight: bold;
            color: $black;
            border: none;

            &:hover {
                border-color: $brand-red;
                background-color: lighten($brand-red, 17%);
            }
        }

        &.btn--danger-secondary {
            background-color: $red;
            font-weight: bold;
            color: $black;
            border: none;

            &:hover {
                border-color: $brand-red;
                background-color: lighten($brand-red, 17%);
            }
        }

        &.btn--light {
            background-color: lighten($gray, 30%);
            font-weight: bold;
            color: $white;

            &:hover {
                background-color: lighten($gray, 20%);
                color: $white;
            }
        }

        &.btn--disabled,
        &:disabled {
            border: 1px solid $mid-gray !important;
            background-color: $mid-gray !important;
            color: $mid-dark-gray !important;
            cursor: not-allowed !important;

            &:hover,
            &:active,
            &:focus {
                background-color: $mid-gray;
                outline: 0;
                color: unset;
                box-shadow: none !important;
            }
        }

        &.btn--link {
            color: $brand-blue;
            transition: border-color 0.25s;
            background-color: transparent;

            &:hover {
                border-color: $brand-blue;
            }
        }

        &.bd {
            text-align: center;
            display: inline-block;
            padding: 5px 15px;
            background-color: #dadada;
            color: #000;
            border: solid 2px #dadada;
            text-decoration: none;
            border-radius: 50px;
            font-weight: bold;
        }

        &.bd:hover {
            background-color: #fff;
        }

        &.bp {
            text-align: center;
            display: inline-block;
            padding: 5px 15px;
            background-color: #2fcca1;
            color: #000;
            border: 2px solid #2fcca1;
            text-decoration: none;
            border-radius: 50px;
            font-weight: bold;
        }

        &.bp:hover {
            background-color: #fff;
        }

        &.bf {
            display: block;
            width: 100%;
        }
    }

    a.side-menu__item--active {
        background-color: rgba($brand-blue, 0.7);
        color: $white;
        transition:
            background-color 0.2s ease-out,
            width 0.25s;

        &:hover {
            background-color: rgba($brand-blue, 1);
            color: $white;
        }
    }

    .nav-pills a.nav-link.active {
        color: white;
    }

    .centered-block {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        padding-top: 10rem;
        padding-bottom: 200px;

        &.loading {
            color: $brand-cyan;
        }
    }

    .editor-block {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-top: 10px;
        padding-bottom: 10px;

        &.loading {
            color: $brand-cyan;
        }
    }

    .form {
        &.form--narrow {
            width: 26rem;
            max-width: 450px;
        }

        &.form--floating {
            box-shadow: 0 0 12px rgba($gray, 0.3);
            border-radius: 0.25rem;

            .form__content {
                padding: 2rem 1.75rem;
            }
        }

        &.form--two-cols {
            .row {
                max-width: calc(340px * 2 + 4rem);

                .col {
                    &:not(:last-child) {
                        margin-right: 4rem;
                    }
                }

                & > .form__field {
                    width: 100%;
                    padding: 0 15px;
                }
            }
        }

        &.form--with-error {
            .form__content {
                padding-top: 1rem;
            }
        }

        .form__content {
            padding: 2rem 0;

            & > h2:first-child {
                margin-top: 0;
            }

            & > form > .row {
                &:not(:last-child) {
                    margin-bottom: 0.75rem;
                }
            }
        }

        .form__field {
            display: flex;
            flex-direction: column;

            &.form__field--inside-icon {
                position: relative;

                & > input {
                    padding-right: 2.5rem;
                }

                & > .btn {
                    position: absolute;
                    top: 0.3rem;
                    right: 0.3rem;
                }
            }

            &:not(:first-child) {
                margin-top: 1.3rem;
            }

            label {
                margin-bottom: 0.5rem;
                font-weight: bold;
            }

            .form_error {
                input {
                    border: 1px solid #ff4343 !important;
                    color: #ff4343;
                }
            }
        }

        .form__message {
            margin: 0.75rem 0 1.5rem 0;
        }

        .form__error {
            background-color: rgba($red, 0.2);
            border-bottom: 1px solid rgba($red, 0.3);
            color: darken($red, 35%);
            font-size: 1.05rem;
            line-height: 1.5;
            padding: 0.6rem 0.75rem;
            border-radius: 0.25rem 0.25rem 0 0;
        }

        .form_field_error {
            position: 'relative';
            padding-bottom: '5px';
        }

        .login-form__error {
            color: $red;
            font-size: small;
        }

        .alert_icon {
            position: absolute;
            top: auto;
            left: auto;
            right: 15px;
            bottom: 15px;
            color: $red;
        }

        .form__buttons {
            margin-top: 1.3rem;

            .btn {
                &:not(:first-child) {
                    margin-left: 1rem;
                }
            }
        }
    }

    .global-alert {
        position: fixed;
        top: 4rem;
        left: 50%;
        opacity: 0;
        transform: translateX(-50%) translateY(-0.5rem);
        padding: 0.75rem 2rem;
        background-color: $white;
        border-radius: 3rem;
        color: $brand-blue;
        z-index: 99997;
        box-shadow: 0 0 10px rgba($black, 0.2);
        transition: all 0.5s ease-in-out;
        pointer-events: none;
        overflow: hidden;
        font-weight: bold;

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 52px;
            height: 100%;
        }

        &.global-alert--visible {
            opacity: 1;
            transform: translateX(-50%) translateY(0);
            pointer-events: auto;
        }

        &.global-alert--success {
            padding-left: 80px;

            &::after {
                background-color: lighten($brand-cyan, 20);
            }

            & > svg {
                color: darken($brand-cyan, 15);
            }
        }

        &.global-alert--error {
            padding-left: 80px;

            &::after {
                background-color: lighten($brand-red, 20);
            }

            & > svg {
                color: darken($brand-red, 15);
            }
        }

        & > svg {
            position: absolute;
            top: 11px;
            left: 14px;
            font-size: 26px;
            z-index: 2;
        }
    }

    .color--light {
        color: $light-gray;
    }

    .text--center {
        text-align: center;
    }

    .font--small {
        font-size: 0.75rem;
    }

    .font--medium {
        font-size: 1.25rem;
    }

    .font--big {
        font-size: 1.5rem;
    }

    .font--huge {
        font-size: 2rem;
    }

    .padding {
        padding: 1rem;
    }

    .padding--double {
        padding: 2rem;
    }

    .padding--half {
        padding: 0.5rem;
    }

    .block-title {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 1.25em;
    }

    .items-spacing-right {
        display: flex;

        &:not(:last-child) {
            margin-right: 1rem;
        }

        &.btn--right {
            margin-left: auto;
        }
    }

    .center-loading {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        color: $brand-blue;

        .spinner-border {
            width: 2em;
            height: 2em;
        }

        &.big {
            font-size: 26px;
        }
    }

    .modal {
        top: 44px;
        z-index: 99990;
        background-color: rgba(255, 255, 255, 0.8);
        overflow-y: hidden;

        .modal-dialog {
            display: flex;
            align-items: center;
            height: calc(100vh - 54px);
            margin: 0 auto;
            padding: 30px 10px;

            @media screen and (min-width: 1366px) {
                max-width: calc(100vw - 120px);
            }

            .modal-content {
                border-radius: 0;

                .modal-body {
                    max-height: calc(100vh - 324px);
                    overflow: auto;
                }
            }
        }

        &.fullHeight-Scroll {
            top: 0;

            .modal-dialog {
                height: 100vh;
                padding: 30px 10px;

                .modal-content {
                    height: 100%;

                    .modal-body {
                        max-height: initial;
                    }
                }
            }
        }
    }

    .modal-backdrop {
        backdrop-filter: blur(3px);
    }

    .CalendarMonth_caption {
        select {
            border: 1px solid rgba($light-gray, 0.2);
            border-radius: 3px;
            font-size: 0.75rem;
            margin: 0 2px;
            color: rgba($gray, 0.8);
            padding: 7px 10px;
            transform: translateY(-4px);
        }
    }
}

.tooltip,
.popover {
    z-index: 99998;
}

.tooltip {
    position: fixed !important;
}

.tooltip.abs-tooltip {
    position: absolute !important;
}

.ck-content a {
    color: blue;
    text-decoration: underline;
}

.flex-grid {
    display: flex;
}

.flex-item-1 {
    flex: 1;
}

.flex-item-2 {
    flex: 2;
}

.flex-item-3 {
    flex: 3;
}

.message-alert {
    display: block;
    padding: 10px 15px;
    margin-bottom: 15px;
    box-shadow: inset 0 0px 0px 1px lighten($light-gray, 20);
    background-color: lighten($light-gray, 40);
    color: darken($light-gray, 20);
    border-radius: 10px;
    overflow: hidden;

    & > svg {
        &:first-child {
            margin-right: 15px;
        }
    }

    &.narrow {
        border-radius: 5px;
        padding: 5px 10px;
        font-size: 0.75rem;
        margin-bottom: 10px;
    }

    &.positive {
        box-shadow: inset 0 0px 0px 1px lighten($brand-cyan, 20);
        background-color: lighten($brand-cyan, 40);
        color: darken($brand-cyan, 30);

        &.narrow {
            border-bottom: 2px solid rgba(darken($brand-cyan, 30), 0.1);
        }
    }

    &.warning {
        box-shadow: inset 0 0px 0px 1px lighten($warning, 10);
        background-color: lighten($warning, 25);
        color: darken($warning, 30);

        &.narrow {
            border-bottom: 2px solid rgba(darken($warning, 30), 0.1);
        }
    }
}

.oneline-text {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.twolines-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    width: 100%;
}

.no-scroll {
    overflow-y: hidden !important;
}

@media screen and (max-width: 900px) {
    html {
        font-size: 14px;
    }
}

body {
    .SingleDatePickerInput {
        &__showClearDate {
            padding-right: 0;
        }

        &_clearDate__default {
            padding: 2px 5px;
        }
    }
}

.login_form {
    .field__wrapper {
        position: relative;
        padding-bottom: 5px;
    }

    .field__error {
        border: 2px solid $red;
    }
}

#thought_survey {
    width: auto;
    min-width: 30%;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
}

#tm_question {
    font-size: 25px !important;
    color: #25255e;
}

#tm_thank {
    font-size: 25px !important;
    color: #25255e;
}

.tm_response {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    border: 1px solid black;
    padding-left: 20px;
    margin: 5px 0px;
    font-weight: bold;
}

#tm_submit {
    padding: 7px !important;
    display: flex;
    margin: auto;
    width: 150px;
    justify-content: center;
    background-color: #2fcca1;
    border-radius: 50px !important;
}

.form-field-space-bottom {
    margin-bottom: 1rem;
}

.form-check-input {
    border-color: $black;
}
