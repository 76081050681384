@import "./src/scss/globals";
.menu-bar {
    padding: 0.625rem;
    min-width: 22.25rem;
    .course-title {
        height: 3.125rem;
        margin: 0 0.625rem 0 0;
    }
    .course-progess {
        display: flex;
        height: 5.5rem;
        min-width: 22rem;
        align-items: center;
        .progress-bar-skeleton {
            width: 3.75rem;
            height: 3.75rem;
            margin: 0 1rem 0 0.25rem;
            align-items: center;
        }
        .course-details-skeleton {
            height: 4.5rem;
            min-width: 16rem;
        }
    }
    .chapter-title {
        height: 3.75rem;
        margin: 0 0.625rem 0 0;
    }
}
