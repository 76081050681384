@import "./src/scss/globals";
.alerts-container,
.toasts-container,
.announcemnent-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 100000;
    pointer-events: none;
    display: flex;
    flex-direction: column;
}
.alerts-container {
    align-items: center;
}
.toasts-container {
    align-items: flex-end;
    > .popup-toast {
        &:first-child {
            margin-top: 120px;
        }
    }
}
.announcemnent-container {
    align-items: flex-end;
}
