.question-slide {
    & > header {
        & > h1 {
            font-size: 24px;
        }

        & > h3 {
            font-size: 20px;
        }
    }
    .switch-choice{
        .question-block {
            flex-direction: column;
        }
    
        .options {
            margin: 10px 10px 10px 10px;
            width: 280px;
        }
    }
    
}
