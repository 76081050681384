@import "./src/scss/globals";
.exam-info {
    padding-top: 74px;
    .exam-title {
        position: fixed;
        z-index: 100;
        top: $header-height;
        padding: 1rem;
        width: 100%;
        background-color: $white;
    }
}
