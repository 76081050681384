.question-slide {
    > header {
        h1 {
            font-size: 18px !important;
        }

        h3 {
            font-size: 16px !important;
        }
    }
    .single-question {
        .questions {
            .question {
                &.image-question {
                    .option {
                        width: 40%;
                        padding-left: 4px;
                        padding-right: 4px;
                    }
                }
            }
        }
    }
}
