@import "./src/scss/globals";
.navigation-progress {
    display: grid;
    grid-template-columns: 84px calc(100% - 84px);
    background-color: lighten($blue, 54.5%);
    padding: 15px 0;
    font-size: 14px;
    align-items: center;

    & > svg {
        width: 54px;
        height: 54px;
        margin: 0 auto;
        transition: width 0.15s ease-out, height 0.15s ease-out;

        .CircularProgressbar {
            &-trail {
                stroke: lighten($brand-cyan, 33.2%);
            }

            &-path {
                stroke: $brand-blue;
            }

            &-text {
                font-size: 1.6rem;
                font-weight: bold;
                fill: $brand-blue;
                dominant-baseline: middle;
                text-anchor: middle;
            }
        }
    }

    .action-course {
        display: flex;
        margin-top: 5px;
        height: 30px;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: 350px) {
            align-items: flex-start;
            flex-direction: column;
            height: 5.3rem;
        }
    }

    .course-status {
        text-align: center;
        border-radius: 5px;
        width: fit-content;
        padding: 5px 8px;
        font-size: 15px;
        font-weight: bold;
        background-color: $warning;
    }
    .extend-button {
        align-items: center;
        padding: 5px 10px;
        height: 35px;
        font-size: 15px;
        justify-content: center;
        text-align: center;
        background-color: #2fcca1;
    }
    .extend-btn-space {
        margin-right: auto;
        margin-left: 8px;
    }
}

.exam-proctor-progress {
    padding: 15px;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    span {
        margin-bottom: 5px;
    }
}