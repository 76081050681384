@import "./src/scss/globals";
.button-list {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 5px;
    top: 5px;
    // max-width: 30px;
    max-width: 100%;
    min-width: 10%;
    z-index: 100;
}