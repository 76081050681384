@import "./src/scss/globals";
.stripe-checkout-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    -webkit-overflow-scrolling: touch;

    .stripe-checkout-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        max-width: 1266px;
        background-color: $light-blue;
        border-radius: 15px;
        padding: 60px 90px;

        @media screen and (min-width: 501px) and (max-height: 900px) {
            padding: 30px 45px 30px;
        }

        @media screen and (max-width: 900px) {
            padding: 60px 45px;
        }

        @media screen and (max-width: 750px) {
            padding: 40px 10px;
            width: 95%;
            margin-bottom: 16px;
        }

        @include respond(smallest-phone) {
            padding: 30px 10px;
        }

        .stripe-total-container {
            font-size: 0.93rem !important;
            width: 300px;
            margin: 10px 0;
            display: flex;
            justify-content: space-between;
            font-weight: bold;

            sup {
                font-weight: normal;
            }
        }

        .return-stripe-back-button {
            background: unset !important;
            color: #0052b2 !important;
            font-size: 0.93rem !important;
            padding: 0 !important;
            margin-top: 1rem !important;
        }

        button {
            @include checkout-main-button;
        }

        .StripeElement {
            margin-bottom: 1rem;
        }
    }


    .product-title {
        h4 {
            margin-top: 0.5rem;
        }
    }
}