ul,
ol {
    list-style-position: inside;
}

.text-tiny {
    font-size: 0.7em;
}

.text-small {
    font-size: 0.85em;
}

.text-big {
    font-size: 1.4em;
}

.text-huge {
    font-size: 1.8em;
}

a {
    color: blue;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

.image {
    display: table;
    clear: both;
    text-align: center;
    margin: 0.9em auto;
    min-width: 50px;
}

.image img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    min-width: 100%;
}

.image-inline {
    display: inline-flex;
    max-width: 100%;
    align-items: flex-start;
}

.image-inline img {
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 100%;
}

.image > figcaption {
    display: table-caption;
    caption-side: bottom;
    word-break: break-word;
    color: var(--ck-color-image-caption-text);
    background-color: var(--ck-color-image-caption-background);
    padding: 0.6em;
    font-size: 0.75em;
    outline-offset: -1px;
}

.image.image_resized {
    max-width: 100%;
    display: block;
    box-sizing: border-box;
}

.image.image_resized img {
    width: 100%;
}

.image.image_resized > figcaption {
    display: block;
}

.image-style-block-align-left,
.image-style-block-align-right {
    max-width: calc(100% - var(--ck-image-style-spacing));
}

.image-style-align-left,
.image-style-align-right {
    clear: none;
}

.image-style-side {
    float: right;
    margin-left: var(--ck-image-style-spacing);
    max-width: 50%;
}

.image-style-align-left {
    float: left;
    margin-right: var(--ck-image-style-spacing);
}

.image-style-align-center {
    margin-left: auto;
    margin-right: auto;
}

.image-style-align-right {
    float: right;
    margin-left: var(--ck-image-style-spacing);
}

.image-style-block-align-right {
    margin-right: 0;
    margin-left: auto;
}

.image-style-block-align-left {
    margin-left: 0;
    margin-right: auto;
}

p + .image-style-align-left,
p + .image-style-align-right,
p + .image-style-side {
    margin-top: 0;
}

.image-inline.image-style-align-left,
.image-inline.image-style-align-right {
    margin-top: var(--ck-inline-image-style-spacing);
    margin-bottom: var(--ck-inline-image-style-spacing);
}

.image-inline.image-style-align-left {
    margin-right: var(--ck-inline-image-style-spacing);
}

.image-inline.image-style-align-right {
    margin-left: var(--ck-inline-image-style-spacing);
}

hr {
    margin: 15px 0;
    height: 4px;
    background: hsl(0, 0%, 87%);
    border: 0;
}

.table {
    margin: 0.9em auto;
    display: table;
}

.table table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    height: 100%;
    border: 1px double hsl(0, 0%, 70%);
}

.table table td,
.table table th {
    min-width: 2em;
    padding: 0.4em;
    border: 1px solid hsl(0, 0%, 75%);
}

.table table th {
    font-weight: bold;
    background: hsla(0, 0%, 0%, 5%);
}

.ck-content[dir='rtl'] .table th {
    text-align: right;
}

.ck-content[dir='ltr'] .table th {
    text-align: left;
}

.table > figcaption {
    display: table-caption;
    caption-side: top;
    word-break: break-word;
    text-align: center;
    color: var(--ck-color-table-caption-text);
    background-color: var(--ck-color-table-caption-background);
    padding: 0.6em;
    font-size: 0.75em;
    outline-offset: -1px;
}
