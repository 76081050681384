@import "./src/scss/globals";
.single-page-checkout-container {
    width: 90%;
    justify-content: center;
    display: flex;
    margin: 0 auto;
    height: 100%;
    font-family: 'Segoe UI', sans-serif;
    background-color: $cyan;
    position: relative;
    z-index: 1;

    @media screen and (max-width: 1024px) {
        flex-direction: column-reverse;
        margin: 0;
        justify-content: center;
        width: 100%;
        align-items: center;

        &::after {
            content: unset !important;
            position: unset !important;
            top: unset !important;
            bottom: unset !important;
            right: unset !important;
            width: unset !important;
            background-color: unset !important;
            z-index: unset !important;
        }

        &::before {
            content: unset !important;
            position: unset !important;
            top: unset !important;
            bottom: unset !important;
            left: unset !important;
            width: unset !important;
            background-color: unset !important;
            z-index: unset !important;
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: -100%;
        width: 100%;
        background-color: $cyan;
        z-index: -1;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 40%;
        background-color: $white;
        z-index: -1;
    }

    .left-container {
        max-width: 40%;
        padding: 3rem 1.5rem 3rem 3rem;
        background-color: $white;

        @include respond(phone) {
            padding: 1.5rem 2rem 1.5rem 2rem !important;
        }

        @media screen and (max-width: 1024px) {
            max-width: 100%;
        }

        @media (min-width: 770px) and (max-width: 1024px) {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        @media (min-width: 1470px) and (max-width: 1600px) {
            padding-left: 0;
            max-width: 35%;
        }

        @media (min-width: 1600px) and (max-width: 2560px) {
            padding-left: 0;
            max-width: 35%;
        }

        .left-content-wrapper {
            flex-direction: column;
            height: 100%;

            @media (min-width: 770px) and (max-width: 1024px) {
                max-width: 100%;
                width: 710px;
            }

            .contact-wrapper {
                flex: unset;

                h1 {
                    font-size: 1.5rem;
                    font-weight: bold;
                    color: #25255e;
                    text-decoration: none solid rgb(37, 37, 94);
                }

                input[type='text'],
                input[type='email'],
                input[type='tel'],
                select {
                    background: $white;
                    border-radius: 0;
                    padding: 10px 25px;

                    @media screen and (min-width: 500px) and (max-height: 900px) {
                        padding: 10px 20px;
                    }

                    @include respond(phone) {
                        padding: 12px 16px;
                    }
                }

                .form_error {
                    border: 1px solid #ff4343 !important;
                    color: #ff4343;
                }

                .error {
                    color: $red;
                }

                .email-field-wrapper {
                    margin: 1rem 0;
                }

                .field-wrapper {
                    margin: 1rem 0;
                    display: flex;
                    justify-content: space-between;

                    .margin-right-box {
                        margin-right: 1rem;
                    }

                    .no-content-selected {
                        color: gray !important;
                    }

                    select {
                        width: 49%;
                        height: 43px;

                        option {
                            color: $black;
                        }
                    }

                    .zipcode-wrapper {
                        width: 49%;

                        p {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .payment-wrapper {
                flex: unset;

                h1 {
                    font-size: 1.5rem;
                    font-weight: bold;
                    color: #25255e;
                    text-decoration: none solid rgb(37, 37, 94);
                    margin-bottom: 1rem;
                }

                .payment-content-wrapper {
                    padding: 0;
                    margin: 0;
                    background-color: unset;
                    border-radius: unset;
                    box-shadow: unset;

                    .content-wrapper {
                        .payment-button-wrapper {
                            button {
                                display: flex;
                                align-items: center;
                                background-color: $indigo-dye;
                                font-size: 26px;
                                font-family: 'Poppins', sans-serif;
                                font-weight: 600;
                                color: $white;
                                padding: 14px 90px;
                                border: 0;
                                border-radius: 45px;

                                @include respond(phone) {
                                    width: 100%;
                                    padding: 14px 0;
                                    justify-content: center;
                                }

                                @include respond(smallest-phone) {
                                    width: 100%;
                                    padding: 10px 0;
                                    justify-content: center;
                                }

                                &.dark {
                                    background-color: $madison;
                                }

                                &.light-blue {
                                    background-color: $cornflower-blue;
                                }

                                &.light {
                                    background-color: $gallery;
                                    color: $stratos;
                                }

                                &.sm {
                                    font-size: 18px;
                                    padding: 14px 30px;

                                    @include respond(phone) {
                                        padding: 10px 30px;
                                    }
                                }
                            }
                        }

                        .agreement-wrapper {
                            margin-bottom: 1rem;
                        }
                    }
                }
            }
        }
    }

    .show-summary-block {
        padding-bottom: 0 !important;
        padding-top: 0.8rem !important;
    }

    .left-show-summary-block {
        padding-top: 0.8rem !important;
    }

    .right-container {
        padding: 3rem 2rem 3rem 2rem;

        @include respond(tab-port-large) {
            flex: unset;
        }

        @include respond(phone) {
            flex: unset;
            margin-bottom: 1rem;
            padding: 1rem 1rem 0rem 1rem !important;
        }

        @media screen and (max-width: 1024px) {
            max-width: 100%;
        }

        @media (min-width: 770px) and (max-width: 1024px) {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        @media (min-width: 1400px) and (max-width: 1600px) {
            padding-right: 0;
            max-width: 50%;
        }

        @media (min-width: 1600px) and (max-width: 2560px) {
            padding-right: 0;
            max-width: 45%;
        }

        .right-container-wrapper {
            @media (min-width: 770px) and (max-width: 1024px) {
                max-width: 690px;
            }
        }

        .order-summary-text {
            display: flex;
            justify-content: space-between;
            font-size: 1.5rem;
            font-family: "Segoe UI", sans-serif;
            color: #25255e;
            text-decoration: none solid rgb(37, 37, 94);

            p {
                margin-bottom: 0;
            }

            .summary-text-wrapper {
                cursor: pointer;
                background-color: unset;
                border: unset;
                color: unset;
                padding: 0;
                margin: 0;
                height: 30px;

                b {
                    font-weight: normal;
                    font-family: serif;
                }
            }
        }

        .hidden-container {
            display: none;
        }

        .right-content-wrapper {
            flex-direction: column;
            justify-content: space-between;
            height: 100%;

            .payment-summary-wrapper {
                flex: unset;
            }

            .summary-divider {
                margin: 2rem 1.5rem 2rem 1.5rem;
            }
        }
    }
}

.empty-cart-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    background-color: $light-blue;
    border-radius: 15px;
    padding: 60px 90px;
    margin: 5rem auto;

    a {
        color: blue;
        text-decoration: underline;
    }

    h1 {
        text-align: center;
        color: #25255e;
        font: 600 28px / 40px Poppins, sans-serif;
        max-width: 834px;
        margin-bottom: 30px;

        @include respond(phone) {
            font: 600 18px / 27px Poppins, sans-serif;
        }
    }

    .content-wrapper {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        gap: 0.8rem;
    }
}

.single-page-checkout-heading-wrapper {
    flex: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5fafd;

    .col {
        display: flex;
        align-items: center;
        justify-content: center;

        .heading {
            text-align: center;
            color: $brand-blue;
            font: normal normal 600 28px/40px Poppins, sans-serif;
            max-width: 834px;
            margin-bottom: 30px;

            @include respond(phone) {
                margin-top: 30px;
                font-size: 18px;
                max-width: 93%;
                width: 100%;
                line-height: 27px;
            }

            @include respond(smallest-phone) {
                margin-top: 25px;
                margin-bottom: 25px;
                font-size: 16px;
            }

            @media screen and (min-width: 500px) and (max-width: 900px) {
                margin-bottom: 10px;
                font: normal normal 600 24px/40px Poppins,sans-serif;
            }
        }
    }
}