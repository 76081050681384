@import "./src/scss/globals";
.unsubcribe-notification {
    &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        padding: 30px 15px;
        @include respond(phone) {
            padding: 40px 15px;
        }
        .un-wrapper {
            border-radius: 20px;
            padding: 25px 35px;
            background-color: $white;
            width: 100%;
            max-width: 560px;
            box-shadow: 0 0 30px rgba(37, 37, 94, 0.06);
            margin: auto;
            margin-bottom: 30px;
            @include respond(phone) {
                padding: 20px 15px 15px;
            }
            .notification-list {
                width: 100%;
            }
            h1 {
                font-size: 32px;
                font-weight: 700;
                margin-bottom: 16px;
                color: $brand-blue;
                font-family: 'Poppins', sans-serif;
            }
            h2 {
                color: $mid-dark-gray;
                border-top: 1px solid $mid-gray;
                border-bottom: 1px solid $mid-gray;
                margin: 16px 0;
                padding: 10px 0;
                line-height: 1.3;
                font-size: 20px;
            }
        }
        & > .heading {
            text-align: center;
            color: $brand-blue;
            font: normal normal 600 32px/40px 'Poppins', sans-serif;
            max-width: 834px;
            margin-bottom: 30px;
            @include respond(phone) {
                margin-top: 30px;
                font-size: 20px;
                max-width: 95%;
                width: 100%;
                line-height: 27px;
            }
        }

        & > .sub-heading {
            text-align: center;
            color: $brand-blue;
            padding: 40px;

            @include respond(phone) {
                padding: 20px;
            }
        }

        button {
            background-color: $mid-gray;
            color: black;
            font-size: 20px;
            font-weight: 600;
            padding: 8px 24px;
            border-radius: 50px;
            @include respond(phone) {
                padding: 8px 15px;
                margin-top: 10px;
            }
        }
    }
    &-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        .notification-list {
            &-container {
                margin-bottom: 20px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                .heading {
                    color: $brand-blue;
                    font-size: 18px;
                    font-weight: 700;
                    margin-bottom: 5px;
                    font-family: 'Poppins', sans-serif;
                }
                .notification-list-content-wrapper {
                    span {
                        display: flex;
                        width: 95%;
                    }
                }
            }
        }
    }
}

.wrapper.pref {
    background-color: $light-blue;
    height: auto;
    min-height: 100vh;
    & > header {
        justify-content: center;
        background-color: $brand-blue;
    }
}
