@import "./src/scss/globals";
.question {
    margin: 10px 0;
}

.question-message {
    padding: 10px 20px;
    background-color: #f5fafd;
    border-left: 10px solid;
    display: none;
    flex-direction: column;

    > h3 {
        font-weight: bold;
    }

    .feedback-container {
        margin-bottom: 0;
    }

    &--correct {
        color: $black;
        display: flex;
        border-color: $correct-green;
    }

    &--wrong {
        color: $black;
        border-color: $incorrect-orange;
        display: flex;
    }
}
