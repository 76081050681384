@import "./src/scss/globals";
.checkout {
    &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        -webkit-overflow-scrolling: touch;

        & > .heading {
            text-align: center;
            color: $brand-blue;
            font: normal normal 600 28px/40px Poppins,sans-serif;
            max-width: 834px;
            margin-bottom: 30px;

            @include respond(phone) {
                margin-top: 30px;
                font-size: 18px;
                max-width: 95%;
                width: 100%;
                line-height: 27px;
            }

            @include respond(smallest-phone) {
                margin-top: 25px;
                margin-bottom: 25px;
                font-size: 16px;
            }

            @media screen and (min-width: 500px) and (max-width: 900px) {
                margin-bottom: 10px;
                font: normal normal 600 24px/40px Poppins,sans-serif;
            }
        }

        .checkout-page-preview-settings-container {
            margin-left: auto;
            margin-right: 20px;
        }

        .checkout-page-preview-settings-confirmation {
            position: absolute;
            right: 20px;
            color: $white;
        }
    }

    &-payment-container {
        background-color: $cyan !important;
    }

    &-payment-stage {
        flex-direction: column-reverse !important;
        background-color: unset !important;
        padding: 0px 0px 60px 0px !important;
    }

    &-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        max-width: 1266px;
        background-color: $light-blue;
        border-radius: 15px;
        padding: 60px 90px;

        @media screen and (min-width: 501px) and (max-height: 900px) {
            padding: 30px 45px 30px;
        }

        @media screen and (max-width: 900px) {
            padding: 60px 45px;
        }

        @media screen and (max-width: 750px) {
            padding: 40px 10px;
            width: 95%;
            margin-bottom: 16px;
        }

        @include respond(smallest-phone) {
            padding: 30px 10px;
        }

        h1 {
            font: normal normal 600 30px Poppins, sans-serif;
            color: $brand-blue;
            margin-bottom: 5px;
            text-align: center;

            @include respond(phone) {
                font-size: 20px;
                margin-bottom: 10px;
            }
        }

        h3 {
            font: normal normal 600 18px 'Open Sans', sans-serif;
            color: $black;
            text-align: center;

            @include respond(phone) {
                font-size: 16px;
            }
        }

        h6 {
            font-size: 18px;
            text-align: center;
            max-width: 620px;

            & > span {
                font-weight: bold;
            }

            @include respond(phone) {
                font-size: 16px;
            }
        }

        a {
            color: #25255e;
            text-decoration: underline;
        }

        button {
            @include checkout-main-button;
        }

        & > button {
            margin-top: 65px;

            @media screen and (min-width: 500px) and (max-height: 900px) {
                margin-top: unset;
            }

            @include respond(phone) {
                margin-top: 30px !important;
                font-size: 18px;
            }
        }

        .checkout-upsell-next-button {
            margin-top: 65px !important;

            @include respond(phone) {
                margin-top: 30px !important;
                font-size: 18px;
            }
        }

        .number-input-text {
            button {
                display: block;
                width: 100%;
                font-weight: 400;
                line-height: 1.5;
                color: #495057;
                background-color: #fff;
                background-clip: padding-box;
                border-radius: 0.25rem;
                padding: 0.5rem 0;
                border: unset;
                transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                border-top-right-radius: unset;
                border-bottom-right-radius: unset;
                height: 4rem;

                @media screen and (max-width: 1000px) {
                    height: 4.5rem;
                }

                @media screen and (min-width: 500px) and (max-height: 900px) {
                    height: 3rem;
                }

                @include respond(phone) {
                    height: 47px;
                }
            }

            input[type='tel'] {
                border: 1px solid $white;
                border-top-left-radius: unset;
                border-bottom-left-radius: unset;
                border-left: unset;
            }

            input[type='text'] {
                border: 1px solid #ced4da;
            }

            a {
                color: unset;
                text-decoration: unset;
            }

            .dropdown-toggle {
                &::after {
                    margin: 5px;
                    border-top: 0.3em solid !important;
                    border-right: 0.3em solid transparent !important;
                    border-bottom: 0 !important;
                    border-left: 0.3em solid transparent !important;
                }
            }
        }
    }

    &-form {
        display: flex;
        max-width: 740px;
        width: 100%;
        margin-top: 30px;
        flex-wrap: wrap;
        justify-content: space-between;

        @media screen and (min-width: 500px) and (max-height: 900px) {
            margin-top: 20px;
        }

        > div > .login-link {
            color: $black;
            text-decoration: underline;
            font-style: normal;
            font-size: large;
            cursor: pointer;
            background-color: unset;
            border: unset;
            border-radius: unset;
            padding: unset;
            display: unset;
            outline: 0;
        }

        > div {
            margin: 10px 2.5%;
            max-width: 45%;
            width: 100%;

            > p {
                margin-top: 5px;
                color: $red;
                font-size: 14px;
                font-style: italic;
            }

            @media screen and (min-width: 500px) and (max-height: 900px) {
                margin: 6px 2.5%;
            }
        }

        input[type='text'],
        input[type='email'],
        input[type='tel'],
        select {
            background: $white;
            border: 0;
            border-radius: 0;
            padding: 20px 25px;

            @media screen and (min-width: 500px) and (max-height: 900px) {
                padding: 10px 20px;
            }

            @include respond(phone) {
                padding: 12px 16px;
            }
        }

        select.stateDropddown {
            background-image: none;
        }

        .full-width {
            grid-column-start: 1;
            grid-column-end: 3;
        }

        @include respond(phone) {
            width: 100%;

            > div {
                margin: 10px 2.5% 0 2.5%;
                max-width: 95%;
                width: 100%;
            }
        }
    }

    &-contact,
    &-address,
    &-billing,
    &-payment {
        margin-top: 65px;

        @media screen and (min-width: 500px) and (max-height: 900px) {
            margin-top: 25px;
        }

        @include respond(phone) {
            margin-top: 30px;
        }
    }

    &-payment {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        width: 100%;

        label {
            display: flex;
            align-items: center;
            justify-content: center;

            input[type='checkbox'] {
                width: 13px;
                height: 13px;
                margin-right: 5px;
                border-radius: 0;
            }
        }

        &__options {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            margin-top: 40px;
            max-width: 100%;
            width: 100%;

            > div {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            @media screen and (max-width: 1000px) {
                flex-direction: column;
                align-items: center;
            }

            @include respond(phone) {
                margin-top: 25px;
            }
        }

        &__options > &__or {
            flex-direction: row;
            font-size: 24px;
            font-family: 'Poppins', sans-serif;
            letter-spacing: 0;
            font-weight: 500;
            height: 60px;
            margin: 0 80px;
        }

        &__card {
            > span {
                display: block;
                text-align: center;
                width: 100%;
                max-width: 380px;
                font-size: 14px;
                margin-top: 20px;
            }

            .stripe-button {
                text-decoration: none !important;
                display: flex;
                width: 300px;

                button {
                    width: 125%;
                    justify-content: center;

                    @include respond(phone) {
                        max-width: 260px;
                        margin: auto;
                    }
                }
            }
        }

        &__paypal {
            width: 100%;
            display: block;
            text-align: center;
            font-size: 14px;
            max-width: 380px;
            height: 55px;

            a {
                width: 100%;

                button {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    padding: 14px 30px !important;
                }
            }

            > .paypal {
                max-width: 380px;
                width: 100%;

                @include respond(phone) {
                    max-width: 300px;
                }
            }
        }

        &__badges {
            display: flex;
            justify-content: center;
            margin-top: 30px;

            & > div {
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                width: 145px;
                height: 45px;
                border-radius: 2px;

                & > img {
                    height: 22px;
                }

                & > svg {
                    margin-right: 5px;
                    font-size: 20px;
                }
            }

            @media screen and (max-width: 350px) {
                flex-direction: column;
                align-items: center;

                & > div {
                    margin: 10px;

                    & > svg {
                        margin: 0px;
                    }
                }
            }
        }

        &__stripe {
            font-size: 11px;
            background-color: $ebony-clay;
            margin-right: 10px;
            font-weight: 600;
        }

        &__ssl {
            background-color: $victoria;
            font-size: 11px;
            text-transform: uppercase;

            & > div {
                display: flex;
                flex-direction: column;
                align-items: center;
                line-height: 11px;

                & > span {
                    font-weight: bold;
                }
            }
        }

        .cards-logos {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 30px;

            img {
                height: 24px;
            }
        }
    }

    &-upsells {
        background-color: $white;
        border-radius: 15px;
        padding: 50px;
        margin-top: 50px;
        width: 100%;

        @media screen and (max-width: 600px) {
            padding: 20px;
        }

        @include respond(phone) {
            margin-top: 30px;
        }

        &__item {
            display: flex;

            &:not(:last-child) {
                margin-bottom: 65px;
            }

            & > .non-square-image {
                object-fit: cover !important;
            }

            & > img {
                object-fit: contain;
                height: 150px;
                width: 150px;
                border-radius: 8px;
            }

            & > div {
                margin-left: auto;
                max-width: 285px;
                width: 100%;

                button {
                    width: 100%;
                    justify-content: center;
                }

                & > div {
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    align-items: center;
                    margin-top: 10px;
                    color: $ebony-clay;

                    & > svg {
                        font-size: 28px;
                    }
                }
            }

            @media screen and (max-width: 1000px) {
                flex-direction: column;
                align-items: center;

                & > div {
                    button {
                        margin: 15px 0;
                    }

                    margin: 0;
                }
            }
        }

        &__price {
            font-size: 28px;
            font-weight: 600;
            margin: 0 8px;

            @include respond(phone) {
                font-size: 20px;
            }
        }

        &__oldPrice {
            font-size: 24px;
            color: $red;
            text-decoration: line-through;

            @include respond(phone) {
                font-size: 18px;
            }
        }

        &__discount {
            font-size: 24px;
            margin-left: 10px;

            @include respond(phone) {
                font-size: 20px;
            }
        }

        &__itemData {
            margin-left: 25px;
            max-width: 390px;

            h1 {
                font-size: 22px;
                color: $black;
                font-weight: medium;
                text-align: left;

                @include respond(phone) {
                    font-size: 18px;
                }
            }

            span {
                font-size: 16px;
            }

            @media screen and (max-width: 1000px) {
                text-align: center;
                margin-left: 0px;
                margin-top: 15px;

                h1 {
                    text-align: center;
                }
            }
        }

        .timer-icon {
            width: 30px;
            height: 30px;

            @include respond(phone) {
                width: 22px;
            }
        }

        &__header {
            margin-top: 65px;

            @include respond(phone) {
                margin-top: 30px;
            }
        }
    }
}
