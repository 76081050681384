@import "./src/scss/globals";
.lesson-page-header {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    background-color: lighten($blue, 54.5%);
    font-weight: bold;

    >i {
        margin-right: 20px;

        &>svg {
            width: 25px;
            height: 25px;
        }

        &:hover {
            cursor: pointer;
        }
    }

    >span {
        >i {
            margin: 0 10px;
        }
    }

    .page-preview-settings-container {
        margin-left: auto;
        margin-right: 10px;
    }

    .lesson-type {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 30px;
        padding: 0 10px;
        font-size: 16px;
        margin-right: 10px;
        color: $white;

        &.state {
            background-color: $state-brown;
        }

        &.national {
            background-color: $cornflower-blue;
        }

        i {
            margin: 0;
            margin-right: 10px;
        }
    }

    .header-text {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-height: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .page-header-chapter {
        flex-shrink: 0;
        max-width: 60%;
    }

    .page-header-lesson {
        flex-grow: 1;
    }

    @media screen and (max-width: 900px) {
        position: fixed;
        top: 48px;
        z-index: 300;
        padding-left: 0;
        left: 0;
        right: 0;

        &.state {
            background-color: $state-brown;
        }

        &.national {
            background-color: $cornflower-blue;
        }

        .sidebar-toggle {
            background-color: #fff;
            padding: 8px;
            border-radius: 0 8px 8px 0;
            margin-right: 10px;
        }

        .lesson-type {
            margin-right: 5px;

            &>i {
                margin: 0;
                font-size: 22px;
                color: black;
            }

            &>span {
                display: none;
            }
        }

        .page-header-chapter {
            max-width: 40%;
        }
    }
}