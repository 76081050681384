@import "./src/scss/globals";
.inline-quiz {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;

    .quiz-title {
        margin-top: 0.5rem !important;
    }

    header {
        display: flex;
        align-items: flex-start;
        flex-direction: column; 
        justify-content: space-between;
        padding: 20px 20px 0;
        > h1 {
            font-weight: bold;
            font-size: 24px;
        }
        > h2 {
            font-size: 20px;
        }
    }

    > h4 {
        padding: 0 20px;
        font-weight: bold;
        font-size: 24px;
    }

    .questions {
        padding: 0 25px 0 20px;
    }
    
    & > button {
        border: 0;
        outline: none;
        background-color: $brand-cyan;
        color: $white;
        width: 20%;
        border-radius: 5px;
        height: 40px;
        margin: 0 auto;
        margin-top: 20px;
    }

    @media screen and (max-width: 720px) {
        button {
            width: auto;
        }
    }
}
