@import "./src/scss/globals";
.lesson-container {
    width: 100%;
    height: 100%;
    display: flex;
}

.lesson-container-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
}

.lesson-refresh-modal {
    .modal-dialog {
        justify-content: center;

        .modal-content {
            width: 50%;
            border-radius: 0.9rem !important;
            border-color: #25255e;
            .modal-header {
                background-color: #25255e;
                color: #fff;
                align-items: center;
                justify-content: center;
                border-top-left-radius: 0.7rem;
                border-top-right-radius: 0.7rem;
                padding: 1.5rem 1.5rem;
                @media screen and (max-width: 375px) {
                    padding: 1rem 1rem;
                }
                .btn-close {
                    filter: invert(1);
                    opacity: 1;
                }
            }
        }
    }
}
