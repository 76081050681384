@import "./src/scss/globals";
.quiz-attempt {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    height: 70px;
    background-color: $light-blue;
    border-top: solid 1px darken($cyan, 15);
    &:last-child {
        border-bottom: solid 1px darken($cyan, 15);
    }
    .colour-bar {
        position: absolute;
        left: 0;
        width: 15px;
        height: 100%;
        &.success {
            background-color: $brand-cyan;
        }
        &.fail {
            background-color: $brand-red;
        }
    }
    .status {
        font-weight: bold;
        width: 65px;
    }
    @media screen and (max-width: 500px) {
        padding: 6px 30px;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 10px;
        height: auto;
        .status {
            flex: 0 0 auto;
            width: 20%;
        }
        .completed-date {
            flex: 0 0 auto;
            width: 50%;
        }
        .percentage-score {
            flex: 0 0 auto;
            width: 20%;
        }
    }
}
