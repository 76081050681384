@import "./src/scss/globals";
.slide-layout {
    position: relative;
    display: block;
    height: auto;
    min-height: calc(100vh - 54px);
    width: 100%;

    @media screen and (max-width: 900px) {
        height: calc(100vh - 48px);
    }
    .sidebar-toggle {
        position: absolute;
        z-index: 10;
        top: 10px;
        left: 10px;
    }
    .slide-preview-settings-container {
        position: absolute;
        z-index: 100;
        right: 20px;
        top: 10px;
        @media screen and (max-width: 900px) {
            right: 5px;
            left: auto;
            top: 4px;
        }
    }
    .slide-container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        right: 0;
        width: 100%;
        height: 100%;
        &__wrapper {
            display: flex;
            flex-direction: column;
            width: 800px;
            & > header {
                & > h3 {
                    font-size: 16px;
                    font-weight: bold;
                }
                & > h5 {
                    font-size: 16px;
                    color: $light-gray;
                }
            }
            .exam-card {
                background-color: #f5fafd;
                .exam-slide {
                    overflow-y: auto;
                    padding: 20px 30px;
                }
            }

            & > main {
                height: 400px;
                overflow-y: auto;
                box-shadow: 0 0 10px rgba($brand-blue, 0.3);
                --ck-color-image-caption-background: hsl(0, 0%, 97%);
                --ck-color-image-caption-text: hsl(0, 0%, 20%);
                --ck-image-style-spacing: 1.5em;
                --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
                @import '../../../../../Courses/CKEditorContent.scss';
                img,
                figure {
                    max-width: 100%;
                }
                p {
                    word-break: break-word;
                }
                header {
                    > h1,
                    > h2,
                    > h3,
                    > h4,
                    > h5,
                    > h6,
                    > .h1,
                    > .h2,
                    > .h3,
                    > .h4,
                    > .h5,
                    > .h6 {
                        line-height: 1.5;
                    }
                }
            }
            & > footer {
                height: 75px;
            }
            @media screen and (max-width: 900px) {
                align-items: flex-start;
                width: 100vw;
                height: 100%;
                & > header {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    background-color: rgba($blue, 0.1);
                    position: relative;
                    height: 48px;
                    & > h3,
                    & > h5 {
                        display: block;
                        font-size: 14px;
                        color: $light-gray;
                        font-weight: normal;
                        margin: 0;
                        max-width: calc(100% - 220px);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
                & > main {
                    width: 100vw;
                    height: auto;
                    flex: 1;
                    box-shadow: none;
                }
                & > footer {
                    height: 53px;
                    width: 100%;
                }
            }
        }
        &__progress {
            height: 15px;
            width: 100%;
            background-color: lighten($brand-cyan, 35%);
            & > div {
                height: 100%;
                background-color: $brand-cyan;
            }
            @media screen and (max-width: 900px) {
                height: 5px;
            }
        }
        &__navigation {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $brand-blue;
            height: 60px;
            color: $white;
            font-size: 14px;
            position: relative;
            .lesson-button {
                position: absolute;
                display: flex;
                align-items: center;
                padding: 0 15px;
                height: 100%;
                user-select: none;
                cursor: pointer;
                background-color: transparent;
                transition: background-color 0.2s ease-out;
                font-size: 14px;
                &:hover {
                    background: lighten($brand-blue, 10%);
                }
                i {
                    margin: 0 15px;
                    font-size: 11px;
                    transform: translateY(1px);
                }
                &--next {
                    top: 0;
                    padding-right: 0;
                    right: 0;
                }
                &--previous {
                    padding-left: 0;
                    left: 0;
                }
                &--disabled {
                    opacity: 0.5;
                    &:hover {
                        background-color: transparent;
                        cursor: default;
                    }
                }
                &.take-final-exam-btn {
                    right: 10px;
                }
            }
            .check-answer-container {
                @extend %center-content;
                margin: 0px;
                height: 50px;
                position: absolute;
                bottom: 116%;
                z-index: 10;
                background-image: linear-gradient(transparent, rgba(255, 255, 255, 0.3));

                button {
                    text-align: center;
                    padding: 5px 35px;
                    background-color: #2fcca1;
                    color: #000;
                    border: 2px solid #2fcca1;
                    text-decoration: none;
                    border-radius: 50px;
                    font-weight: bold;
                }
            }
            @media screen and (max-width: 900px) {
                height: 48px;
                width: 100vw;
            }
            .calendar-group {
                max-height: 200px;
                overflow-y: auto;
            }
        }

        .banner-container {
            margin: 0px;
            max-width: 100%;
        }
    }
}
