@import "./src/scss/globals";
#user-chat-post-purchase-menu {
    border: unset;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    inset: 0px 0px auto auto;

    .chat-purchase-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        position: relative;
        z-index: 1;
        padding: 0 1rem;
        overflow-x: auto;
        flex-wrap: nowrap;

        .chat-purchase-wrapper {
            height: calc(100vh - 14.9rem);
            padding: 0 1rem;
            margin: 0;

            @media screen and (max-height: 760px) {
                height: calc(100vh - 10rem);
            }
        }

        .chat-header-container {
            font-family: 'Segoe UI', sans-serif;
            font-size: 1.5rem;
            display: flex;
            flex-grow: unset;
            margin: 1rem 0;

            @media screen and (max-width: 450px) {
                font-size: 1.4rem;
            }

            @media screen and (max-width: 375px) {
                font-size: 1.2rem;
            }

            p {
                color: $brand-cyan;
                margin-bottom: 0;
                margin-right: 0.5rem;
            }

            span {
                color: $brand-blue;
            }
        }

        .chat-subheader-container {
            font-size: 0.9rem;
            flex-grow: unset;
            margin-bottom: 1.2rem;
        }

        .chat-image-menu-container {
            flex-grow: unset;
            margin-bottom: 1rem;
            display: flex;
            justify-content: center;
            height: 4rem;

            img {
                object-fit: contain;

                @media screen and (max-width: 450px) {
                    width: 100%;
                }
            }
        }

        .chat-image-container {
            flex-grow: unset;
            margin-bottom: 1rem;
            display: flex;
            justify-content: center;

            img {
                width: 100%;
                object-fit: contain;

                @media screen and (max-width: 450px) {
                    width: 100%;
                }
            }
        }

        .button-wrapper {
            flex-grow: unset;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 1.3rem 0;

            button {
                font-weight: normal;
            }

            p {
                margin-bottom: 0;
                margin-right: 0.7rem;
                font-size: 1.125rem;
                font-weight: bold;
            }
        }

        .footer-heading-1-container {
            flex-grow: unset;
            padding: 0.8rem;
            border-top: 1px solid $mid-gray;

            :last-child {
                margin-bottom: 0 !important;
            }
        }
    }
}

.chat-post-purchase-modal {
    .modal-dialog {
        justify-content: center;
        width: 30rem !important;

        @include respond(phone) {
            width: unset !important;
        }

        .modal-content {
            border: 0;

            .modal-body {
                max-height: 100% !important;
                overflow: unset !important;
                padding: 0;
            }
        }
    }
}