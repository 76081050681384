@import "./src/scss/globals";
.list-wrapper {
    flex-direction: column;
    padding: 0;

    .heading-wrapper {
        & > h2 {
            color: $theme-navy;
            font-weight: bold;
            margin-top: 0.5rem;
            font-size: 1.6rem;
            margin-bottom: 1rem;
        }
    }

    .order-summary-package-wrapper {
        overflow: auto;
        flex: unset;

        @media screen and (max-width: 320px) {
            padding: 0;
        }

        .order-summary-container {
            border-radius: 15px;
            max-height: calc(100vh - 330px);

            .payment-package-card {
                width: 100%;
                font-family: 'Poppins', sans-serif;
                display: flex;
                flex-wrap: wrap;
                background: $white;
                padding: 20px 30px 20px;
                border-radius: 15px;
                box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;

                &:not(:last-child) {
                    margin-bottom: 25px;
                }

                @include respond(phone) {
                    flex-wrap: nowrap;
                }

                .chatImgWrap {
                    flex: 0 0 auto;
                    width: 25%;
                    padding-right: 30px;
                    height: 100%;

                    @include respond(phone) {
                        padding-right: 0 !important;
                        padding-bottom: 15px !important;
                        margin-right: 3% !important;
                        width: 27% !important;
                    }

                    @include respond(tab-port) {
                        padding-right: 0;
                        padding-bottom: 8px;
                        margin-right: 10px;
                        width: 21%;
                    }
                }

                .imgWrap {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex: 0 0 auto;
                    width: 25%;
                    padding-right: 30px;

                    @include respond(phone) {
                        padding-right: 0 !important;
                        padding-bottom: 15px !important;
                        margin-right: 3% !important;
                        width: 27% !important;
                    }

                    @include respond(tab-port) {
                        padding-right: 0;
                        padding-bottom: 8px;
                        margin-right: 10px;
                        width: 21%;
                    }

                    img {
                        width: 150px;
                        display: block;
                        font-size: 12px;
                        border-radius: 8px;
                        height: 150px;
                        object-fit: contain;

                        @include respond(tab-port) {
                            width: 125px;
                            height: 125px;
                            background: transparent !important;
                        }

                        @include respond(large-phone) {
                            width: 115px;
                        }

                        @include respond(phone) {
                            width: 75px;
                            object-fit: contain;
                            height: 75px !important;
                            background: transparent !important;
                        }

                        @media (min-width: 1025px) and (max-width: 1160px) {
                            width: 130px;
                            height: 130px;
                        }
                    }

                    .non-square-image {
                        object-fit: cover !important;

                        @include respond(phone) {
                            object-fit: cover !important;
                        }
                    }
                }

                .course-content-width {
                    width: 100% !important;
                }

                .contentWrap {
                    flex: 0 0 auto;
                    width: 75%;
                    display: flex;
                    justify-content: space-between;

                    @include respond(phone) {
                        width: 70%;
                    }

                    .detailsWrap {
                        display: flex;
                        justify-content: space-between;
                        gap: 20px;
                    }

                    h3 {
                        text-align: left;
                        font-family: inherit;
                        font-size: 18px;
                        line-height: 24px;
                        font-weight: 600;
                        color: $brand-blue;
                        margin: 0;

                        @include respond(phone) {
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }
                }

                .details {
                    padding: 15px 0 0;
                    overflow: hidden;
                    flex-grow: 1;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;

                    .details-row {
                        display: flex;
                        justify-content: space-between;

                        .price {
                            color: $ebony-clay;
                            font-weight: 600;

                            .old {
                                font-weight: normal;
                                text-decoration: line-through;
                                color: $red;
                                margin-right: 6px;
                            }
                        }

                        .tax-label,
                        .tax-value {
                            font-size: 14px;
                            line-height: 16px;
                            font-weight: normal;
                            color: #1d1d1d;

                            @include respond(phone) {
                                font-size: 14px;
                                line-height: 22px;
                                max-width: 167px;
                            }
                        }

                        .terms-conditions-button {
                            font-size: 12px;
                            text-transform: uppercase;
                            border: none;
                            background: #2f74ac;
                            color: $white;
                            padding: 2px 8px;
                            font-weight: 200;
                            font-family: sans-serif, Arial, Verdana, 'Trebuchet MS', 'Apple Color Emoji',
                                'Segoe UI Emoji', 'Segoe UI Symbol';
                            -webkit-font-smoothing: antialiased;
                        }
                    }
                }

                .order-package-controls {
                    font-size: 20px;
                    display: flex;
                    align-items: center;

                    & > .delete {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $mid-gray;
                        width: 36px;
                        height: 36px;
                        border-radius: 5px;
                        color: $black;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
