@import "./src/scss/globals";
.suggestion-upsell {
    &-modal {
        background-color: $mid-gray !important;
        .modal-dialog {
            @media screen and (max-width: 768px) {
                width: 100% !important;
            }
            @media (min-width: 769px) and (max-width: 1365px) {
                width: calc(100vw - 160px) !important;
                max-width: unset;
            }
            @media (min-width: 1366px) and (max-width: 1440px) {
                max-width: calc(100vw - 200px) !important;
            }
            @media (min-width: 1441px) and (max-width: 1500px) {
                max-width: 90% !important;
            }
            @media (min-width: 1501px) and (max-width: 1900px) {
                width: 75rem !important;
            }
            @include respond(phone) {
                padding: 16px 10px !important;
            }
        }
        .modal-body {
            min-height: calc(100vh - 430px);
            @include respond(phone) {
                min-height: initial;
                max-height: initial !important;
            }
        }
        .modal-content {
            border-radius: 0.9rem !important;
            @include respond(phone) {
                height: 100%;
            }
        }
        .modal-header {
            background-color: #25255e;
            color: #fff;
            align-items: center;
            justify-content: center;
            border-top-left-radius: 0.9rem;
            border-top-right-radius: 0.9rem;
            padding: 1.5rem 1.5rem;
            @media screen and (max-width: 375px) {
                padding: 1rem 1rem;
            }
        }
        .modal-footer {
            align-items: center;
            justify-content: flex-end;
            .addtocart-button-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;
                .price-wrapper {
                    display: flex;
                    width: 15rem;
                    justify-content: flex-end;
                    align-items: flex-end;

                    h4 {
                        margin-right: 8px;
                    }
                    h5 {
                        font-size: 16px;
                        color: $red;
                        text-decoration: line-through;
                        margin-right: 5px;
                    }
                }
                .button-wrapper {
                    display: flex;
                    flex-direction: row-reverse;
                    flex-wrap: wrap;
                    gap: 8px;
                    .add-to-cart {
                        width: 9rem;
                        color: $white;
                        &:hover {
                            color: $black;
                        }
                        @media screen and (max-width: 1200px) {
                            width: 14rem;
                        }
                        @include respond(phone) {
                            width: 10rem;
                        }
                    }

                    .button--disabled {
                        border: 1px solid $mid-gray;
                        background-color: $mid-gray !important;
                        color: $mid-dark-gray !important;
                        cursor: not-allowed !important;
                        &:hover,
                        &:active,
                        &:focus {
                            background-color: $mid-gray;
                            outline: 0;
                            color: unset;
                            box-shadow: none;
                        }
                    }

                    .no-thanks-button-wrapper {
                        width: 8rem !important;
                        display: flex;
                        justify-content: center;
                        .btn,
                        .btn:active,
                        .btnn:focus {
                            background-color: $white;
                            color: $light-gray;
                            margin-top: 3px;
                            font-weight: unset;
                            box-shadow: unset !important;
                        }
                    }
                }
            }
        }
    }
    &__item {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        width: 100%;
        @media (min-width: 780px) and (max-width: 2600px) {
            padding: 10px 10px;
        }
        @media screen and (max-width: 780px) {
            flex-direction: column-reverse;
            width: 100%;
            padding: 10px 10px;
        }
    }
    &__description {
        & > ul {
            padding-inline-start: 18px;
        }
    }
    &__itemData {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-right: 8px;
        @media screen and (max-width: 780px) {
            margin-right: 0;
            margin-top: 8px;
        }
    }
    &__itemImage {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        img {
            width: 23rem;
            height: 23rem;
            border-radius: 5px;
            object-fit: contain;
            @media (min-width: 780px) and (max-width: 2600px) {
                width: 18rem;
                height: 18rem;
            }
            @media screen and (max-width: 780px) {
                width: 100%;
                height: unset;
                margin-bottom: 10px;
                max-width: 220px;
            }
        }
    }
    img {
        max-width: 230px;
    }
}
