@import "./src/scss/globals";
.lesson-card {
    max-width: 100%;
    width: 100%;
    position: relative;

    &.slide {
        height: 100%;
    }

    &.card-quiz {
        height: 100%;
    }

    @media screen and (max-width: 900px) {
        .cards-quiz {
            max-height: initial;
            position: relative;

            .question-wrap {
                margin: 0;
            }
        }
    }
}
.card-content {
    height: inherit;
    > div {
        height: inherit;
    }
    section {
        padding: 10px 20px;
        p {
            &:last-child {
                margin: 0;
            }
        }
    }
}

@import './CardThemes.scss';
