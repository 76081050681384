@import "./src/scss/globals";
.exam-slide {
    &__data {
        margin-top: 10px;
        & > p {
            margin-bottom: 3px;
            span {
                font-weight: bold;
            }
            .no-of-questions {
                margin-right: 2rem;
            }
            .time-limit {
                margin-right: 6.8rem;
            }
            .attempt-limit {
                margin-right: 7.7rem;
            }
        }
    }
    &__footer {
        margin-top: 20px;
        & > button {
            margin: 16px 15px 16px 0;
            height: 45px !important;
        }
        & > button[disabled] {
            opacity: 0.6;
            cursor: not-allowed;
            background-color: $mid-gray;
            border: none;
            color: $black;
            &:hover {
                background-color: $mid-gray;
                color: $black;
            }
        }
    }
    &__info {
        display: flex;
        margin-top: 20px;
        .icon {
            width: 28px;
            height: 28px;
            border-radius: 50px;
            background-color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 22px;
            flex-shrink: 0;
            margin-right: 10px;
            svg {
                width: 28px;
                height: 28px;
                fill: $warning !important;
            }
        }
        & > p {
            text-decoration: underline;
            font-weight: bold;
        }
    }
}

.biosight-status-bar {
    text-align: center;
    padding: 2px;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    &.green {
        background-color: $brand-cyan;
    }

    &.orange {
        background-color: $incorrect-orange;
    }

    &.gray {
        background-color: rgba($mid-gray, 0.3);
    }

    span {
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 4px;
        &.okay {
            color: $brand-blue;
        }

        &.flagged {
            color: $red;
        }
    }

    @media screen and (min-width: 900px) {
        position: fixed;
        top: 54px;
        left: 0px;
    }
}
