@import "./src/scss/globals";
header {
    > h1 {
        font-weight: bold;
        font-size: 24px;
        @media screen and (max-width: 500px) {
            font-size: 18px;
        }
        @media (min-width: 501px) and (max-width: 900px) {
            font-size: 21px;
        }
    }
    > h2 {
        font-size: 20px;
        @media screen and (max-width: 500px) {
            font-size: 16px;
        }
        @media (min-width: 501px) and (max-width: 900px) {
            font-size: 17px;
        }
    }
}
