@import "./src/scss/globals";
.question-slide {
    .lesson-preview__container > main > & {
        padding: 0;
    }

    & > header {
        & > h3 {
            -webkit-line-clamp: 2;
        }

        & + section {
            padding-top: 0;
        }
    }

    .boolean-answers {
        .content {
            padding: 10px 20px 0 20px;
        }

        .questions {
            @extend %center-content-vertical-top;

            // flex-flow: row wrap;
            padding: 10px 20px;
            box-sizing: border-box;

            .question {
                position: relative;
                display: flex;
                // width: calc(50% - 20px);
                width: 100%;
                min-height: 90px;
                background-color: hsl(195, 53%, 93%);
                margin: 5px 10px;
                @media screen and (max-width: 900px) {
                    width: calc(100% - 20px);
                }
                .result {
                    position: absolute;
                    @extend %center-content;
                    width: 20px;
                    height: 100%;
                    svg {
                        display: none;
                    }
                    &.correct,
                    &.incorrect {
                        svg {
                            display: block;
                        }
                    }
                    &.correct {
                        background-color: $brand-cyan;
                        padding: 20px;
                    }
                    &.incorrect {
                        background-color: $incorrect-orange;
                        padding: 20px;
                    }
                }
                .content {
                    display: flex;
                    // flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    padding: 10px 20px;

                    &.adjust-content-padding {
                        padding-left: 3rem;
                    }

                    @media screen and (max-width: 900px) {
                        flex-direction: column;
                        align-items: initial;
                    }

                    span {
                        // margin-bottom: 10px;
                        font-weight: 600;
                        text-align: left;
                    }
                    .options {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-left: 20px;

                        .option {
                            border-radius: 50px;
                            background-color: #dadada;
                            border: 2px solid #dadada;
                            min-height: 33px;
                            width: 150px;
                            font-weight: 600;

                            @media screen and (max-width: 400px) {
                                // margin: 10px;
                            }

                            &.selected {
                                border: 2px solid $brand-blue;
                            }
                            &:first-child {
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    @import './BooleanAnswerMobile.scss';
}

.preview-wrapper.preview-wrapper--mobile {
    @import './BooleanAnswerMobile.scss';
}
