@import "./src/scss/globals";
#user-bar-profile-menu {
    border: unset;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    margin-top: 0.5rem !important;
    max-width: 380px !important;

    position: fixed;
    inset: 0px 0px auto auto;
    transform: translate(-3%, 25%);

    @media screen and (max-width: 900px) {
        transform: translate(-12%, 25%);
    }

    @media screen and (max-width: 320px) {
        transform: translate(-18%, 16%);
    }

    .popover-arrow {
        right: 1.2rem !important;
        position: absolute;
        display: block;
        width: 1rem;
        height: 0.5rem;
        margin: 0 0.3rem;
    }

    .popover-body {
        padding: 0.5rem 1.3rem !important;
    }

    .profile-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0;
        font-family: 'Open Sans', sans-serif;

        .col {
            padding: 20px 10px;
        }

        .col:not(:last-child) {
            border-bottom: 1px solid $mid-gray;
        }

        .profile-details-container {
            .profile-details-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1rem;

                @media screen and (max-width: 320px) {
                    flex-direction: column;
                }

                .profile-photo-container {
                    position: relative;
                    display: inline-block;

                    .profile-photo {
                        width: 4rem;
                        height: 4rem;
                        border-radius: 100%;
                        border: 2px solid $brand-cyan;
                        object-fit: cover;

                        @media screen and (max-width: 320px) {
                            width: 8rem;
                            height: 8rem;
                        }
                    }

                    .profile-avatar {
                        width: 4rem !important;
                        height: 4rem !important;
                        font-size: 1rem;

                        @media screen and (max-width: 320px) {
                            width: 8rem !important;
                            height: 8rem !important;
                            font-size: 1.5rem;
                        }
                    }

                    .overlay {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 100%;
                        background-color: rgba(255, 255, 255, 0.5);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        opacity: 0;
                        transition: opacity 0.3s ease;
                    }

                    .overlay i {
                        background: rgba(0, 0, 0, 0.5);
                        color: $white;
                        width: 2rem;
                        height: 2rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 100%;
                        font-size: 1em;
                    }
                }

                .profile-photo-container:hover .overlay {
                    opacity: 1;
                    cursor: pointer;
                }

                .profile-info {
                    min-width: 15rem;

                    h3 {
                        font-size: 20px;
                        font-weight: bold;
                        margin-bottom: 0 !important;
                    }

                    .profile-email {
                        font-size: 15px;
                        margin-bottom: 0 !important;
                    }

                    .profile-role {
                        font-size: 10px;
                        color: $light-gray-2;
                        margin-bottom: 0 !important;

                        span {
                            margin-left: 5px;
                        }
                    }
                }
            }
        }

        .profile-navigation {
            cursor: pointer;
            display: flex;
            align-items: center;

            i {
                font-size: 25px;
                color: $brand-blue;
            }

            span {
                font-size: 15px;
                margin-left: 10px;
            }
        }

        .profile-sign-out-wrapper {
            padding: 10px;
            color: $red;
            cursor: pointer;
        }
    }
}
