@import "./src/scss/globals";
.checkout-billing {
    .checkout-form {
        .zip-code-input {
            -webkit-appearance: none;
            appearance: none;
            margin: 0;
            -moz-appearance: textfield;
            background: #fff;
            border: 0;
            border-radius: 0;
            padding: 20px 25px;
            font-size: 1rem;
            line-height: 1.5;
            width: 100%;

            @media screen and (min-width: 500px) and (max-height: 900px) {
                padding: 10px 20px;
            }

            @include respond(phone) {
                padding: 12px 16px;
            }
        }
    }

    .autofill-address-check-box {
        .check-label {
            display: flex;
            align-items: center;
            margin: 10px 2.5%;
        }
    }
}