@import "./src/scss/globals";
.pdf-container {
    max-width: 100%;
    flex: 1;
    height: 100%;

    .rpv-core__viewer div:first-child {
        .rpv-default-layout__container .rpv-default-layout__toolbar .rpv-toolbar .rpv-toolbar__right {
            .rpv-core__display--hidden .rpv-toolbar__item {
                [aria-describedby="rpv-core__tooltip-body-open"] {
                      display: none;
                }
            }
        }
    }
}
