@import "./src/scss/globals";
.lesson-skeleton {
    display: flex;
    padding: 10px;
    .circle-skeleton {
        width: 35px;
        height: 35px;
        margin: 0 20px 0 5px;
    }
    .normal-skeleton {
        width: 260px;
        height: 40px;
        margin: 0 10px 0 0px;
    }
}
