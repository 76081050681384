@import "./src/scss/globals";
#user-chat-purchase-menu {
    border: unset;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    margin-top: 0.5rem !important;
    max-width: 30rem !important;

    position: fixed;
    inset: 0px 0px auto auto;
    transform: translate(-23.5%, 10%);

    @media screen and (max-width: 900px) {
        transform: translate(-31%, 8%);
    }

    @media screen and (max-width: 450px) {
        transform: translate(-6%, 8%);
        max-width: 25rem !important;
    }

    @media screen and (max-width: 375px) {
        transform: translate(-8%, 8%);
        max-width: 23rem !important;
    }

    @media screen and (max-width: 320px) {
        transform: translate(-2%, 8%);
        max-width: 22rem !important;
    }

    .popover-arrow {
        right: 1.2rem !important;
        position: absolute;
        display: block;
        width: 1rem;
        height: 0.5rem;
        margin: 0 0.3rem;
        @media screen and (max-width: 450px) {
            right: 35% !important;
        }

        @media screen and (max-width: 375px) {
            right: 37% !important;
        }

        @media screen and (max-width: 320px) {
            right: 45% !important;
        }
    }

    .chat-purchase-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        position: relative;
        z-index: 1;
        padding: 0 1rem;
        overflow-x: auto;
        flex-wrap: nowrap;

        .chat-purchase-wrapper {
            height: calc(100vh - 15rem);
            padding: 0;
            margin: 0;
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
        }

        .chat-header-container {
            font-family: 'Segoe UI', sans-serif;
            font-size: 1.5rem;
            display: flex;
            flex-grow: unset;
            margin-bottom: 1rem;

            @media screen and (max-width: 450px) {
                font-size: 1.4rem;
            }

            @media screen and (max-width: 375px) {
                font-size: 1.2rem;
            }

            p {
                color: $brand-cyan;
                margin-bottom: 0;
                margin-right: 0.5rem;
            }

            span {
                color: $brand-blue;
            }
        }

        .chat-subheader-container {
            font-size: 0.99rem;
            flex-grow: unset;
            margin-bottom: 1.5rem;
        }

        .chat-image-container {
            flex-grow: unset;
            margin-bottom: 1rem;

            img {
                width: 27.2rem;
                object-fit: contain;

                @media screen and (max-width: 450px) {
                    width: 100%;
                }
            }
        }

        .button-wrapper {
            flex-grow: unset;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 1.3rem 0;

            button {
                font-weight: normal;
            }

            p {
                margin-bottom: 0;
                margin-right: 0.7rem;
                font-size: 1.125rem;
                font-weight: bold;
            }
        }

        .footer-heading-1-container {
            flex-grow: unset;
            padding: 0.8rem;
            border-top: 1px solid $mid-gray;

            :last-child {
                margin-bottom: 0 !important;
            }
        }
    }
}