@import "./src/scss/globals";
.horizontal-list-slide {
    width: 100%;
    overflow-y: auto;
    .headings {
        padding-bottom: 5px;
        & > div {
            padding: 5px 20px;
        }
        p:last-child {
            margin-bottom: 0;
        }
    }
    .horizontal-list {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        padding: 20px;
        padding-top: 10px !important;
        .list-item {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            flex-grow: 0;
            width: 100%;
            padding: 10px 10px;
            &:not(:last-child) {
                margin-right: 10px;
            }
            img {
                height: 80px;
                max-width: 100%;
            }
            .text-content {
                text-align: left;
                margin-top: 10px;
            }
        }
        @media screen and (max-width: 900px) {
            flex-direction: column;
            .list-item {
                width: 100%;
            }
        }
    }
}
