@import "./src/scss/globals";
.upsells-list {
    min-width: 600px;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    @media screen and (max-width: 600px) {
        min-width: 300px;
    }
    @media screen and (max-width: 900px) and (min-width: 600px) {
        min-width: 460px;
    }
    @media screen and (max-width: 1200px) and (min-width: 900px) {
        min-width: 380px;
        max-width: 380px;
    }

    @media (min-width: 900px) and (max-width: 980px) {
        min-width: 380px;
        max-width: 380px;
    }

    @media (min-width: 1200px) and (max-width: 1300px) {
        min-width: 530px;
        max-width: 530px;
    }

    @media (min-width: 1300px) and (max-width: 1500px) {
        min-width: 600px;
        max-width: 600px;
    }

    &>h2 {
        color: $indigo-dye;
        font-weight: bold;
        margin-top: 1rem;
    }
}