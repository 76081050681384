.theme-navy {
    background-color: #fff;
    color: #000;
    header {
        background-color: #26265f;
        color: #fff;
    }
    .audio-backword-arrow::before {
        background: #26265f !important;
    }
    .audio-backword-arrow::after {
        border-top: 4px solid #fff !important;
    }
    .audio-forword-arrow::before {
        background: #26265f !important;
    }
    .audio-forword-arrow::after {
        border-top: 4px solid #fff !important;
    }
    #tooltip-audio-indicator {
        background-color: #fff !important;
        color: #000 !important;
        &::after {
            border-color: transparent #fff transparent transparent !important;
        }
    }
}

.theme-green {
    background-color: #fff;
    color: #000;
    header {
        background-color: #2fcca1;
        color: #000;
    }
    .audio-backword-arrow::before {
        background: #2fcca1 !important;
    }
    .audio-forword-arrow::before {
        background: #2fcca1 !important;
    }
}

.theme-blue {
    background-color: #a0d6ef;
    color: #000;
    header {
        background-color: #a0d6ef;
    }
    .audio-backword-arrow::before {
        background: #a0d6ef !important;
    }
    .audio-forword-arrow::before {
        background: #a0d6ef !important;
    }
}

.theme-cyan {
    background-color: #aceded;
    color: #000;
    header {
        background-color: #aceded;
    }
    .audio-backword-arrow::before {
        background: #aceded !important;
    }

    .audio-forword-arrow::before {
        background: #aceded !important;
    }
}

.theme-yellow {
    background-color: #fef5c5;
    color: #000;
    header {
        background-color: #fef5c5;
    }
    .audio-backword-arrow::before {
        background: #fef5c5 !important;
    }

    .audio-forword-arrow::before {
        background: #fef5c5 !important;
    }
}

.theme-cinderalla {
    background-color: #fde3d1;
    color: #000;
    header {
        background-color: #fde3d1;
    }
    .audio-backword-arrow::before {
        background: #fde3d1 !important;
    }

    .audio-forword-arrow::before {
        background: #fde3d1 !important;
    }
}

.theme-honeydew {
    background-color: #ebfaf6;
    color: #000;
    header {
        background-color: #ebfaf6;
    }
    .audio-backword-arrow::before {
        background: #ebfaf6 !important;
    }
    .audio-forword-arrow::before {
        background: #ebfaf6 !important;
    }
}

.theme-orange {
    background-color: #ffd9a7;
    color: #000;
    header {
        background-color: #ffd9a7;
    }
    .audio-backword-arrow::before {
        background: #ffd9a7 !important;
    }
    .audio-forword-arrow::before {
        background: #ffd9a7 !important;
    }
}

.theme-pink {
    background-color: #ffbbb9;
    color: #000;
    header {
        background-color: #ffbbb9;
    }
    .audio-backword-arrow::before {
        background: #ffbbb9 !important;
    }
    .audio-forword-arrow::before {
        background: #ffbbb9 !important;
    }
}

.theme-kobi {
    background-color: #eca8d4;
    color: #000;
    header {
        background-color: #eca8d4;
    }
    .audio-backword-arrow::before {
        background: #eca8d4 !important;
    }
    .audio-forword-arrow::before {
        background: #eca8d4 !important;
    }
}

.theme-vanilla {
    background-color: #eecee6;
    color: #000;
    header {
        background-color: #eecee6;
    }
    .audio-backword-arrow::before {
        background: #eecee6 !important;
    }
    .audio-forword-arrow::before {
        background: #eecee6 !important;
    }
}

.theme-default {
    .player {
        .track {
            background-color: #f0f0f0;
        }
    }
}
