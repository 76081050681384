@import "./src/scss/globals";
.banner-container {
    min-height: 30px;
    max-width: 1625px;
    margin: 0 25px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1625px) {
        max-width: calc(100% - 50px);
    }

    .package-banner {
        @media screen and (max-width: 900px) {
            margin-left: 15px;
            margin-right: 15px;
        }
    }
}

.package-banner {
    display: flex;
    padding: 20px 20px 20px 20px;
    margin: 20px 0;
    border-radius: 20px;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.02);
    border-width: 2px;
    border-style: dashed;
    max-width: 100%;
    width: 100%;
    flex-direction: row;
    position: relative;

    .banner-close-icon {
        position: absolute;
        top: 20px;
        right: 30px;
        cursor: pointer;
        font-size: 16px;
        height: 20px;
        width: 20px;
        display: block;
    }

    .banner-content {
        width: 100%;
        display: block;
        max-height: 250px;
        overflow: auto;
        h4 {
            margin: 5px 0 12px;
            line-height: 22px;
            font-size: 16px;
            padding-right: 40px;
            a {
                color: $linkClr;
                text-decoration: underline;
            }
        }
        .banner-data {
            padding-right: 40px;
            display: block;
        }
        h4 + .banner-data {
            clear: both;
            padding-right: 0;
        }
    }

    .banner-heading {
        font-weight: bold;
    }

    .banner-type-icon {
        height: 32px;
        width: 32px;
        display: block;
        float: left;
        margin-right: 20px;
        margin-bottom: 10px;
        padding-top: 5px;
        &--info {
            color: $indigo-dye;
        }
        &--alert {
            color: $warning;
        }
        &--critical {
            color: $error;
        }
    }

    &.info {
        border-color: $indigo-dye;
        background-color: $white;
    }

    &.alert {
        border-color: $warning;
        background-color: $warning-light-10;
    }

    &.critical {
        border-color: $error;
        background-color: $error-light-10;
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.02);
    }
}
