@import "./src/scss/globals";
.video-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    .transcript {
        margin: 20px;
        padding: 15px;
        background-color: #f9f9f9;
    }

    > iframe {
        max-width: 100%;
        max-height: 100%;
        object-position: top;
    }
}
