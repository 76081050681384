@import "./src/scss/globals";
.profile-wrapper {
    @media screen and (max-width: 500px) {
        padding: unset !important;
    }
    .user-profile-details {
        padding: 2rem;
        @media screen and (max-width: 500px) {
            padding: 2rem 0.5rem;
        }
        .header-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media screen and (max-width: 500px) {
                h3 {
                    font-size: 1.5rem;
                }
            }
        }
    }

    @media (max-width: 501px) {
        .__react_component_tooltip {
            width: calc(100vw - 50px);
        }
    }

    @media (min-width: 500px) and (max-width: 980px) {
        .__react_component_tooltip {
            width: calc(100vw - 40vw);
        }
    }
    @media (min-width: 980px) and (max-width: 2600px) {
        .__react_component_tooltip {
            width: calc(100vw - 50vw);
        }
    }
}
