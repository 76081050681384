@import "./src/scss/globals";
.proctoring-password {
    &__header {
        h3 {
            font-weight: 600;
            font-size: 1.1rem;
            margin: 0;
            padding: 1rem 1rem 1rem 3rem;
            background-color: $cyan;

            @media screen and (max-width: 500px) {
                padding: 1rem;
            }
        }
    }
    
    .content {
        position: relative;
        display: block;
        height: auto;
        min-height: calc(100vh - 185px);
        width: 100%;
        margin-top: 25px;
        overflow: auto;
        max-height: calc(100vh - 200px);

        &-container {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            right: 0;
            width: 100%;
            height: 100%;

            &-wrapper {
                display: flex;
                flex-direction: column;
                width: 800px;

                .video-player {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 1rem 0;

                    @media screen and (max-width: 600px) {
                        iframe {
                            width: 100% !important;
                        }
                    }
                }

                .purchase-proctor-button-wrapper {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    margin: 30px 0;

                    .price-wrapper {
                        display: flex;
                        margin-right: 10px;
                        justify-content: center;
                        align-items: center;

                        .price {
                            margin-bottom: 0;
                        }
                    }
                }

                @media screen and (max-width: 500px) {
                    width: 100%;
                }
            }
        }

        @media screen and (max-width: 800px) {
            padding: 0 40px;
        }

        @media screen and (max-width: 500px) {
            padding: 0 1.5rem;
        }
    }

    .proctoring-footer {
        position: sticky;
        bottom: 0;
        width: 100%;
        height: 50px;
        background-color: #f5f9fc;
        display: flex;
        justify-content: center;
        z-index: 600;

        .buttons-container {
            height: 100%;
            width: 800px;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .lesson-button {
                position: absolute;
                display: flex;
                align-items: center;
                padding: 0 15px;
                height: 100%;
                user-select: none;
                cursor: pointer;
                background-color: transparent;
                transition: background-color 0.2s ease-out;
                font-size: 14px;

                &:hover {
                    background: lighten($brand-blue, 10%);
                }

                &--previous {
                    padding-left: 0;
                    left: 0;
                }

                &--disabled {
                    opacity: 0.5;

                    &:hover {
                        background-color: transparent;
                        cursor: default;
                    }
                }
            }

            .button--disabled {
                border: 1px solid $mid-gray;
                background-color: $mid-gray;
                color: $mid-dark-gray !important;
                cursor: not-allowed;

                &:hover,
                &:active,
                &:focus {
                    background-color: $mid-gray;
                    outline: 0;
                    color: unset;
                    box-shadow: none;
                }
            }

            .exit-button {
                width: 9rem !important;
                background-color: $mid-gray !important;
                border: none !important;
                color: $black;

                &:hover {
                    background: $white !important;
                    border: 2px solid $mid-gray !important;
                }
            }

            @media screen and (max-width: 800px) {
                padding: 0 40px;
            }

            @media screen and (max-width: 500px) {
                padding: 0 1.5rem;
            }
        }
    }
}