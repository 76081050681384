@import "./src/scss/globals";
.essay-page {
    width: 100%;
    overflow-y: auto;
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    header {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 20px 25px 20px 20px;

        h1 {
            font-weight: bold;
            font-size: 24px;

            @media screen and (max-width: 500px) {
                font-size: 18px;
            }

            @media (min-width: 501px) and (max-width: 900px) {
                font-size: 21px;
            }
        }

        h2 {
            font-size: 20px;

            @media screen and (max-width: 500px) {
                font-size: 16px;
            }

            @media (min-width: 501px) and (max-width: 900px) {
                font-size: 17px;
            }
        }
    }

    .content {
        padding: 20px 20px 20px 20px;
        width: 100%;
        flex-direction: column;
        margin: 0;

        .content-data {
            padding: 0;
        }

        .display-attempt-list {
            padding: 0;
            display: flex;
            flex-basis: unset;
            flex-grow: unset;
            justify-content: center;
            align-items: flex-start;
            overflow-y: auto;
            flex-direction: column;
            min-height: 20vh;
            max-height: calc(100vh - 480px);

            @include respond(phone) {
                max-height: calc(100vh - 390px);
            }

            @media screen and (max-height: 900px) {
                max-height: calc(100vh - 300px);
            }

            .attempt-list-wrapper {
                min-height: 20vh;
                max-height: calc(100vh - 480px);
                display: flex;
                flex-direction: column;
                width: 100%;
                overflow: auto;

                @include respond(phone) {
                    max-height: calc(100vh - 390px);
                }

                @media screen and (max-height: 900px) {
                    max-height: calc(100vh - 300px);
                }
            }

            .no-answers-list {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                text-align: center;
            }
        }

        .essay-field {
            flex-grow: 0;
            padding: 0.8rem 0;
            border-top: 1px solid $mid-gray;

            &-wrapper {
                justify-content: space-between;
                align-items: center;

                .input-wrapper {
                    max-width: 88%;
                    flex: 0 0 88%;
                    padding-right: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @include respond(phone) {
                        max-width: 75%;
                        flex: 0 0 75%;
                    }

                    .essay-answer {
                        border-color: $mid-gray;
                    }
                }

                .button-wrapper {
                    display: flex;
                    align-items: center;
                    padding: 0;

                    .submit-button {
                        margin-left: 0.5rem;

                        &:focus,
                        &:active {
                            background-color: #2fcca1;
                            box-shadow: unset !important;
                            outline: 0;
                        }

                        &:hover {
                            background-color: $white;
                            color: #25255e;
                        }
                    }
                }
            }
        }
    }
}
