@import "./src/scss/globals";
.discount {
    display: flex;
    max-width: 1158px;
    width: 100%;
    padding: 35px 48px;

    margin: 0 auto;

    @media screen and (max-width: 900px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 25px;
    }

    > div:first-child {
        max-width: 605px;
        width: 100%;

        & > h2 {
            font-size: 24px;
            line-height: 35px;
            color: $brand-blue;
            font-weight: 600;
        }

        & > p {
            font-size: 16px;
            line-height: 25px;
            font-weight: normal;
        }

        & > ul {
            padding-inline-start: 18px;
        }
    }

    > div:last-child {
        max-width: 450px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        & > h3 {
            background-color: $brand-blue;
            padding: 25px;
            color: white;
            width: 80%;
            text-align: center;
            @media screen and (max-width: 500px) {
                width: 100%;
            }
        }

        & > div {
            display: flex;
            border: 2px dashed $brand-cyan;
            margin-top: 15px;
            width: 80%;
            align-items: center;
            justify-content: center;
            padding: 10px 15px;

            @media screen and (max-width: 500px) {
                width: 100%;
            }
            & > p {
                margin-bottom: 0;
                font-size: 18px;
                font-weight: bold;
                color: $brand-blue;
            }

            & > .btn {
                margin-left: 10px;
            }
        }
    }
}
