@import "./src/scss/globals";
.switcher {

    button {
        border: none;
        background: transparent;
        padding: 0px 16px;
    }

    .disabled {
        color: $light-gray-2;
    }
}