@import "./src/scss/globals";
.confirmation-popup-wrapper {
    .modal-dialog {
        width: 100% !important;
        max-width: 600px !important;
    }
}

.confirmation-popup-wrapper-width-38 {
    .modal-dialog {
        width: 38% !important;
    }
}
