@import "./src/scss/globals";
.navigation-calendar-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .navigation-slide-index-text {
        margin-left: 0.6rem;
        font-size: 14px;
        margin-top: 0.2rem;
        color: #ffffff;
        text-decoration: none solid rgb(255, 255, 255);
        text-align: center;
    }
    i {
        cursor: pointer;
        font-size: 1.3rem;
    }
}
.navigation-calendar-slide-index-wrapper {
    $self: &;
    position: absolute;
    min-height: 60px;
    background-color: $brand-blue;
    width: 100%;
    bottom: 100%;
    z-index: 99;
    top: auto;

    @media screen and (max-width: 900px) {
        max-height: calc(100vh - 400px);
    }

    .calendar-content-wrapper {
        @media screen and (max-width: 900px) {
            padding: 0 20px;
            max-height: calc(100vh - 400px);
        }
        .quiz-calendar-text {
            font-size: 16px;
            line-height: 20px;
            font-weight: bold;
            padding: 15px 30px 0;
            display: block;
            @media screen and (max-width: 900px) {
                text-align: lef;
                padding: 20px 0 0;
            }
        }
        .navigation-calendar {
            padding: 20px 30px;
            @media screen and (max-width: 900px) {
                padding: 10px 0;
            }
            .calendar-group {
                .item-list {
                    column-gap: 6px;
                    grid-row-gap: 6px;
                    margin-top: 0;
                }
            }
            @media screen and (max-width: 900px) {
                .calendar-group {
                    .item-list {
                        justify-content: flex-start;
                    }
                }
            }
        }
    }
    .card-container__feedback__toggle {
        i {
            transform: translateY(4px);
        }
    }
}
