@import "./src/scss/globals";
.course {
    padding: 20px 0;

    .course-wrapper {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        max-width: 435px;
        width: 100%;
        gap: 10px;

        .course-content-wrapper {
            width: 100%;
            h6 {
                width: 100%;
                flex-shrink: 0;
                color: $stratos;
                font-weight: bold;
                margin-bottom: 15px;
            }

            .progress-bar-container {
                width: 100%;
            }
        }
        .exam-not-available {
            width: 26px;
        }
    }

    .content-alignment {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .course-button,
        .extension-course-button {
            margin-right: unset !important;
        }
    }
    .status {
        display: flex;
        .course-status {
            text-align: center;
            margin-top: 10px;
            border-radius: 5px;
            width: fit-content;
            padding: 4px 10px;
            font-size: 15px;
            font-weight: bold;
        }
        .pass-status {
            background-color: $success;
            margin-right: 10px;
            border: 1px solid #2fcca1;
            cursor: pointer;
            &:hover {
                background-color: #d2f5eb;
                border: 1px solid #2fcca1;
                color: #25255e;
            }
        }
        .exam-certificate-btn--disabled {
            border: 1px solid $mid-gray;
            background-color: $mid-gray;
            color: $mid-dark-gray !important;
            cursor: not-allowed;
            &:hover,
            &:active,
            &:focus {
                outline: 0;
                background-color: $mid-gray;
                border: 1px solid $mid-gray;
                box-shadow: none;
                transition: none;
            }
        }
        .all-status {
            background-color: $warning;
            margin-right: 10px;
        }
        .certificate-expiry {
            background-color: $warning;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s, transform 0.2s;
            &:hover {
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
                opacity: 0.6;
                background-color: #ebcfae;
            }
        }
    }

    .dropdown-toggle {
        margin-top: 10px;
        width: auto;
        max-width: 100%;
        height: 40px;
    }

    .course-button {
        margin-left: auto;
        margin-top: 10px;
        @media screen and (max-width: 992px) {
            max-width: 140px;
            margin-right: auto;
            margin-left: 0;
        }
    }
    .extension-course-button {
        margin-left: auto;
        margin-top: 10px;
        @media screen and (max-width: 992px) {
            max-width: 140px;
            margin-right: auto;
            margin-left: 0;
        }
    }
    @media screen and (max-width: 1600px) {
        justify-content: space-between;

        h6 {
            margin-left: 0px;
        }

        .course-button {
            font-size: 16px;
            min-width: 100px;
            height: 40px;
        }
        .extension-course-button {
            font-size: 16px;
            min-width: 100px;
            height: 40px;
        }
        .expired-course-button {
            font-size: 16px;
            min-width: 100px;
            height: 40px;
        }
    }
    @media screen and (max-width: 1300px) {
        flex-wrap: wrap;
        h6 {
            width: 100%;
            font-size: 18px;
        }
    }
}

#exam-status-tooltip {
    .tooltip-inner {
        max-width: 250px;
    }
}
