@import "./src/scss/globals";
.checkout-progress {
    display: flex;
    &__heading {
        display: none;
        @media screen and (max-width: 650px) {
            display: block;
            font-weight: 600;
            font-size: 22px;
            color: $blue;
            margin-top: 12px;
            text-transform: uppercase;
        }
        @include respond(phone) {
            font-size: 18px;
        }
    }
    .tab {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        color: $blue;
        position: relative;
        width: 90px;
        cursor: pointer;
        background: unset;
        border: unset;
        padding: unset;
        border-radius: unset;
        font-size: unset;

        &:not(:last-child) {
            margin-right: 70px;
            @media screen and (max-width: 850px) {
                margin-right: 30px;
            }
            @media screen and (max-width: 650px) {
                margin-right: 5px;
            }
        }
        @media screen and (max-width: 500px) {
            width: 70px;
        }
        @media screen and (max-width: 450px) {
            width: 45px;
        }
        & > div {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $blue;
            height: 58px;
            width: 58px;
            border-radius: 29px;
            font-size: 20px;
            & > svg {
                font-size: 34px;
            }
            @media screen and (max-width: 650px) {
                width: 38px;
                height: 38px;
                font-size: 16px;

                & > svg {
                    font-size: 25px;
                }
            }
        }
        & > span {
            font-weight: 600;
            font-size: 12px;
            margin-top: 8px;
            text-transform: uppercase;
            @media screen and (max-width: 650px) {
                display: none;
            }
        }
        &.complete {
            & > div {
                background-color: $brand-cyan;
                color: $white;
                border-color: transparent;
            }
            &::after {
                background-color: $brand-cyan;
            }
        }
        &.active {
            & > div {
                background-color: $blue;
                color: $white;
                border-color: transparent;
            }
        }
        &:not(:last-child)::after {
            content: '';
            display: block;
            width: 46px;
            height: 3px;
            background-color: $azure-white;
            position: absolute;
            top: 29px;
            right: -56px;
            @media screen and (max-width: 850px) {
                width: 20px;
                right: -26px;
            }
            @media screen and (max-width: 650px) {
                top: 19px;
                right: -15px;
            }
            @media screen and (max-width: 500px) {
                right: -12px;
            }
            @media screen and (max-width: 450px) {
                width: 5px;
                right: -5px;
            }
        }
    }
}
