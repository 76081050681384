@import "./src/scss/globals";
.info-modal {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 9999;
    .info {
        width: 90%;
        max-height: 250px;
        margin-top: 50px;
        padding: 10px;
        border-top: solid 10px #26265f;
        background-color: #e9ecef;
        overflow-y: scroll;
        border-right: solid 2px;
        border-bottom: solid 2px;
        border-left: solid 2px #26265f;
        position: absolute;
    }
}
