@import "./src/scss/globals";
.exam-banner-container {
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.25rem;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.02);
    max-width: 100%;
    width: 100%;
    background-color: $incorrect-orange;
    position: fixed;
    z-index: 1000;
    top: $header-height;

    @media screen and (max-width: 900px) {
        top: 48px;
    }

    @include respond(phone) {
        padding: 0.5rem
    }

    .banner-content {
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 500px) {
            flex-direction: column;
        }

        button {
            margin: 0 1rem;

            @media screen and (max-width: 500px) {
                margin: 0.5rem 0;
            }
        }
    }
}