@import "./src/scss/globals";
.checkout-survey-thank-you-prompt {
    .modal-dialog {
        .modal-body {
            padding: 0.8rem 1.5rem;
        }
        .modal-footer {
            display: none;
        }
    }
}
