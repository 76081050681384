@import "./src/scss/globals";
.button {
    .icon {
        width: 22px;
        height: 22px;
        border-radius: 50px;
        background-color: $brand-blue;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        margin: 5px 5px 5px auto;
        box-shadow: 0 0 15px #fff;
        z-index: 20;
        cursor: pointer;
    }
}
