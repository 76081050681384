@import "./src/scss/globals";
.date-selection-wrapper {
    position: relative;
    width: fit-content;
    .date-selection-input {
        text-align: left;
        cursor: pointer;
        flex: 1;
    }
    .date-selection-picker {
        position: absolute;
        top: 44px;
        left: 0;
        background: $white;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 10px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        z-index: 1000;
        width: fit-content;
        button {
            font-weight: bold;
            border: 1px solid $light-gray;
            border-radius: 0.2rem;
        }

        button[disabled] {
            font-weight: normal;
            border: 1px solid $mid-dark-gray;
            .fa-solid {
                color: $mid-dark-gray;
            }
        }

        select {
            padding: 0.25rem 0.5rem;
            font-size: 0.8rem;
        }
        select[disabled] {
            border: 1px solid $mid-dark-gray;
        }

        .year-selection {
            width: 100px;
            margin: 0 2px;
            @include respond(phone) {
                width: 55px;
            }
        }

        .day-selection {
            width: 92px;
            margin: 0 2px;
            @include respond(phone) {
                width: 50px;
            }
        }
        .month-selection {
            width: 110px;
            margin: 0 2px;
            @include respond(phone) {
                width: 70px;
            }
        }
    }

    .date-selection-container {
        display: flex;
        align-items: center;
        position: relative;
    }

    .clear-button {
        background: none;
        border: none;
        color: #888;
        cursor: pointer;
        font-size: 1rem;
        position: absolute;
        right: 0.5rem;
        top: 50%;
        transform: translateY(-50%);
        padding: 0.25rem;
    }
    .clear-button:hover {
        color: #333;
    }
}
.label-date-selection {
    font-size: 0.875em !important;
}
