@import "./src/scss/globals";
.progress-bar-container {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    .bar {
        position: relative;
        width: 100%;
        background-color: $mid-gray;
        height: 20px;
        border-radius: 10px;
        border: none;
        color: $white;
        .progress {
            position: absolute;
            z-index: 20;
            height: 100%;
            border-radius: 10px;
            border-collapse: separate;
            border: none;
            background-color: $correct-green;
            width: 100%;
            align-items: center;
            justify-content: flex-end;
            padding: 8px;
        }
        .progress_spacing {
            padding-left: 11%;
            text-align: center;
        }
    }
    .progress-less-zero {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 8px;
        font-size: 0.75rem;
    }

    @media screen and (max-width: 1600px) {
        margin-left: 0;
        row-gap: 10px;
        .bar {
            width: 100%;
            height: 15px;
        }
    }
    @media screen and (max-width: 1300px) {
        margin-left: 0;
    }
}
