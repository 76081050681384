@import "./src/scss/globals";
.quiz-page {
    .inline-quiz {
        header {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 20px 25px 20px 20px;
            h1 {
                font-weight: bold;
                font-size: 24px;
                @media screen and (max-width: 500px) {
                    font-size: 18px;
                }
                @media (min-width: 501px) and (max-width: 900px) {
                    font-size: 21px;
                }
            }
            h2 {
                font-size: 20px;
                @media screen and (max-width: 500px) {
                    font-size: 16px;
                }
                @media (min-width: 501px) and (max-width: 900px) {
                    font-size: 17px;
                }
            }
        }
    }
}
