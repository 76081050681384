@import "./src/scss/globals";
.confirmation-payment {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $stratos;

    @media screen and (max-width: 900px) {
        padding: 25px;
    }

    > img {
        width: 100px;
    }
    > h2 {
        color: $white;
        font-size: 25px;
        line-height: 30px;
        margin-top: 20px;
        text-align: center;
    }

    .process-progress {
        display: grid;
        grid-template-columns: 84px calc(100% - 84px);
        padding: 15px 0;
        font-size: 14px;
        .CircularProgressbar {
            &-trail {
                stroke: white;
            }

            &-path {
                stroke: #2fcca1;
            }

            &-text {
                font-size: 1.5rem;
                font-weight: bold;
                fill: $white;
                dominant-baseline: middle;
                text-anchor: middle;
            }
        }
    }

    > h5 {
        color: $white;
    }
}
