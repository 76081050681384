@import "./src/scss/globals";
.button-container {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    .upload-button {
        border: 1px solid #ccc;
        display: inline-block;
        cursor: pointer;
        text-align: center;
        margin-right: 5px;
        color: $black;
    }
    span {
        display: inline-block;
        width: 20rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
