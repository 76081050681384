@import "./src/scss/globals";
.payment_fail_header {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $stratos;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 30px;
    text-align: center;
    > i {
        font-size: 6rem;
        color: $red;
        background: $white;
        border-radius: 50%;
        margin-bottom: 1rem;
    }
    > h1 {
        color: $white;
        font-size: 30px;
        line-height: 40px;
        @include respond(phone) {
            font-size: 22px;
            line-height: 34px;
            margin-bottom: 20px;
        }
    }
    .back-to-checkout-button {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        a {
            border: none;
            background: transparent;
            color: $white;
            text-decoration: underline;
            font-size: 1.3rem;
            cursor: pointer;
        }
        .link-disabled,
        .btn-primary.disabled {
            color: gray;
            cursor: not-allowed;
            background-color: $white;
            border: 0;
            &:hover {
                cursor: not-allowed;
                outline: 0;
            }
        }
    }
}
