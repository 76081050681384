@import "./src/scss/globals";
.package-courses {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;

    h4 {
        font-weight: bold;
    }
    .show-courses-button {
        display: flex;
        justify-content: flex-end;
        padding: 0;
        align-items: center;
        height: 30px;
        width: auto;
        border-radius: 100px;
        background-color: $white;
        border: none;
        color: $stratos;
        font-size: 18px;
        font-weight: 500;
        text-decoration: none solid rgb(1, 1, 75);
        &:hover {
            background-color: $white;
        }
        i {
            font-size: 15px;
            margin-left: 8px;
        }
        @media screen and (max-width: 1200px) {
            font-size: 16px;
            width: 175px;
            height: 40px;
            padding: 0 10px;
            i {
                margin-left: 10px;
            }
        }
    }
    .course-list {
        width: 100%;
        padding-top: 25px;
    }
    .single-course {
        &:first-child {
            margin-top: 20px;
        }
    }
}
.long-text-spacing {
    margin-top: -2.5rem;
    z-index: 2;
    @media screen and (max-width: 500px) {
        margin-top: 0rem;
    }
}
.show-course-spacing {
    margin-top: -1rem;
    @media screen and (max-width: 1440px) {
        margin-top: 0rem;
    }
}
