@import "./src/scss/globals";
.nmls-user-id-modal {
    .modal-dialog {
        &.modal-lg {
            max-width: 700px !important;
        }

        &.modal-sm {
            max-width: 380px !important;
        }

        &.modal-md {
            max-width: 500px !important;
        }

        .modal-content {
            padding: 20px;
            border-radius: 16px !important;
            word-break: break-word;

            a {
                color: $linkClr;
            }

            i {
                margin-left: 6px;
                cursor: pointer;
            }

            .modal-body {
                #nmlsUserId {
                    max-width: 300px;
                }

                div {
                    .hidden {
                        display: none;
                    }
                }
            }
        }
    }
}
