@import "./src/scss/globals";
.attempt-list-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0;

    .answer-container {
        padding: 10px;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        margin-bottom: 20px;
        fill: $light-gray-100;

        &.sender {
            background-color: $brand-cyan;
        }

        &.receiver {
            background-color: $light-gray-100;
            margin-left: -3%;
        }

        .sender-name {
            font-family: 'Open Sans', sans-serif;
            font-size: 0.688rem;
            color: #25255e;
            text-decoration: none solid rgb(37, 37, 94);
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: 2px;
        }

        @media screen and (max-width: 450px) {
            max-width: 90% !important;
        }
    }

    @media screen and (max-width: 450px) {
        justify-content: space-between;
    }

    .sender-photo-hidden {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 0;

        &.receiver {
            opacity: 0;
            display: none;
        }
    }

    .sender-photo {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0;

        &.sender {
            opacity: 0;
            display: none;
        }
    }

    .profile-photo {
        width: 2rem;
        height: 2rem;
        border-radius: 100%;
        object-fit: cover;
    }

    .profile-avatar {
        width: 2rem !important;
        height: 2rem !important;
        font-size: 0.5rem;
    }
}

.attempt-feedback-status {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: nowrap;

    .attempt-status-wrapper {
        width: auto;
        display: flex;
        padding: 0;
        align-items: center;
        justify-content: center;
    }
    @include respond(phone) {
        text-align: center;
        flex-direction: column;
    }

    .status-icon {
        color: $warning;
        font-size: 1.58rem;
        margin-right: 5px;
        width: auto;
        padding: 0;
    }

    span {
        font-size: 0.9rem;
        margin-right: 0.6rem;
        width: auto;
        padding: 0;
    }
}
