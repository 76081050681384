@import "./src/scss/globals";
.lesson-cards__endofchapter-quiz-type {
    overflow-y: auto;
    .quiz {
        &__info {
            padding: 20px 20px;
            background-color: $cyan;
            & > h4 {
                margin-top: 0px;
                margin-bottom: 16px;
            }
        }
        &__unset-default-background {
            background-color: transparent !important;
        }
        &__alert {
            padding: 0.55rem;
            border-left: 10px solid;
            margin-bottom: 1rem;
            background-color: $white;

            &--success {
                border-color: $brand-cyan;
            }

            h6 {
                font-weight: bold;
                margin-bottom: 0.1rem;
            }
        }
        &__data {
            margin-top: 10px;

            & > p {
                margin-bottom: 3px;
            }
        }
        &__footer {
            & > button {
                margin: 16px 15px 16px 0;
            }
        }
    }
}
