@import "./src/scss/globals";
.navigation-sidebar-container {
    width: 350px;
    height: calc(100vh - 54px);
    position: relative;
    flex-shrink: 0;
    transition: transform 0.175s linear, width 0.175s linear;
    &.minimised {
        width: 0px;
        transform: translateX(-350px);
    }
    .navigation-sidebar {
        position: fixed;
        width: 350px;
        height: calc(100vh - 50px);
        overflow-y: auto;
        border-right: 1px solid lighten($light-gray, 40%);
        background-color: $white;

        @include respond(phone) {
            height: calc(100vh - 100px);
        }

        span {
            display: flex;
            align-items: center;
            > h3 {
                font-weight: 600;
                font-size: 1.1rem;
                margin: 0;
                padding: 1rem;
            }
            i {
                margin-left: 20px;
                margin-right: 20px;
                & > svg {
                    width: 25px;
                    height: 25px;
                }
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .navigation-sidebar-header {
            position: sticky;
            top: 0px;
            z-index: 700;
            background-color: white;
        }
    }
    @media screen and (max-width: 900px) {
        top: 48px;
        position: fixed;
        z-index: 500;
        transition: transform 0.175s linear;
    }
}
