@import './_vars.scss';
@import './placeholders.scss';
@import './mixins.scss';

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@function encodecolor($string) {
    @if type-of($string) == 'color' {
        $hex: str-slice(ie-hex-str($string), 4);
        $string: unquote('#{$hex}');
    }
    $string: '%23' + $string;
    @return $string;
}

input[type='checkbox'] {
    width: 20px;
    height: 20px;
    border-color: $black;
}

fieldset > legend {
    font-size: 16px;
    font-weight: bold;
}

.w-12 {
    width: 12rem;
}
