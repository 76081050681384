@import "./src/scss/globals";
.popup-toast {
    margin-top: 15px;
    display: flex;
    align-items: center;
    border-radius: 25px 0 0 25px;
    min-height: 45px;
    width: 350px;
    padding: 0px 5px;
    transition: opacity 250ms ease-in-out;
    opacity: 0;
    pointer-events: auto;
    &.active {
        opacity: 1;
        transform: translateX(0);
    }
    &:hover {
        cursor: pointer;
    }

    &--info {
        color: $indigo-dye;
        background-color: $indigo-dye;
    }

    &--alert {
        color: $warning;
        background-color: $warning;
    }

    &--critical {
        color: $error;
        background-color: $error;
    }

    .icon {
        width: 35px;
        height: 35px;
        border-radius: 50px;
        background-color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        flex-shrink: 0;
    }
    span {
        margin: auto 10px;
        padding-right: 5px;
        color: white;
        font-weight: bold;
        display: flex;
        align-items: center;

        * {
            margin: auto;
        }
    }
}
