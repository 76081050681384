@import "./src/scss/globals";
.profile-photo-update {
    &-modal {
        margin-top: 0.3rem;
        .modal-dialog {
            width: 30rem !important;
            @include respond(phone) {
                width: unset !important;
                padding: 16px 10px !important;
            }
        }
        .modal-content {
            border-radius: 0.8rem !important;
        }
        .modal-header {
            background-color: #25255e;
            color: #fff;
            align-items: center;
            justify-content: center;
            border-top-left-radius: 0.5rem;
            border-top-right-radius: 0.5rem;
            padding: 1.5rem 1.5rem;
            @media screen and (max-width: 375px) {
                padding: 1rem 1rem;
            }
        }
        .modal-body {
            .profile-upload-content-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0.5rem 1.5rem;
                @media screen and (max-width: 320px) {
                    flex-direction: column-reverse;
                }
                .upload-button-wrapper {
                    @media screen and (max-width: 320px) {
                        margin-top: 1rem;
                    }
                    p {
                        cursor: pointer;
                        font-size: 1.2rem;
                        margin-bottom: 0;
                        color: $mid-dark-gray;
                        &:hover,
                        &:active,
                        &:focus {
                            color: #25255e;
                        }
                        span {
                            margin-left: 0.5rem;
                        }
                    }
                    :first-child {
                        margin-bottom: 0.8rem !important;
                    }
                }
                .profile-photo {
                    width: 10rem;
                    height: 10rem;
                    border-radius: 100%;
                    border: 2px solid $brand-cyan;
                    object-fit: cover;
                }
                .profile-avatar {
                    width: 10rem !important;
                    height: 10rem !important;
                    font-size: 2.5rem;
                }
            }
        }

        .modal-footer {
            align-items: center;
            justify-content: flex-end;
            .button-wrapper {
                display: flex;
                flex-wrap: wrap;
                @media screen and (max-width: 320px) {
                    button {
                        width: 7rem !important;
                    }
                }
                .save-profile {
                    width: 9rem;
                    color: $white;
                    margin-left: 8px;
                    &:hover {
                        color: $black;
                    }
                    @media screen and (max-width: 1200px) {
                        width: 14rem;
                    }
                    @include respond(phone) {
                        width: 10rem;
                    }
                }

                .button--disabled {
                    border: 1px solid $mid-gray;
                    background-color: $mid-gray !important;
                    color: $mid-dark-gray !important;
                    cursor: not-allowed !important;
                    &:hover,
                    &:active,
                    &:focus {
                        background-color: $mid-gray;
                        outline: 0;
                        color: unset;
                        box-shadow: none;
                    }
                }

                .close-button-wrapper {
                    display: flex;
                    justify-content: center;
                    .btn {
                        width: 9rem;
                    }
                    .btn,
                    .btn:active,
                    .btnn:focus {
                        background-color: $white;
                        color: $light-gray;
                        margin-top: 3px;
                        font-weight: unset;
                        box-shadow: unset !important;
                    }
                }
            }
        }
    }
}
