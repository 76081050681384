@import "./src/scss/globals";
.cardInfo {
    &__overlay {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        max-height: 400px;
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 10;
        display: none;
        cursor: pointer;
        @media screen and (max-width: 900px) {
            max-height: 100%;
        }
        &--active {
            display: block;
        }
    }
    &__buttonWrapper {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 10000;
    }
    &__icon {
        box-shadow: 0 0 15px #fff;
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 20;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #26265f;
        color: #fff;
        & i {
            font-size: 10px;
        }
        & svg {
            width: 10px;
            height: 10px;
        }
    }
    &__modal {
        position: absolute;
        border-top: 10px solid #26265f;
        padding: 10px;
        height: calc(100% - 60px);
        width: calc(50% - 30px);
        transform: scale(0);
        transform-origin: bottom right;
        bottom: 30px;
        right: 30px;
        left: 50%;
        top: 30px;
        background-color: #e9ecef;
        transition: all 0.2s cubic-bezier(0.32, 0, 0.67, 0);
        overflow-y: auto;
        z-index: 20;
        @media screen and (max-width: 900px) {
            transform-origin: top right;
            width: calc(100% - 60px);
            left: 30px;
        }
        &--active {
            transform: scale(1);
        }
    }
}

.cardImg {
    &__buttonWrapper {
        position: absolute;
        z-index: 50;
    }
    &__icon {
        box-shadow: #fff;
        cursor: pointer;
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 20;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #26265f;
        color: #fff;
        &--top,
        &--bg {
            right: 10px;
        }
        & i {
            font-size: 10px;
        }
        & svg {
            width: 10px;
            height: 10px;
        }
    }

    &__modal {
        // border-top: 10px solid #26265f;
        background-color: rgba(255, 255, 255, 0.7);
        padding: 30px;
        transform: scale(0);
        transform-origin: top left;
        bottom: 0;
        right: 0;
        top: 0;
        left: 0;
        // background-color: #E9ECEf;
        transition: all 0.2s cubic-bezier(0.32, 0, 0.67, 0);
        // overflow-Y: scroll;
        z-index: 20;
        max-height: 100%;
        overflow-y: auto;
        & img {
            object-fit: contain !important;
        }
        @media screen and (max-width: 900px) {
            transform-origin: top right;
        }
        &--active {
            transform: scale(1);
        }
    }
}
