@import "./src/scss/globals";
.profile-wrapper {
    @media screen and (max-width: 500px) {
        padding: unset !important;
    }
    .user-profile-certificates {
        padding: 2rem;
        @media screen and (max-width: 500px) {
            padding: 2rem 0.5rem;
        }
        .header-wrapper {
            @media screen and (max-width: 500px) {
                h3 {
                    font-size: 1.5rem;
                }
            }
        }
    }
}
