@import "./src/scss/globals";
.fill-gap-question-slide {
    display: flex;
    flex-direction: column;
    .quiz-question {
        display: block;
        font-weight: bold;
        & > select {
            padding: 0 25px 0 2px;
            line-height: 1;
            height: 30px;
            width: auto;
            margin: 0 5px;
            min-width: 64px;
        }
        .select-answer {
            margin: 0 3px;
            font-style: italic;
            text-decoration: underline;
        }
    }
    .quiz-feedback {
        margin-top: 20px;
        padding: 10px 20px;
        &.correct {
            color: $black;
            display: flex;
            background-color: $light-gray-100;
            border-left: 8px solid $correct-green;
        }
        &.incorrect {
            color: $black;
            display: flex;
            background-color: $light-gray-100;
            border-left: 8px solid $incorrect-orange;
        }
    }

    .options {
        margin-top: 8px;
    }

    .option {
        padding: 4px;
        padding-left: 16px;
        & .feedback-text {
            flex: 0 0 auto;
            font-weight: 700;
            color: #000;
            padding: 0 10px;
            text-align: right;
            flex-grow: 1;
        }
    }

    .option-content {
        padding-left: 4px;
    }

    .correct-feedback {
        color: $black;
        background-color: $correct-green;
        margin-bottom: 4px;
    }
    .incorrect-feedback {
        color: $black;
        background-color: $incorrect-orange;
        margin-bottom: 4px;
    }
    .reveal-correct-feedback {
        background-color: $light-gray-100;
        color: $black;
        margin-bottom: 4px;
    }
}
