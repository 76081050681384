@import "./src/scss/globals";
.form-builder {
    position: relative;
    height: 100%;

    main {
        @import '../../pages/Courses/CKEditorContent.scss';
        overflow-y: auto;
        scroll-padding-top: 80px;
        height: 100%;

        &:not(:first-child) {
            & > div {
                padding-top: 64px;
            }
        }

        & > div {
            width: 100%;
            max-width: 800px;
            margin: 0 auto;
            padding-bottom: 60px;

            & > div {
                margin: 10px 0;

                .SingleDatePicker {
                    max-width: 300px;
                }
            }
        }

        @media screen and (max-width: 900px) {
            padding: 0 15px;
        }
    }

    footer {
        display: flex;
        background-color: white;
        height: 54px;
        align-items: center;
        justify-content: center;
        border-top: 1px solid darken($very-light-gray, 10);
        position: fixed;
        bottom: 0;
        width: 100vw;

        @media screen and (max-width: 900px) {
            height: 48px;
        }
    }

    &__checkboxInput {
        display: flex;
        align-items: center;

        .checkInput__wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            input {
                margin-right: 8px;
            }
        }
    }

    &__textInput,
    &__numberInput {
        small {
            display: block;
            transform: translateY(-5px);
        }
    }
    &__errorBorder {
        background-color: $red;
        border: 1px solid $red !important;
        box-shadow: 0 0 10px 0 rgba($red, 0.2);
        transition: 'background-color 0.3s ease-in-out';
    }

    &__optionsInput,
    &__radioInput {
        input[type='checkbox'],
        input[type='radio'] {
            margin-right: 8px;
            border-color: $black;
        }

        &__error {
            textarea,
            input[type='text'] {
                border: 1px solid $red !important;
                color: $red;
            }

            ::placeholder {
                color: $red;
            }
        }

        label {
            label:not(:first-of-type) {
                margin-left: 16px;
            }
        }
        .dropdown-input {
            width: 12rem !important;
            max-width: 100%;
            text-overflow: ellipsis;
            padding-right: 1.5rem !important;
        }
        .options-input-container {
            display: flex;
            align-items: center;
            label {
                display: flex;
                align-items: center;
                padding-right: 10px;
            }
        }
    }

    &__date-field {
        display: flex;
        flex-direction: column;

        & > label {
            font-size: 0.875em !important;
        }

        .react-datepicker-wrapper {
            width: 35%;
            @include respond(phone) {
                width: 100%;
            }
            .react-datepicker__input-container {
                width: auto;
            }
        }
    }

    &__upload-file {
        label {
            font-size: 0.875em !important;
        }
    }

    &__error {
        border: 1px solid $red !important;
        color: $red;
        ::placeholder {
            color: $red;
        }
    }
    &__error-color {
        margin-top: 5px;
        color: $red;
    }

    &__text-card-block {
        .theme-default,
        .theme- {
            header {
                padding: 20px 25px 20px 0 !important;
            }
            .content {
                padding: 20px 25px 20px 0 !important;
            }
        }
    }

    &__document-card-block {
        .theme-default,
        .theme- {
            header {
                padding: 20px 25px 20px 0 !important;
            }
            .content {
                padding: 20px 25px 20px 0 !important;
            }
        }
    }
    &__image-card-block {
        .image-page {
            .image-page-padding {
                padding-left: 0 !important;
            }
        }
    }
}

small {
    display: block;
    transform: translateY(-5px);
}

label {
    margin-bottom: 0;
}

.agree-disagree-button {
    .agree-button {
        margin-right: 5px;
    }
    .disagree-button {
        background-color: #dadada;
        border: solid 2px #dadada;
        &:hover {
            background-color: transparent;
        }
    }
}
