@import "./src/scss/globals";
.iframe-page {
    width: 100%;
    overflow-y: auto;
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    header {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 20px 25px 20px 20px;

        h1 {
            font-weight: bold;
            font-size: 24px;
            @media screen and (max-width: 500px) {
                font-size: 18px;
            }
            @media (min-width: 501px) and (max-width: 900px) {
                font-size: 21px;
            }
        }
        h2 {
            font-size: 20px;
            @media screen and (max-width: 500px) {
                font-size: 16px;
            }
            @media (min-width: 501px) and (max-width: 900px) {
                font-size: 17px;
            }
        }
    }
    .iframe-wrapper {
        width: 100%;
        padding: 20px 20px 20px 20px;

        iframe {
            width: 100%;
            padding-bottom: 1px;
        }

        .gray-border-clz {
            border: 1px solid $mid-dark-gray !important;
        }
        .navy-border-clz {
            border: 1px solid $theme-navy !important;
        }
        .no-border-clz {
            border: unset !important;
        }
    }

    .content {
        padding: 20px 25px 20px 20px;
        width: 100%;
        > div {
            img {
                max-width: 100%;
            }
            p:last-child {
                font-size: 16px;
            }
        }
    }
}
.theme-navy,
.theme-green {
    .iframe-wrapper {
        padding: 20px 20px 20px 20px !important;
    }
}
