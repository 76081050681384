%default-icon {
    border-width: 4px;
    border-style: solid;
    border-radius: 7px;
    font-weight: 600;
    &.green {
        background: #2fcca1;
        border-top-color: #29b993;
        border-left-color: #29b993;
        border-right-color: #4bf0c4;
        border-bottom-color: #4bf0c4;
    }
    &.red {
        background: #ff7069;
        border-top-color: #f55850;
        border-left-color: #f55850;
        border-right-color: #fc7e77;
        border-bottom-color: #fc7e77;
    }
    &.blue {
        background-color: #549de6;
        border-top-color: #2f84da;
        border-left-color: #2f84da;
        border-right-color: #6aabec;
        border-bottom-color: #6aabec;
    }
}
