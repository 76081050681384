@import "./src/scss/globals";
.image-modal {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
    & > img {
        border-top: solid 10px #26265f;
        margin-top: 50px;
        object-fit: contain;
        max-width: 90%;
        max-height: 90%;
    }
}
