@import "./src/scss/globals";
.text-slide {
    overflow-y: auto;
    flex: 1;

    & > .file-image {
        float: left; // Cause the remaining elements to flow around the icon
        margin-right: 30px; // some additional margin to the right to prevent bunching
        margin-bottom: 20px; // some additional margin to the bottom to prevent bunching
        margin-left: 20px;
        margin-top: 20px;
        width: 150px;
        height: 150px;
        object-fit: contain;
    }

    &-content {
        padding: 10px 20px 20px;
        & > p:last-child {
            margin: 0;
        }
    }
}
