@import "./src/scss/globals";
.frequentlyBoughtTogether {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    width: 674px;
    max-width: 100%;
    padding: 0 15px;
    @include respond(phone) {
        margin-top: 20px;
    }
    > h2 {
        font-family: 'Poppins', sans-serif;
        font-size: 30px;
        line-height: 35px;
        font-weight: 600;
        color: $stratos;
        text-align: center;
        @include respond(phone) {
            font-size: 24px;
        }
    }

    > p {
        text-align: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        color: #212121;

        @include respond(phone) {
            font-size: 1rem;
            margin-bottom: 0;
        }
        > span {
            color: $stratos;
        }
    }

    > .checkout-main {
        padding: 40px 40px;
    }
}

.confirmation__upsell {
    display: flex;
    max-width: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    & > img {
        height: 150px;
        width: 110px;
        border-radius: 3px;
        object-fit: cover;
    }

    & > .main {
        padding: 0 30px;
        max-width: 50%;

        & > h3 {
            text-align: left;
        }
    }

    & > .cta {
        & > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 27px;
            color: $brand-blue;

            & > svg {
                width: 35px;
                height: 35px;
            }
        }
    }

    &-oldprice {
        color: $red;
        text-decoration: line-through;
    }

    &-price {
        font-weight: bold;
    }
}

.confirmation {
    max-width: 1158px;
    width: 100%;
    padding: 20px 0 50px;
    margin: 0 auto;
    @include respond(phone) {
        padding: 0px 0 20px;
    }
    .upsell-card {
        max-width: initial;
        min-width: initial;
        .confirmation__upsell {
            margin-left: auto;
            width: 100%;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            align-items: center;
            margin-top: 10px;
            color: #23283f;
            font-family: 'Poppins', sans-serif;
            .confirmation__upsell-price {
                font-size: 28px;
                font-weight: 600;
                margin: 0 8px;
                @include respond(phone) {
                    font-size: 20px;
                }
            }
            .confirmation__upsell-oldprice {
                font-size: 24px;
                color: #ff4343;
                text-decoration: line-through;
                @include respond(phone) {
                    font-size: 18px;
                }
            }
            .confirmation__upsell-discount {
                font-size: 24px;
                margin-left: 10px;
                @include respond(phone) {
                    font-size: 20px;
                }
            }
        }
    }
    .confirmation_actions {
        margin-left: auto;
        width: 285px;
        max-width: 100%;
        a.light-blue {
            background-color: #549de6;
            width: 100%;
            text-decoration: none;
            color: #fff;
            font-size: 18px;
            padding: 14px 30px;
            font-weight: 600;
            border-radius: 45px;
            display: block;
            text-align: center;
            @include respond(phone) {
                width: 80%;
                margin: auto;
                padding: 10px 30px;
                font-size: 18px;
            }
        }
    }
}
