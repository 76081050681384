@import "./src/scss/globals";
.document-slide .document-type {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    .DT-Content {
        width: 100%;
        flex: 1;
        overflow: auto;
    }
    iframe {
        height: calc(100vh - 156px);
        max-height: 750px;
        width: 100%;
        max-width: 600px;
        border: 0;
        @media screen and (max-width: 900px) {
            width: calc(100% + 20px);
            margin: 0 -20px;
        }
    }
    & > .document-link {
        margin: auto;
        .text-link {
            margin-bottom: 0;
        }
    }
    & > header {
        width: 100%;
        & > h1 {
            &.single {
                margin: 0;
                margin-bottom: 20px;
                -webkit-line-clamp: 2;
            }
        }
        & > h3 {
            &.single {
                margin: 0;
                margin-bottom: 20px;
                -webkit-line-clamp: 2;
            }
        }
    }
    & > div {
        padding: 10px 20px 20px;
        font-size: 14px;
        @media screen and (min-width: 900px) {
            font-size: 16px;
        }
    }
    button {
        margin: auto auto 0 auto;
        svg {
            margin-left: 10px;
        }
        + a {
            display: block;
            margin: 10px auto 0 auto;
        }
    }
    a.text-link,
    a.text-link:visited,
    a.text-link:focus {
        color: #666 !important;
        font-size: 14px;
        text-decoration: none !important;
        margin-bottom: 20px;
    }
    .document-modal-container {
        @extend %center-content;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 999;
        width: 100vw;
        height: 98vh;
        transform: translate(-50%, -43%);
        background-color: hsla(0, 0%, 75%, 0.8);
        .document-modal {
            width: 1000px;
            height: 86vh;
            background-color: white;
            border-radius: 10px;
            header {
                display: flex;
                align-items: center;
                padding: 10px 15px;
                svg {
                    margin-left: auto;
                    transform: scale(1.3);
                    &:hover {
                        cursor: pointer;
                        transform: scale(1.5);
                    }
                }
            }
            div {
                display: flex;
                justify-content: center;
                iframe {
                    height: calc(86vh - 70px);
                    max-height: 800px;
                    width: 100%;
                    max-width: 600px;
                    border: 0;
                }
            }
        }
    }
}
.document-card-modal {
    top: 0px !important;
    ::-webkit-scrollbar {
        width: 15px;
    }
    .modal-body {
        padding: 0;
        iframe {
            width: 100%;
            height: 100%;
            border: 0;
            display: block;
        }
    }
}
