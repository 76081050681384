@import "./src/scss/globals";
.exam-summary {
    &__header {
        h3 {
            font-weight: 600;
            font-size: 1.1rem;
            margin: 0;
            padding: 1rem 1rem 1rem 3rem;
            background-color: $cyan;
            @media screen and (max-width: 500px) {
                padding: 1rem;
            }
        }
    }
    .content {
        position: relative;
        display: block;
        height: auto;
        min-height: calc(100vh - 108px);
        width: 100%;
        margin-top: 25px;
        &-container {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            right: 0;
            width: 100%;
            height: 100%;
            &-wrapper {
                display: flex;
                flex-direction: column;
                width: 800px;
                .about-exam {
                    &__content {
                        padding-top: 0.5rem;
                        padding-bottom: 1.5rem;
                        & > ul {
                            padding-inline-start: 18px;
                        }
                    }
                }
                .exam-rules {
                    &__data {
                        padding: 0.5rem 0px 2rem;
                        & > p {
                            margin-bottom: 3px;
                            span {
                                font-weight: bold;
                            }
                            .no-of-questions {
                                margin-right: 2rem;
                            }
                            .time-limit {
                                margin-right: 6.8rem;
                            }
                        }
                    }
                }
                .attempt-display {
                    &__data {
                        padding: 0.5rem 0px 1rem;
                        & > p {
                            margin-bottom: 3px;
                            span {
                                font-weight: bold;
                            }
                            .attempt-limit {
                                margin-right: 4rem;
                            }
                        }
                        .attempt-info {
                            display: flex;
                            margin-top: 20px;
                            align-items: center;
                            .icon {
                                width: 28px;
                                height: 28px;
                                border-radius: 50px;
                                background-color: $white;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 22px;
                                flex-shrink: 0;
                                margin-right: 10px;
                                svg {
                                    width: 28px;
                                    height: 28px;
                                    fill: $warning !important;
                                }
                            }

                            .warn-message {
                                p {
                                    text-decoration: underline;
                                    font-weight: bold;
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
                .attempt-list-display {
                    .empty-attempt {
                        color: $mid-dark-gray;
                    }
                }
                .display-repurchase-block {
                    margin: 2rem 0;
                    border-radius: 20px;
                    border: 2px solid $brand-blue;
                    .repurchase-container {
                        justify-content: space-between;
                        align-items: center;
                        display: flex;
                        flex-direction: row;
                        @media screen and (max-width: 500px) {
                            flex-direction: column;
                        }
                        .repurchase-info {
                            background-color: $brand-blue;
                            color: $white;
                            width: 50%;
                            padding: 2rem 1rem 1rem 1.5rem;
                            border-top-left-radius: 1rem;
                            border-bottom-left-radius: 1rem;
                            p {
                                font-size: 12px;
                            }
                            @media screen and (max-width: 500px) {
                                padding: 1.5rem;
                                border-bottom-left-radius: unset;
                                border-top-right-radius: 1rem;
                                width: 100%;
                            }
                        }
                        .repurchase-block {
                            width: 50%;
                            height: 100%;
                            padding: 1rem 2.5rem 1rem 1.5rem;
                            .about-extension {
                                height: 7rem;
                                overflow: auto;
                            }
                            p {
                                color: $light-gray;
                            }
                            @media screen and (max-width: 500px) {
                                width: 100%;
                                padding: 1.5rem;
                            }

                            .repurchase-button-wrapper {
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-end;
                                align-items: flex-end;
                                .price-wrapper {
                                    display: flex;
                                    width: 8rem;
                                    justify-content: flex-end;
                                    align-items: center;
                                    h4 {
                                        margin-right: 8px;
                                    }
                                    h5 {
                                        font-size: 18px;
                                        color: $red;
                                        text-decoration: line-through;
                                    }
                                }
                                button {
                                    color: $white;
                                    &:hover {
                                        color: $black;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 800px) {
            padding: 0 40px;
        }
        @media screen and (max-width: 500px) {
            padding: 0 1.5rem;
        }
    }
    .exam-summary__header {
        display: flex;
        justify-content: space-between;
        background-color: #f5fafd;
        align-items: center;
        button {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 35px;
            border-radius: 100px;
            background-color: $indigo-dye;
            border: none;
            color: $white;
            font-size: 15px;
            padding: 0 24px;
            margin: 5px;
            min-width: 115px;
            &:hover {
                background-color: $azure-white;
                color: $black;
            }

            @media screen and (min-width: 1200px) {
                font-size: 16px;
                min-width: 100px;
                height: 40px;
            }
        }
    }
    .exam-footer {
        position: sticky;
        bottom: 0;
        width: 100%;
        height: 50px;
        background-color: #f5f9fc;
        display: flex;
        justify-content: center;
        z-index: 600;
        .buttons-container {
            height: 100%;
            width: 800px;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .lesson-button {
                position: absolute;
                display: flex;
                align-items: center;
                padding: 0 15px;
                height: 100%;
                user-select: none;
                cursor: pointer;
                background-color: transparent;
                transition: background-color 0.2s ease-out;
                font-size: 14px;
                &:hover {
                    background: lighten($brand-blue, 10%);
                }
                &--previous {
                    padding-left: 0;
                    left: 0;
                }
                &--disabled {
                    opacity: 0.5;
                    &:hover {
                        background-color: transparent;
                        cursor: default;
                    }
                }
            }
            button {
                width: 9rem;
                padding: 5px 10px;
            }

            .button--disabled {
                border: 1px solid $mid-gray;
                background-color: $mid-gray;
                color: $mid-dark-gray !important;
                cursor: not-allowed;
                &:hover,
                &:active,
                &:focus {
                    background-color: $mid-gray;
                    outline: 0;
                    color: unset;
                    box-shadow: none;
                }
            }
            .exit-button {
                width: 9rem !important;
                background-color: $mid-gray !important;
                border: none !important;
                color: $black;
                &:hover {
                    background: $white !important;
                    border: 2px solid $mid-gray !important;
                }
            }
            @media screen and (max-width: 800px) {
                padding: 0 40px;
            }
            @media screen and (max-width: 500px) {
                padding: 0 1.5rem;
            }
        }
    }
}

.resale-options {
    .modal-dialog.modal-lg {
        max-width: 75vw;
    }
}
