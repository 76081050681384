@import "./src/scss/globals";
.text-page {
    width: 100%;
    overflow-y: auto;
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    header {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 20px 25px 20px 20px;

        h1 {
            font-weight: bold;
            font-size: 24px;
            @media screen and (max-width: 500px) {
                font-size: 18px;
            }
            @media (min-width: 501px) and (max-width: 900px) {
                font-size: 21px;
            }
        }
        h2 {
            font-size: 20px;
            @media screen and (max-width: 500px) {
                font-size: 16px;
            }
            @media (min-width: 501px) and (max-width: 900px) {
                font-size: 17px;
            }
        }
    }
    .content {
        padding: 20px 25px 20px 20px;
        width: 100%;
        .file-image {
            float: left; // Cause the remaining elements to flow around the icon
            margin: 7px 15px 7px 0px;
            max-width: 150px;
            max-height: 400px;
            object-fit: contain;
        }
        > div {
            img {
                max-width: 100%;
            }

            p:last-child {
                font-size: 16px;
            }
        }
    }
}
