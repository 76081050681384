@import "./src/scss/globals";
.extension-error-modal {
    .modal-dialog {
        justify-content: center;

        .modal-content {
            width: auto;
            max-width: 90%;
        }
    }
}
