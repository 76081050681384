@import "./src/scss/globals";
.essay-admin-card {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;

    .show-header-content {
        width: 100%;

        header {
            padding: 15px 30px;

            >h1 {
                font-weight: bold;
                font-size: 18px;
                -webkit-line-clamp: 2;
                padding-left: 0;
                padding-right: 0;
            }

            >h3 {
                font-size: 16px;
                -webkit-line-clamp: 2;
                padding-left: 0;
                padding-right: 0;
            }
        }

        .essay-slide-instruction {
            max-height: 100px;
            overflow-y: auto;
            margin: 20px 30px 0;
        }
    }

    .essay-card-body-wrapper {
        display: flex;
        align-items: center;
        width: 100%;

        .fa-chevron-circle-right {
            margin-right: 20px;
        }

        .fa-chevron-circle-left {
            margin-left: 20px;
        }

        .body-container {
            padding: 20px 20px 0;
            width: 100%;

            .slide-card-textarea {
                margin-bottom: 10px;

                textarea {
                    height: 220px;
                }

                textarea[readonly] {
                    background-color: #ffffff;
                    border: 2px solid $opaque-bg;
                    color: $opaque-bg;
                    min-height: 100px;
                    max-height: 400px;

                    &:focus {
                        outline: none !important;
                        border: 2px solid $opaque-bg;
                    }
                }
            }

            .attempt-heading-container {
                display: flex;
                width: 30%;
                justify-content: space-between;

                h3 {
                    font-size: 16px;
                    margin: 0;
                }
            }
        }
    }
}