@import "./src/scss/globals";
.slide-calendar-legend {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background-color: $mid-gray;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.green {
        background-color: $success;
    }
    &.red {
        background-color: $incorrect-orange;
    }
    &.inactive {
        border: solid 2px $light-gray;
    }
    &.active {
        border: solid 2px $white;
    }
    i {
        color: $black;
        font-size: 12px;
    }
    .inactive-overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: $disabled-overlay;
    }
}
