@import "./src/scss/globals";
.card-container__feedback {
    $self: &;

    position: relative;
    height: 60px;
    background-color: $incorrect-orange;

    @media screen and (max-width: 900px) {
        height: 48px;
    }

    &--correct {
        background-color: lighten($brand-cyan, 10%);
    }

    &--approved {
        background-color: lighten($brand-cyan, 10%);
    }

    &--rejected {
        background-color: $incorrect-orange;
    }


    &__header {
        background-color: inherit;
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        align-items: center;
        height: 100%;
        z-index: 1;

        & > h1 {
            font-size: 20px;
            font-weight: bold;
            margin: 0;

            @media screen and (max-width: 900px) {
                font-size: 16px;
            }
        }

        .mobile-view-center {
            @media screen and (max-width: 900px) {
                text-align: center;
            }
        }

        @media screen and (max-width: 900px) {
            padding: 0 10px;
        }

        .lesson-button {
            display: flex;
            align-items: center;
            position: relative;
            background-color: $brand-blue;
            color: white;
            height: 34px;
            padding: 0 20px;
            font-weight: bold;
            border-radius: 22px;
            transition: background-color 0.2s ease-out, color 0.2s ease-out, border-color 0.2s ease-out;
            border: 3px solid transparent;

            &:hover {
                border-color: $brand-blue;
                color: $brand-blue;
                background-color: transparent;
                cursor: pointer;
            }
            &--disabled {
                opacity: 0.5;
                &:hover {
                    background-color: transparent;
                    cursor: default;
                }
            }
        }
    }

    &__content {
        position: absolute;
        background-color: inherit;
        bottom: 60px;
        transition: transform 0.2s ease-out;
        transform: translateY(10%);
        width: 100%;
        padding-top: 20px;
        z-index: 1;

        @media screen and (max-width: 900px) {
            bottom: 48px;
        }

        & > main {
            padding: 0 20px 20px 20px;
            opacity: 0;
            transition: opacity 0.2s ease-out;
            overflow: hidden;
            pointer-events: none;
            transform-origin: bottom;
            display: none;
            max-height: calc(100vh - 680px);
            min-height: 20%;
            overflow-y: auto;
            position: relative;
            z-index: 1;

            @media screen and (max-width: 900px) {
                max-height: calc(100vh - 277px);
            }
        }

        #{$self}--expanded & {
            transform: translateY(0px);

            & > main {
                opacity: 1;
                height: auto;
                pointer-events: auto;
                display: block;
                transition: opacity 0.2s ease-out;
            }
        }
    }

    &__up-icon-toggle {
        z-index: 10;
    }
    &__toggle {
        display: flex;
        justify-content: center;
        font-size: 12px;
        position: absolute;
        height: 30px;
        width: 30px;
        border-radius: 15px;
        top: -13px;
        left: 50%;
        transform: translateX(-50%);
        background-color: inherit;
        cursor: pointer;

        svg {
            transform: translateY(3px);
        }
        .up-icon-padding {
            padding: 4px !important;
        }
    }
}
