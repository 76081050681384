@import "./src/scss/globals";
.student-preview-controls {
    border-radius: 10px;
    width: 40px;
    height: 40px;
    transition: all 0.15s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        cursor: pointer;
        background-color: $opaque-bg;
    }
    > i {
        font-size: 24px;
        @media screen and (max-width: 900px) {
            font-size: 22px;
        }
    }
}
#preview-controls-popup {
    width: 400px;
    max-width: 90%;
    .modal-body {
        max-height: calc(100vh - 300px);
        @media screen and (max-height: 500px) {
            min-height: 250px;
        }
        .settings {
            width: 100%;
            margin: 15px 0;
            display: flex;
            flex-direction: column;
            row-gap: 25px;
            select {
                height: 30px;
                padding: 0px 10px;
            }
            .toggles {
                display: flex;
                justify-content: space-around;
                align-items: center;
            }
        }

        .preview-message {
            color: $red;
        }
    }
}
