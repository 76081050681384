@import "./src/scss/globals";
.payment-tab-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0;

    .payment-tab-heading {
        & > h2 {
            color: $theme-navy;
            font-weight: bold;
            margin-top: 0.5rem;
            font-size: 1.6rem;
            margin-bottom: 1rem;
        }

        @media screen and (max-width: 1024px) {
            padding: 0;
            margin-top: 1rem;
        }
    }
}

.payment-main-wrapper {
    width: 100%;
    margin: 0 0 1.5rem 0;

    @media screen and (max-width: 1024px) {
        flex-direction: column;
    }

    .payment-content-container {
        @media screen and (max-width: 1024px) {
            max-width: 100%;
            flex: 1;
        }
    }
}
