@import "./src/scss/globals";
.profile-avatar {
    background-color: #25255e;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
    border: 2px solid #25255e;
    object-fit: cover;
}
